import React from "react";
import Input from "../../../../components/input";

export const AdminForm = (props) => {
  return (
    <div>
      <div className="drawer-title border-none margin-top">{props.title}</div>
      <div className="input-container">
        <label className="form-label" htmlFor="tenant">
          Nome
        </label>
        <Input
          id="adminName"
          name="adminName"
          type="text"
          placeholder="Nome"
          onChange={(e) => props.setAdminName(e.target.value)}
          value={props.adminName}
        />
        {props.getError(props.nameError) && (
          <div className="error-message">
            {props.getError(props.nameError).message}
          </div>
        )}
      </div>
      <div className="input-container">
        <label className="form-label" htmlFor="tenant">
          Cognome
        </label>
        <Input
          id="adminSurname"
          name="adminSurname"
          type="text"
          placeholder="Cognome"
          onChange={(e) => props.setAdminSurname(e.target.value)}
          value={props.adminSurname}
        />
        {props.getError(props.surnameError) && (
          <div className="error-message">
            {props.getError(props.surnameError).message}
          </div>
        )}
      </div>
      <div className="input-container">
        <label className="form-label" htmlFor="tenant">
          Email
        </label>
        <Input
          id="adminAccountEmail"
          name="adminAccountEmail"
          type="email"
          placeholder="Email"
          onChange={(e) => props.setAdminAccountEmail(e.target.value)}
          value={props.adminAccountEmail}
        />
        {props.getError(props.emailError) && (
          <div className="error-message">
            {props.getError(props.emailError).message}
          </div>
        )}
      </div>
    </div>
  );
};
