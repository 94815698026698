import React, { useState } from "react";
import { useEffect } from "react";
import {
  deleteCheck,
  deleteUser,
  editUser,
} from "../../../../api/backofficeAPI";
import Button from "../../../../components/button";
import { DetailedTooltip } from "../../../../components/detailedTooltip";
import EliminationModal from "../../../../components/eliminationModal";
import Input from "../../../../components/input";
import Select from "../../../../components/select";
import Toggler from "../../../../components/toggler";
import { Wrapper } from "../../../styles/userForm";
import TooltipData from "../backoffice/TooltipData";

const EditUserForm = (props) => {
  const [name, setName] = useState(props.utente.name);
  const [surname, setSurname] = useState(props.utente.surname);
  const [email, setEmail] = useState(props.utente.account);
  const [password] = useState(props.utente.password);
  const [type, setType] = useState(props.utente.userType);
  const [isActive, setIsActive] = useState(props.utente.isActive);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [responseErrors, setResponseErrors] = useState([]);
  const [canDelete, setCanDelete] = useState(null);
  const [deleteError, setDeleteError] = useState("");
  const [showDeleteError, setShowDeleteError] = useState(false);
  const [notAllowedError, setNotAllowedError] = useState("");
  const [showNotAllowedError, setShowNotAllowedError] = useState(false);
  const [canModifyType, setCanModifyType] = useState(true);

  useEffect(() => {
    deleteCheck(props.utente.id).then((response) => {
      if (response.status === 200) {
        setCanDelete(true);
      } else if (response.status === 400 || response.status === 403) {
        setCanDelete(false);
        setDeleteError(response.data.Message);
      } else {
      }
    });
  }, [props.token, props.utente.id]);

  useEffect(() => {
    if (type === "TenantUser" || type === "TenantSupervisor") {
      setCanModifyType(true);
    } else {
      setCanModifyType(false);
    }
  }, [type]);

  const onFormSubmit = () => {
    let body = {
      id: props.utente.id,
      name: name,
      surname: surname,
      account: email,
      password: password,
      note: "prova inserimento utente",
      isActive: isActive,
      userType: type,
    };
    editUser(body).then((response) => {
      if (response.status === 400) {
        setResponseErrors(response.data);
      } else if (response.status === 409 || response.status === 403) {
        setNotAllowedError(response.data.Message);
        setShowNotAllowedError(true);
      } else if (response.status === 200) {
        props.getData();
        props.handleDrawer(false);
      } else {
      }
    });
  };

  const onUserDelete = () => {
    deleteUser(props.utente.id).then((response) => {
      if (response.status === 200) {
        setIsDeleteModalOpen(false);
        props.getData();
        props.handleDrawer(false);
      }
    });
  };

  const getError = (field) => {
    return responseErrors.find((obj) => {
      return obj.fieldName === field;
    });
  };

  const onDeleteButtonClick = () => {
    canDelete ? setIsDeleteModalOpen(true) : setShowDeleteError(true);
  };

  return (
    <Wrapper>
      <div className="form">
        <div className="upper-section">
          <div className="input-container">
            <label className="form-label" htmlFor="tenant">
              Nome
            </label>
            <Input
              value={name}
              id="nomeUtente"
              name="nomeUtente"
              type="text"
              placeholder="nome"
              onChange={(e) => setName(e.target.value)}
            />
            {getError("Name") && (
              <div className="error-message">{getError("Name").message}</div>
            )}
          </div>
          <div className="input-container">
            <label className="form-label" htmlFor="tenant">
              Cognome
            </label>
            <Input
              value={surname}
              id="cognomeUtente"
              name="cognomeUtente"
              type="text"
              placeholder="Cognome"
              onChange={(e) => setSurname(e.target.value)}
            />
            {getError("Surname") && (
              <div className="error-message">{getError("Surname").message}</div>
            )}
          </div>
          <div className="input-container">
            <label className="form-label" htmlFor="tenant">
              Email
            </label>
            <Input
              value={email}
              id="emailUtente"
              name="emailUtente"
              type="text"
              placeholder="Email"
              onChange={(e) => setEmail(e.target.value)}
              disabled
            />
            {getError("Account") && (
              <div className="error-message">{getError("Account").message}</div>
            )}
          </div>
          <div className="select-container">
            <label className="form-label" htmlFor="tenant">
              Tipo
              <div className="tooltip-icon">
                <DetailedTooltip
                  title="Informazione su i tipi di utenti"
                  children={<TooltipData />}
                ></DetailedTooltip>
              </div>
            </label>
            <Select
              name="profile"
              id="profile"
              onChange={(e) => setType(e.target.value)}
              defaultValue={type}
              disabled={!canModifyType}
            >
              <option value="TenantUser" disabled={!canModifyType}>
                Normale
              </option>
              <option value="TenantSupervisor" disabled={!canModifyType}>
                Supervisore
              </option>
              <option value="TenantAdministrator" disabled>
                Amministratore
              </option>
              <option value="TenantApiUser" disabled>
                Utente Api
              </option>
            </Select>
          </div>

          {getError("tenantUserType") && (
            <div className="error-message">
              {getError("tenantUserType").message}
            </div>
          )}
          <div className="toggler-container">
            <Toggler hasMargin checked={isActive} setIsChecked={setIsActive} />
            {getError("isAvtive") && (
              <div className="error-message">
                {getError("isAvtive").message}
              </div>
            )}
            <div>Attivo</div>
          </div>
        </div>

        {/* Buttons */}
        <div className="lower-section">
          <div className="buttons2">
            <Button ghostSmall onClick={() => props.handleDrawer(false)}>
              Annulla
            </Button>
            <Button onClick={onDeleteButtonClick} primaryLightSmall>
              Elimina
            </Button>
            <Button onClick={onFormSubmit} type="button" primary>
              Conferma
            </Button>
          </div>
          {showDeleteError && (
            <div className="error-message margin-top-1">{deleteError}</div>
          )}
          {showNotAllowedError && (
            <div className="error-message margin-top-1">{notAllowedError}</div>
          )}
        </div>
      </div>

      <EliminationModal
        title={"Conferma eliminazione"}
        text={"Sei sicuro di voler Eliminare questo utente?"}
        onAnulla={() => setIsDeleteModalOpen(false)}
        onElimina={onUserDelete}
        isModalOpen={isDeleteModalOpen}
        setIsModalOpen={setIsDeleteModalOpen}
      />
    </Wrapper>
  );
};

export default EditUserForm;
