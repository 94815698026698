import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: fit-content;

  .content {
    display: flex;
    flex-direction: column;
    width: calc(100% - 4rem);
    margin: 1rem 2rem 2rem 2rem;
    height: fit-content;
  }

  .loading-container {
    margin-top: 2rem;
    width: 100%;
  }

  .pagination-container {
    margin-top: 2rem;
  }

  .button-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: end;
    margin-top: 2rem;
  }
`;
