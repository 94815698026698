import logo from "../../../assets/images/tsLogoColor.png";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import { useUserContext } from "../../../context/userContext";
import { useState } from "react";
import Button from "../../../components/button";
import Input from "../../../components/input";
import { Wrapper } from "./style";

const LoginPage = () => {
  const [loginError, setLoginError] = useState("");
  const { handleLogin } = useUserContext();

  return (
    <Wrapper>
      <div className="container">
        <div>
          <img className={"logo"} src={logo} alt={"ts-logo"} />
        </div>
        <LoginForm
          handleLogin={handleLogin}
          loginError={loginError}
          setLoginError={setLoginError}
        />
        <div className="links">
          <Link className="link" to="/forgot-password">
            Password dimenticata?
          </Link>
        </div>
      </div>
    </Wrapper>
  );
};

const LoginForm = (props) => {
  const validate = (values) => {
    let errors = {};
    if (!values.userName) {
      errors.userName = "Inserire nome utente!";
    }
    if (!values.password) {
      errors.password = "Inserire la password!";
    }
    return errors;
  };

  const formik = useFormik({
    initialValues: {
      userName: "",
      password: "",
    },
    validate,
    onSubmit: (values) => {
      props.handleLogin(values).catch((error) => {
        props.setLoginError(error.message);
      });
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Input
        autoComplete="on"
        className={`${
          formik.touched.userName && formik.errors.userName
            ? "form-login-input form-error"
            : "form-login-input "
        }`}
        id="userName"
        name="userName"
        type="text"
        placeholder="E-mail"
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        value={formik.values.userName}
      />
      {formik.touched.userName && formik.errors.userName ? (
        <div className="error-message">{formik.errors.userName}</div>
      ) : null}
      <Input
        autoComplete="new-password"
        className={`${
          formik.touched.password && formik.errors.password
            ? "form-login-input form-error"
            : "form-login-input "
        }`}
        id="password"
        name="password"
        type="password"
        placeholder="Password"
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        value={formik.values.password}
      />
      {formik.touched.password && formik.errors.password ? (
        <div className="error-message">{formik.errors.password}</div>
      ) : null}
      <div className="error-message">
        {props.loginError ? props.loginError : null}
      </div>
      <Button primary type="submit" className=" login-form-button">
        Accedi
      </Button>
    </form>
  );
};

export default LoginPage;
