import React, { useRef, useEffect } from "react";
import styled from "styled-components";
import Button from "../button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBuilding } from "@fortawesome/free-solid-svg-icons";
import { useUserContext } from "../../context/userContext";
import { getFirstLetter } from "../../utils/functions";

function ProfileDropdown(props) {
  const { handleLogout, myUser } = useUserContext();
  const Ref = useRef(null);

  const logoutHandler = () => {
    handleLogout();
  };

  useEffect(() => {
    const closeModal = (e) => {
      if (Ref.current && props.isOpen && !Ref.current.contains(e.target)) {
        props.setIsOpen(false);
      }
    };
    document.addEventListener("mousedown", closeModal);
    return () => {
      document.removeEventListener("mousedown", closeModal);
    };
  }, [props, props.isOpen]);

  if (!props.isOpen) return null;

  return (
    <Wrapper ref={Ref}>
      <div className="top-section">
        <h6>Informazioni</h6>{" "}
        <Button primary onClick={logoutHandler}>
          Esci
        </Button>
      </div>
      <div className="middle-section">
        <div className="row-container">
          <h6>Utente</h6>
          <h5 className="font-size" onClick={props.onChangePassword}>
            Cambia password
          </h5>
        </div>
        <div className="row-container">
          <div className="user-image-container">
            <div className="image">
              {getFirstLetter(myUser.name)}
              {getFirstLetter(myUser.surname)}
            </div>
          </div>
          <div className="user-data-container">
            <h6>
              {myUser.name} {myUser.surname}
            </h6>
            <h6 className="email">email: {myUser.account}</h6>
          </div>
        </div>
      </div>
      <div className="bottom-section">
        <div className="row-container">
          <FontAwesomeIcon className="company-icon" icon={faBuilding} />
          <div className="user-data-container">
            <h6>{myUser.companyName}</h6>
            <h6>{myUser.vatNumber}</h6>
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

export default ProfileDropdown;

const Wrapper = styled.div`
  position: absolute;
  width: 320px;
  height: 260px;
  background-color: white;
  top: 56px;
  right: 8px;
  border-radius: 4px;
  box-shadow: 0 0 7px grey;

  .top-section {
    height: 60px;
    width: 100%;
    border-bottom: 1px solid #f2f5f8;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0 1rem;
  }

  .middle-section {
    width: 100%;
    height: 128px;
    border-bottom: 1px solid #f2f5f8;
  }

  .bottom-section {
    width: 100%;
    height: 50px;
  }

  .row-container {
    width: 100%;
    padding: 0.5rem 1rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  .font-size {
    font-size: 1rem;
    cursor: pointer;
    color: #0090d1;
  }

  .font-size:hover {
    filter: brightness(1.1);
  }

  .user-image-container {
    width: 20%;
    height: fit-content;
  }

  .user-data-container {
    width: 80%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    max-width: 230px;
    white-space: nowrap;
    overflow-x: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .image {
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
    border: 2px solid #0090d1;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
    color: #005075;
    font-weight: 400;
  }

  .company-icon {
    color: #005075;
    height: 1.75rem;
    margin-left: 0.75rem;
  }
`;
