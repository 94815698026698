import React, { useEffect, useState } from "react";
import styled from "styled-components";
import logo from "../../../src/assets/images/tsLogoColor.png";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const DetailedTooltip = (props) => {
  const tooltipRef = React.useRef(null);
  const [isTooltipOpen, setIsTooltioOpen] = useState(false);

  useEffect(() => {
    const closeModal = (e) => {
      if (
        tooltipRef.current &&
        isTooltipOpen &&
        !tooltipRef.current.contains(e.target)
      ) {
        setIsTooltioOpen(false);
      }
    };
    document.addEventListener("mousedown", closeModal);
  }, [isTooltipOpen]);

  return (
    <TooltipWrapper>
      <TooltipIcon onClick={() => setIsTooltioOpen(true)}>
        ℹ️
      </TooltipIcon>
      {isTooltipOpen && (
        <Tooltip directionLeft={props.left} ref={tooltipRef}>
          <div className="top-section">
            <img className={"tslogo"} src={logo} alt={"ts-logo"} />
          </div>
          <div className="middle-section">
            {props.title}
            <FontAwesomeIcon
              className="close-icon"
              icon={faClose}
              onClick={() => setIsTooltioOpen(false)}
            />
          </div>
          <div className="bottom-section">{props.children}</div>
        </Tooltip>
      )}
    </TooltipWrapper>
  );
};

const TooltipWrapper = styled.div`
  width: fit-content;
  height: fit-content;
  position: relative;
`;

const TooltipIcon = styled.div`
  width: 0.8rem;
  height: 0.8rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #0090d1;
  cursor: pointer;
  color: white;
  font-size: 0.6rem;
  margin-left: 8px;
`;

const Tooltip = styled.div`

  position: absolute;
  width: 350px;
  height: 350px;
  background-color: white;
  border-radius: 4px;
  box-shadow: 0 0 7px grey;
  top: -175px;
  left: ${props => props.directionLeft ? "-350px" : "15px"};
  display: flex;
  flex-direction: column;
  padding: 12px;
  z-index: 4;

  .top-section {
    height: 46px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .middle-section {
    height: 40px;
    width: 100%;
    font-weight: 500;
    font-size: 14px;
    color: black;
    display: flex;
    align-items: center;
    position: relative;
  }

  .bottom-section {
    height: 230px;
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    margin-top: 10px;
    padding-right: 12px;

    ::-webkit-scrollbar {
      width: 10px;
    }
    ::-webkit-scrollbar-track {
      background-color: #f2f5f8;
      border-radius: 4px;
    }
    ::-webkit-scrollbar-thumb {
      background-color: darkgray;
      border-radius: 4px;
    }
  }

  .tslogo {
    width: 240px;
  }

  .close-icon {
    color: black;
    position: absolute;
    width: 18px;
    height: 18px;
    right: -4px;
    top: 11px;
    cursor: pointer;
  }
`;
