import axios from "axios";
import { Axios, headers } from "./AxiosConfig";


export const LogOut = async () => {
  await axios.delete("User/logout");
};

export const logIn = async (credentials) => {
  try {
    const response = await Axios.post("Auth/login", credentials, headers);
    return response;
  } catch (err) {
    if (err.response.status === 401) {
      throw new Error("Username o password errati");
    } else  if (err.response.status === 403) {
      throw new Error(err.response.data.Message);
    } else {
      throw new Error("Errore nella connessione");
    }
  }
};
