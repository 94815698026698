import styled, { css } from "styled-components";

const Button = styled.button`
  height: 2rem;
  line-height: 2rem;
  padding: 0px 1.25rem;
  border: 0px;
  font-family: Roboto, sans-serif;
  font-size: 1rem;
  display: inline-block;
  text-decoration: none;
  border-radius: 2px;
  cursor: pointer;
  user-select: none;

  &:disabled {
    cursor: not-allowed;
  }

  ${(props) =>
    props.primary &&
    css`
      height: 32px;
      background-image: linear-gradient(315deg, #00c3ea, #0090d1);
      color: white;
      outline: none;
      text-shadow: 1px 1px 0 rgba(21, 41, 53, 0.24);
      background-repeat: no-repeat;
      border: 0px !important;
      &:hover {
        background-position: 0 -100%;
        background-image: none;
        background-color: #00c3ea;
      }
      &:active {
        text-shadow: 1px 1px 0 rgba(21, 41, 53, 0.48);
      }
      &:disabled {
        text-shadow: none;
        background-image: none;
        background-color: #c9d9e8;
        cursor: not-allowed;
        &:hover {
          background-color: #c9d9e8;
        }
        &:active {
          text-shadow: none;
        }
      }
    `}

  ${(props) =>
    props.primaryLarge &&
    css`
      height: 40px;
      min-width: 62px;
      background-image: linear-gradient(315deg, #00c3ea, #0090d1);
      color: white;
      outline: none;
      text-shadow: 1px 1px 0 rgba(21, 41, 53, 0.24);
      background-repeat: no-repeat;
      border: 0px !important;
      &:hover {
        background-position: 0 -100%;
        background-image: none;
        background-color: #00c3ea;
      }
      &:active {
        text-shadow: 1px 1px 0 rgba(21, 41, 53, 0.48);
      }
      &:disabled {
        text-shadow: none;
        background-image: none;
        background-color: #c9d9e8;
        cursor: not-allowed;
        &:hover {
          background-color: #c9d9e8;
        }
        &:active {
          text-shadow: none;
        }
      }
    `}

  ${(props) =>
    props.light &&
    css`
      background: white;
      color: #0090d1;
      text-shadow: none;
      border: 1px solid white;
      &:hover {
        background: #b9e6f6;
      }
      &.vapor_btn-disabled,
      &:disabled {
        background: white;
        color: #c9d9e8;
        &:hover {
          background: white;
        }
      }
    `}
    ${(props) =>
    props.ghost &&
    css`
      height: 40px;
      border: 1px solid #0090d1;
      color: #0090d1;
      background: none;
      &:hover {
        background: #0090d1;
        color: white;
      }
      &.vapor_btn-disabled,
      &:disabled {
        border: 1px solid #c9d9e8;
        color: #c9d9e8;
        &:hover {
          background: none;
        }
      }
      &.vapor_btn-light {
        color: white;
        border: 1px solid white;
        &:hover {
          background: white;
          color: #0090d1;
        }
      }
      &.vapor_btn-disabled {
        border: 1px solid #c9d9e8 !important;
        background: none;
        color: #c9d9e8 !important;
        &:hover {
          background: none;
        }
      }
    `}
    ${(props) =>
    props.ghostSmall &&
    css`
      height: 32px;
      border: 1px solid #0090d1;
      color: #0090d1;
      background: none;
      &:hover {
        background: #0090d1;
        color: white;
      }
      &.vapor_btn-disabled,
      &:disabled {
        border: 1px solid #c9d9e8;
        color: #c9d9e8;
        &:hover {
          background: none;
        }
      }
      &.vapor_btn-light {
        color: white;
        border: 1px solid white;
        &:hover {
          background: white;
          color: #0090d1;
        }
      }
      &.vapor_btn-disabled {
        border: 1px solid #c9d9e8 !important;
        background: none;
        color: #c9d9e8 !important;
        &:hover {
          background: none;
        }
      }
    `}
    ${(props) =>
    props.alert &&
    css`
      border: 1px solid #d82829;
      color: #d82829;
      background: none;
      &:hover {
        background: #fc4e3d;
        color: white;
      }
    `}

    ${(props) =>
    props.margin &&
    css`
      margin-left: 2rem;
    `}

${(props) =>
    props.margin8 &&
    css`
      margin-left: 0.5rem;
      margin-right: 0.5rem;
    `}
  
    ${(props) =>
    props.marginRight &&
    css`
      margin-right: 0.5rem;
    `}

    ${(props) =>
    props.marginLeft &&
    css`
      margin-left: 0.5rem;
    `}

${(props) =>
    props.margin1rem &&
    css`
      margin-left: 1rem;
      margin-right: 1rem;
    `}

    ${(props) =>
    props.primaryLight &&
    css`
      height: 40px;
      border-radius: 2px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #d82829;
      color: white;
      text-shadow: none;
      font-size: 1rem;
      font-family: "Roboto";
      font-weight: 500;
      cursor: pointer;

      &:hover {
        background-color: #fc4e3d;
      }

      &:active {
        background-color: #d82829;
      }

      &:disabled {
        background-color: #d8d8d9;
        cursor: not-allowed;
      }
    `}

${(props) =>
    props.primaryLightSmall &&
    css`
      width: 100px;
      height: 32px;
      border-radius: 2px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #d82829;
      color: white;
      text-shadow: none;
      font-size: 1rem;
      font-family: "Roboto";
      font-weight: 500;
      cursor: pointer;

      &:hover {
        background-color: #fc4e3d;
      }

      &:active {
        background-color: #d82829;
      }

      &:disabled {
        background-color: #d8d8d9;
        cursor: not-allowed;
      }
    `}

    ${(props) =>
    props.secondaryBlue &&
    css`
      height: 40px;
      border-radius: 2px;
      border: solid 1px #0090d1;
      display: flex;
      align-items: center;
      justify-content: center;
      background: transparent;
      color: #0090d1;
      text-shadow: none;
      font-size: 1rem;
      font-family: "Roboto";
      font-weight: 500;
      cursor: pointer;

      &:hover {
        background-color: #0090d1;
        color: white;
      }

      &:active {
        background-color: #005075;
        color: white;
      }

      &:disabled {
        background-color: #d8d8d9;
        cursor: not-allowed;
        color: white;
        border: #c9d9e8 1px solid;
      }
    `}
`;

export default Button;
