import React from "react";
import styled from "styled-components";
import {
  faArrowRotateRight,
  faAngleRight,
  faAngleLeft,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Input from "../input";
import Select from "../select";

function PageControls(props) {
  return (
    <ControlsContainer className="controls-row-container">
      <div className="filter-controls">
        <FontAwesomeIcon
          icon={faArrowRotateRight}
          className="refresh-icon"
          onClick={props.getData}
        />
        {props.hasRemoveFilters && (
          <div onClick={() => props.refreshData()} className="delete-filters">
            Annulla filtri
          </div>
        )}
      </div>
      <div className="page-controls">
        <div className="row-container">
          <h6>Pagina:</h6>
          <Input
            max={`${props.totalPageNumber}`}
            className={"pages-input"}
            type="number"
            name="page"
            id="page"
            min="1"
            value={props.pageNumber}
            onChange={(e) =>
              e.target.value > props.totalPageNumber
                ? null
                : props.setPageNumber(e.target.value)
            }
          />
          <div className="row-container">
            <h6>Di</h6> &nbsp; <h6>{props.totalPageNumber}</h6>
          </div>
          {/* {`di ${totalPageNumber}`} */}
          <div className="row-container">
            <FontAwesomeIcon
              icon={faAngleLeft}
              onClick={() => props.decreasePageNumber(props.pageNumber - 1)}
              className={"left-arrow"}
            />
            <FontAwesomeIcon
              icon={faAngleRight}
              className={"right-arrow"}
              onClick={() =>
                props.pageNumber === props.totalPageNumber
                  ? null
                  : props.setPageNumber(props.pageNumber + 1)
              }
            />
          </div>
        </div>
        <div className="row-container margin-left-4">
          <h6>Righe per pagina:</h6>
          <Select
            className="row-per-page-select"
            name="rowPerPage"
            id="rowPerPage"
            value={props.rowsPerPage}
            onChange={props.onRighePerPaginaChange}
          >
            <option value={10}>10</option>
            <option value={20}>20</option>
            <option value={50}>50</option>
            <option value={100}>100</option>
          </Select>
          <h6>
            1-{props.rowsPerPage} &nbsp; di &nbsp; {props.totalRecords}
          </h6>
        </div>
      </div>
    </ControlsContainer>
  );
}

export default PageControls;

const ControlsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  .filter-controls {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .page-controls {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .refresh-icon {
    color: #0090d1;
    height: 1.5rem;
    cursor: pointer;
  }

  .delete-filters {
    color: #0090d1;
    margin-left: 2rem;
    cursor: pointer;
  }

  .row-container {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .pages-input {
    width: 4rem;
    margin-left: 1rem;
    margin-right: 1rem;
  }

  .left-arrow {
    margin-left: 1rem;
    margin-right: 1.5rem;
    color: #0090d1;
    cursor: pointer;
  }

  .right-arrow {
    color: #0090d1;
    cursor: pointer;
  }

  .margin-left-4 {
    margin-left: 4rem;
  }

  .row-per-page-select {
    width: 4rem;
    margin-left: 1rem;
    margin-right: 1rem;
  }
`;
