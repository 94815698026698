import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  min-height: calc(100vh - 288px);
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 870px;
`;

export const PageTitle = styled.h4`
  color: black;
  width: 100%;
  font-weight: 600;
  text-align: start;
`;

export const PageDescription = styled.h6`
  color: black;
  font-size: 14px;
`;

export const IdentificationSectionContainer = styled.div`
  display: flex;
  flex-direction: row;
  height: fit-content;
  margin-top: 24px;
`;

export const IdentificationSelectContainer = styled.div`
  width: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const IdentificationCard = styled.div`
  width: 100%;
  height: 70px;
  margin-bottom: 24px;
  background: white;
  border: solid 1px transparent;
  border-bottom: solid 3px transparent;
  box-shadow: 0px 2px 8px 0px rgba(21, 41, 53, 0.32);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 16px;
  align-items: center;
  cursor: pointer;
  color: rgb(0, 70, 102);
  font-size: 24px;
  font-weight: 400;
  border-color: ${(props) => (props.selected ? "#0090D1" : "transparent")};

  .icon {
    height: 24px;
    width: 24px;
    margin: 0 16px 0 0;
    color: #004666;
  }
`;

export const NameContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const Divider = styled.div`
  height: 260px;
  width: 2px;
  background-color: #c9d9e8;
  margin: 0 32px;
`;

export const InformationContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 404px;
`;

export const ButtonContainer = styled.div`
  border-top: 2px solid #c9d9e8;
  padding: 0 16px;
  width: 100%;
  height: 72px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: end;
  margin-top: 32px;
`;
