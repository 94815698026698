import React, { useEffect, useState } from "react";
import {
  Wrapper,
  InputContainer,
  Label,
  Form,
  ButtonContainer,
  PageTitle,
  PageDescription,
  RowInputContainer,
  TagContainer,
  Tag,
} from "./style";
import Input from "../../../../components/input";
import Button from "../../../../components/button";

import { useNavigate } from "react-router-dom";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import InputError from "../../../../components/inputError";

function Session(props) {
  const {
    setSessionData,
    handleStorageData,
    handleStorageStep,
    formSteps,
    sessionData,
  } = props;
  const navigate = useNavigate();
  const [newTag, setNewTag] = useState("");
  const [data, setData] = useState({
    description: "",
    tag: "",
    expirationDate: "",
  });
  const [dataError, setDataError] = useState({
    descriptionError: "",
    tagError: "",
    expirationDateError: "",
  });

  const isFormInvalid = () => {
    return (
      data.description === "" ||
      data.expirationDate === "" ||
      dataError.descriptionError !== "" ||
      dataError.tagError !== "" ||
      dataError.expirationDateError !== ""
    );
  };

  useEffect(() => {
    setData({
      description: sessionData?.description || "",
      tag: sessionData?.tag || "",
      expirationDate: sessionData?.expirationDate || "",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAddTag = () => {
    if (!newTag.trim()) return;

    setData((prevData) => ({
      ...prevData,
      tag: prevData.tag ? `${prevData.tag},${newTag.trim()}` : newTag.trim(),
    }));

    setNewTag("");
  };

  const handleRemoveTag = (indexToRemove) => {
    setData((prevData) => {
      const tagsArray = prevData.tag.split(",");
      tagsArray.splice(indexToRemove, 1);
      return {
        ...prevData,
        tag: tagsArray.join(","),
      };
    });
  };

  const handleChange = (e) => {
    const { value, name } = e.target;

    const checkDateValidity = (value, message) => {
      const date = new Date(value);
      const today = new Date();
      today >= date
        ? setDataError((prev) => ({ ...prev, expirationDateError: message }))
        : setDataError((prev) => ({ ...prev, expirationDateError: "" }));
    };

    if (name === "expirationDate") {
      checkDateValidity(value, "Scegli una data futura");
    }
    if (name !== "tag") {
      setData((prevData) => ({ ...prevData, [name]: value }));
    }

    setData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = () => {
    setSessionData((prev) => ({ ...prev, ...data }));
    handleStorageData(data);
    handleStorageStep(formSteps[1]);
    navigate("/create-session/recognition");
  };

  return (
    <Wrapper>
      <Form>
        <PageTitle>Inserisci i dati della sessione</PageTitle>
        <PageDescription>
          Inserisci la descrizione e la data di scadenza della sessione. Puoi
          aggiungere anche uno o più tag.
        </PageDescription>
        <RowInputContainer>
          <InputContainer>
            <Label>Descrizione *</Label>
            <Input
              type="text"
              placeholder="Descrizione"
              name="description"
              onChange={(e) => handleChange(e)}
              value={data.description}
            />
            <InputError message={dataError.description} />
          </InputContainer>

          <InputContainer marginLeft small>
            <Label>Data di scadenza *</Label>
            <Input
              type="date"
              placeholder="Data di scadenza"
              name="expirationDate"
              onChange={(e) => handleChange(e)}
              value={data.expirationDate}
            />
            <InputError message={dataError.expirationDateError} />
          </InputContainer>
        </RowInputContainer>
        <RowInputContainer alignBottom>
          <InputContainer>
            <Label>Tag</Label>
            <Input
              type="text"
              placeholder="Tag"
              name="tag"
              onChange={(e) => setNewTag(e.target.value)} // Directly update newTag state
              value={newTag} // Use newTag state for input value
            />
          </InputContainer>
          <Button ghost marginLeft onClick={handleAddTag} disabled={!newTag}>
            Aggiungi
          </Button>
        </RowInputContainer>
        <TagContainer>
          {data.tag &&
            data.tag.split(",").map((item, index) => (
              <Tag key={index}>
                {item}
                <FontAwesomeIcon
                  icon={faClose}
                  className="close-icon"
                  onClick={() => handleRemoveTag(index)}
                />
              </Tag>
            ))}
        </TagContainer>
      </Form>
      <ButtonContainer>
        <Button primaryLarge onClick={handleSubmit} disabled={isFormInvalid()}>
          Avanti
        </Button>
      </ButtonContainer>
    </Wrapper>
  );
}

export default Session;
