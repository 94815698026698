import React from "react";
import styled from "styled-components";

function TooltipData() {
  return (
    <Wrapper>
      <div className="tooltip-row">
        <strong className="yellow">Open</strong>:&nbsp; La sessione è stata
        aperta sono i corso le attività di invio mail
      </div>
      <div className="tooltip-row">
        <strong className="green">Ready</strong>:&nbsp; La mail sono state
        inviate e le attività legate alla sessione di riconoscimento e/o firma
        sono iniziate
      </div>
      <div className="tooltip-row">
        <strong className="grey">Closed</strong>:&nbsp; La sessione si è chiusa
        con successo
      </div>
      <div className="tooltip-row">
        <strong className="red">Failed</strong>:&nbsp; La sessione è fallita
        perché almeno uni degli utenti ha fallito nella sua attività di
        riconoscimento
      </div>
      <div className="tooltip-row">
        <strong className="red">Scaduta</strong>:&nbsp; La sessione è scaduta 
        perche è scaduto il timeout
      </div>
    </Wrapper>
  );
}

export default TooltipData;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  .tooltip-row {
    color: black;
    margin-bottom: 0.5rem;
    font-weight: 400;
    font-size: 16px;
  }


  .yellow {
    color: rgb(245, 190, 40);
  }

  .green {
    color: green;
  }

  .grey {
    color: grey;
  }

  .red {
    color: red;
  }
`;
