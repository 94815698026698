import React, { useContext, useState } from "react";

const NotificationContext = React.createContext();

export const NotificationProvider = ({ children }) => {
    const [toctocEmailNotification, setToctocEmailNotification] = useState([])  
    
  return (
    <NotificationContext.Provider value={{ toctocEmailNotification, setToctocEmailNotification }}>
      {children}
    </NotificationContext.Provider>
  );
};

export const useNotificationContext = () => {
  return useContext(NotificationContext);
};
