import React from 'react'
import { Pagination as MuiPagination } from "@mui/material";

function Pagination(props) {
  return (
    <MuiPagination
    sx={{
      ".MuiPagination-ul": {
        color: "blue",
        justifyContent: "right",
      },
      ".MuiPaginationItem-text": {
        color: "#0090d1",
        fontWeight: "500",
      },
      ".Mui-selected": {
        backgroundColor: "#DEF0F7",
      },
      ".Mui-selected:hover": { backgroundColor: "#DEF0F7" },
    }}
    count={props.totalPageNumber}
    defaultPage={1}
    page={props.pageNumber}
    onChange={(e, page) => props.setPageNumber(page)}
    siblingCount={0}
    shape="rounded"
  />
  )
}

export default Pagination