import React from "react";
import styled from "styled-components";

function TooltipData() {
  return (
    <Wrapper className="tooltip-content">
      <div className="tooltip-row">
        <strong>Normale</strong>:&nbsp; E' owner di sessioni di
        firma/identificazione che gestisce tramite dashboard
      </div>
      <div className="tooltip-row">
        <strong>Supervisore</strong>:&nbsp; E' owner di sessioni di
        firma/identificazione. Tramite dashboard gestisce le sessioni di tutti
        gli owner
      </div>
      <div className="tooltip-row">
        <strong>Amministratore</strong>:&nbsp; Amministra il Tenant gestendo i
        suoi utenti. Non accede alle funzionalità di backoffice relative alle
        sessioni
      </div>
      <div className="tooltip-row">
        <strong>Utente Api</strong>:&nbsp; da utilizzare per l’autenticazione in
        fase di utilizzo delle API della sessione. Non accede ad alcuna
        funzionalità del backoffice.
      </div>
    </Wrapper>
  );
}

export default TooltipData;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  
  .tooltip-row {
    color: black;
    margin-bottom: 0.5rem;
    font-weight: 400;
  }
`;
