import { Drawer } from "@mui/material";
import React from "react";
import styled from "styled-components";
import EditUserForm from "./EditUserForm";
import NewUserForm from "./NewUserForm";

function UserDrawer(props) {
  return (
    <Drawer
      anchor="right"
      PaperProps={{
        sx: {
          width: "500px",
          overflow: "auto",
          overflowY: "scroll",
          margin: "48px 0 0 0"
        },
      }}
      open={props.isOpen}
      onClose={() => {
        props.setIsOpen(false);
      }}
      variant="temporary"
    >
      <ContentWrapper>
        <Title>
          <h4>{props.isNew ? "CREA NUOVO UTENTE" : "MODIFICA UTENTE"}</h4>
        </Title>
        {props.isNew ? (
          <NewUserForm
            token={props.myUser.token}
            tenantId={props.myUser.tenant}
            getUsers={props.listAllUsers}
            getData={props.getData}
            handleDrawer={props.handleDrawer}
          />
        ) : (
          <EditUserForm
            utente={props.utente}
            handleDrawer={props.handleDrawer}
            token={props.myUser.token}
            getData={props.getData}
          />
        )}
      </ContentWrapper>
    </Drawer>
  );
}

export default UserDrawer;

const ContentWrapper = styled.div`
height: calc(100% - 6rem);
`

const Title = styled.div`
  width: 100%;
  height: 4rem;
  border-bottom: solid 2px #0090d1;
  display: flex;
  justify-content: center;
  align-items: center;
`;
