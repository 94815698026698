import styled, { css } from "styled-components";

const commonStyles = css`
  font-family: "Roboto";
  display: block;
  font-size: 0.875rem;
  font-weight: 400;
  letter-spacing: 0.25px;
`;

const borderColorNormal = "#c9d9e8";
const borderColorHoverActive = "#0090d1";
const borderColorFocus = "#008bce";

const Input = styled.input`
  ${commonStyles}
  width: 100%;
  height: 2.5rem;
  line-height: 1.125rem;
  text-indent: 0.5rem;
  background: #f2f5f8;
  border: 1px solid ${borderColorNormal};
  color: #152935;
  ::placeholder {
    color: #c1c1c4;
  }
  &:focus {
    outline: none;
    border-color: ${borderColorFocus};
  }
  &:active,
  &:hover {
    border-color: ${borderColorHoverActive};
    outline: none;
    cursor: pointer;
  }
  &:disabled {
    background: #fafbfd;
    cursor: not-allowed;
    border-color: #c1c1c4;
    color: #c1c1c4;
    opacity: 0.64;
  }
  &:read-only {
    color: #9da6ad;
  }

  ${(props) =>
    props.type === "checkbox" &&
    css`
      vertical-align: middle;
      -webkit-appearance: none;
      -moz-appearance: none;
      background: none;
      outline: 0;
      flex-grow: 0;
      border-radius: 2px;
      background-color: #ffffff;
      height: 1rem;
      width: 1rem;
      border: 1px solid ${borderColorHoverActive};
      text-align: center;
      cursor: pointer;
      position: relative; /* New */
      &:checked {
        background-image: linear-gradient(
          315deg,
          #00c3ea,
          ${borderColorHoverActive}
        );
        &:before {
          content: "";
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          display: block;
          height: 1.5rem;
          width: 1.5rem;
          background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 24 24'%3E %3Cpath d='M15.88 8.29L10 14.17l-1.88-1.88a.996.996 0 1 0-1.41 1.41l2.59 2.59c.39.39 1.02.39 1.41 0L17.3 9.7a.996.996 0 0 0 0-1.41c-.39-.39-1.03-.39-1.42 0z' fill='%23fff'/%3E %3C/svg%3E");
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;
        }
      }
      &:disabled {
        background-color: transparent;
        border-color: ${borderColorNormal};
        cursor: not-allowed;
      }
    `}

  ${(props) =>
    props.type === "radio" &&
    css`
      height: 1rem;
      width: 1rem;
      outline: none;
      border: 1px solid ${borderColorHoverActive};
      -webkit-appearance: none;
      -moz-appearance: none;
      background-color: #ffffff;
      border-radius: 50%;
      cursor: pointer;
      position: relative;

      &:after {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border-radius: 50%;
        height: 0.5rem;
        width: 0.5rem;
        background-color: transparent;
        transition: background-color 0.2s;
      }

      &:checked:after {
        background-color: ${borderColorHoverActive};
      }
    `}
`;

export default Input;
