export const fieldValidationConfig = {
  name: {
    regex: /^[a-zA-Z ]+$/,
    emptyErrorMessage: "Il nome è obbligatorio",
    invalidErrorMessage: "Questo campo può contenere solo lettere.",
  },
  surname: {
    regex: /^[a-zA-Z ']+$/,
    emptyErrorMessage: "Il cognome è obbligatorio",
    invalidErrorMessage: "Questo campo può contenere solo lettere.",
  },
  fiscalCode: {
    regex:
      /^([A-Z]{6}[0-9LMNPQRSTUV]{2}[ABCDEHLMPRST]{1}[0-9LMNPQRSTUV]{2}[A-Z]{1}[0-9LMNPQRSTUV]{3}[A-Z]{1})$|([0-9]{11})$/,
    emptyErrorMessage: "Il Codice Fiscale è obbligatorio",
    invalidErrorMessage: "Codice fiscale non valido.",
  },
  mobileNumber: {
    regex: /^[+0-9]{8,15}$/,
    emptyErrorMessage: "Il numero di telefono è obbligatorio",
    invalidErrorMessage: "Numero di telefono non valido.",
  },
  email: {
    regex: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
    emptyErrorMessage: "L’email è obbligatoria",
    invalidErrorMessage: "Formato email non valido.",
  },
  cityName: {
    regex: /^[a-zA-Z ']+$/,
    emptyErrorMessage: "La cita di nascita è obbligatorio",
    invalidErrorMessage: "Questo campo può contenere solo lettere.",
  },
  birthDate: {
    regex: /^\d{4}-\d{2}-\d{2}$/, // date is in YYYY-MM-DD format
    emptyErrorMessage: "La data di nascita è obbligatoria",
    invalidErrorMessage:
      "Formato data non valido. Utilizzare il formato dd-mm-yyyy.",
  },
  gender: {
    regex: /^(M|F)$/,
    emptyErrorMessage: "Il genere e obbligatorio",
    invalidErrorMessage: "Formato data non valido.",
  },
};
