import axios from "axios";

export const Axios = axios.create({
  baseURL: process.env.REACT_APP_API_KEY,
});

// retriving the access token from the object myUser in localStorage
export const getLocalAccessToken = () => {
  const accessToken = JSON.parse(localStorage.getItem("myUser"))?.token;
  return accessToken;
};

// retriving the refresh token from the object myUser in localStorage
export const getLocalRefreshToken = () => {
  const refreshToken = JSON.parse(localStorage.getItem("myUser"))?.refreshToken;
  return refreshToken;
};

const accessToken = JSON.parse(localStorage.getItem("myUser"))?.token;

export const headers = {
  headers: {
    "Content-Type": "application/json",
    Authorization: `Bearer ${accessToken}`,
  },
};

let isRefreshing = false;
let apiRequests = [];

const onLogout = () => {
  localStorage.clear();
  window.history.pushState({}, "Dashboard", "/login");
  window.location.reload();
};

function onAccessTokenFetched(access_token) {
  apiRequests = apiRequests.filter((callback) => callback(access_token));
}

function addApiRequests(callback) {
  apiRequests.push(callback);
}

// setting the axiost request interceptor
Axios.interceptors.request.use(
  (config) => {
    let access_token = getLocalAccessToken();
    if (access_token) {
      if (config.headers) {
        config.headers["Authorization"] = `Bearer ${access_token}`;
        config.headers["Content-Type"] = "application/json";
      }
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

//setting the Axios response interceptor
Axios.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalConfig = error.config;
    if (
      error.response.status === 401 &&
      originalConfig.url === "Auth/refresh"
    ) {
      onLogout();
      return Promise.reject();
    } else if (originalConfig.url !== "Auth/login" && error.response) {
      if (error.response.status === 401 && !originalConfig._retry) {
        originalConfig._retry = true;

        if (!isRefreshing) {
          isRefreshing = true;
          try {
            const response = await Axios.post("Auth/refresh", {
              token: getLocalAccessToken(),
              refreshToken: getLocalRefreshToken(),
            });

            if (response.status === 200) {
              const accessToken = response.data.token;
              const refreshToken = response.data.refreshToken;
              isRefreshing = false;
              onAccessTokenFetched(accessToken);

              //setting the new tokens in localStorage
              let localUser = JSON.parse(localStorage.getItem("myUser"));
              let updatedUser = {
                ...localUser,
                token: accessToken,
                refreshToken: refreshToken,
              };
              localStorage.setItem("myUser", JSON.stringify(updatedUser));

              Axios.defaults.headers.common["Authorization"] =
                "Bearer" + accessToken;
              return Axios(originalConfig);
            } else {
              onLogout();
              return Promise.reject();
            }
          } catch (_error) {
            return Promise.reject(_error);
          }
        }
        const retryOriginalRequest = new Promise((resolve) => {
          addApiRequests((access_token) => {
            originalConfig.headers.Authorization = "Bearer " + access_token;
            resolve(Axios(originalConfig));
          });
        });
        return retryOriginalRequest;
      }
    }

    return Promise.reject(error);
  }
);
