import React, { useEffect, useState } from "react";
import {
  AddUserContainer,
  AddUserSection,
  AddUserText,
  ButtonContainer,
  Wrapper,
  UserCard,
  UserData,
  PageTitle,
  PageDescription,
  UserCardRow,
  UserProfileContainer,
  UserProfileImage,
  IconsContainer,
  LoadingContainer,
} from "./style";
import { useNavigate } from "react-router-dom";
import Button from "../../../../components/button";
import Modal from "../../../../components/modal";
import { faTrashCan, faEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { combineFirstLetters } from "../../../../utils/functions";
import EliminationModal from "../eliminationModal";
import SubjectModal from "../subjectModal";
import { getApiToken, startSession } from "../../../../api/backofficeAPI";
import ConfirmationModal from "../confirmationModal";
import Loading from "../../../../components/loading";
import { useToast } from "../../../../context/toastNotificationContext";

function Subjects(props) {
  const {
    setSessionData,
    handleStorageData,
    formSteps,
    handleStorageStep,
    handleToPreviousStep,
    sessionData,
    supportedSignatures,
  } = props;
  const navigate = useNavigate();
  const [isSignerModalOpen, setIsSignerModalOpen] = useState(false);
  const [isElimiationModalOpen, setIsElimiationModalOpen] = useState(false);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [userToEditCurrentIndex, setUserToEditCurrentIndex] = useState(null);
  const [userToDeleteCurrentIndex, setUserToDeleteCurrentIndex] =
    useState(null);
  const [subjectsData, setSubjectsData] = useState([]);
  const [loading, setLoading] = useState(false);
  const { showToast } = useToast();
  const identificationMethods = JSON.parse(
    localStorage.getItem("sessionData")
  )?.identificationMethods;
  const canIdentifyWithVideo =
    identificationMethods.includes("TOCTOC") ||
    identificationMethods.includes("TOCTOCSYNC");

  useEffect(() => {
    sessionData.signatories && setSubjectsData([...sessionData.signatories]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleConfirmationModal = () => {
    setIsConfirmationModalOpen((prev) => !prev);
  };

  const removeSubject = (index) => {
    const newSubjectsData = [...subjectsData];
    newSubjectsData.splice(index, 1);
    setSubjectsData(newSubjectsData);
    handleCloseEliminationModal();
    setUserToDeleteCurrentIndex(null);
  };

  const editSubject = (index) => {
    setUserToEditCurrentIndex(index);
    handleOpenSignerModal();
  };

  const handleOpenSignerModal = () => {
    setIsSignerModalOpen(true);
  };

  const handleCloseSignerModal = () => {
    setIsSignerModalOpen(false);
    setUserToEditCurrentIndex(null);
  };

  const handleOpenConfirmationModal = (index) => {
    setUserToDeleteCurrentIndex(index);
    setIsElimiationModalOpen(true);
  };

  const handleCloseEliminationModal = () => {
    setIsElimiationModalOpen(false);
    setUserToDeleteCurrentIndex(null);
  };

  const handleStartIdentifySession = () => {
    setLoading(true);
    handleConfirmationModal();
    localStorage.setItem("sessionType", "OnlyIdentify");

    const showErrorToast = (errorMessage) => {
      showToast(errorMessage || "Qualcosa è andato storto", {
        type: "error",
        duration: 5000,
      });
    };

    getApiToken()
      .then((response) => {
        if (response.status !== 200) {
          showErrorToast(response.data.Message);
        }

        return startSession(
          { ...sessionData, signatureType: "OnlyIdentify" },
          response.data
        );
      })
      .then((sessionResponse) => {
        if (sessionResponse.status === 200) {
          showToast("Sessione di identificazione avviata con sucesso", {
            type: "success",
            duration: 5000,
          });
          handleStorageStep(formSteps[6]);
          navigate("/create-session/summary");
        } else {
          showErrorToast(sessionResponse?.data?.Message);
        }
      })
      .catch((err) => showErrorToast(err?.data?.Message))
      .finally(() => {
        setLoading(false);
      });
  };

  const handleSubmit = () => {
    setSessionData((prev) => ({
      ...prev,
      signatories: [...subjectsData],
    }));

    handleStorageData({
      signatories: [...subjectsData],
    });
    handleStorageStep(formSteps[3]);

    if (
      supportedSignatures.length === 1 &&
      supportedSignatures[0] === "OnlyIdentify"
    ) {
      handleConfirmationModal();
    } else {
      navigate("/create-session/documents-condition");
    }
  };

  return (
    <Wrapper>
      {loading ? (
        <LoadingContainer>
          <Loading large />
        </LoadingContainer>
      ) : (
        <AddUserSection>
          <PageTitle>Inserisci i soggetti </PageTitle>
          <PageDescription>
            Inserisci i dati dei soggetti da identificare o a cui inviare i
            documenti da firmare
          </PageDescription>
          {subjectsData?.map((data, index) => {
            return (
              <UserCard key={index}>
                <UserCardRow>
                  <UserProfileContainer>
                    <UserProfileImage>
                      {combineFirstLetters(data?.name, data?.surname)}
                    </UserProfileImage>
                    {`${data?.name} ${data?.surname}`}
                  </UserProfileContainer>
                  <IconsContainer>
                    <FontAwesomeIcon
                      icon={faEdit}
                      className="icon-edit"
                      onClick={() => editSubject(index)}
                    />
                    <FontAwesomeIcon
                      icon={faTrashCan}
                      className="icon-delete"
                      onClick={() => handleOpenConfirmationModal(index)}
                    />
                  </IconsContainer>
                </UserCardRow>
                <UserData>{data?.fiscalCode}</UserData>
                <UserData>{data?.mobileNumber}</UserData>
                <UserData>{data?.email}</UserData>
              </UserCard>
            );
          })}
          <AddUserContainer onClick={handleOpenSignerModal}>
            <AddUserText>+ Aggiungi firmatario</AddUserText>
          </AddUserContainer>
        </AddUserSection>
      )}

      <ButtonContainer>
        <Button ghost margin8 onClick={handleToPreviousStep}>
          Indietro
        </Button>
        <Button
          primaryLarge
          disabled={subjectsData.length === 0 || loading}
          onClick={handleSubmit}
        >
          Avanti
        </Button>
      </ButtonContainer>
      <Modal isOpen={isSignerModalOpen} onClose={handleCloseSignerModal}>
        <SubjectModal
          onClose={handleCloseSignerModal}
          setSubjectsData={setSubjectsData}
          subjectsData={subjectsData}
          userToEditCurrentIndex={userToEditCurrentIndex}
          setUserToEditCurrentIndex={setUserToEditCurrentIndex}
          canIdentifyWithVideo={canIdentifyWithVideo}
        />
      </Modal>
      <Modal isOpen={isElimiationModalOpen}>
        <EliminationModal
          title="Elimina soggetto"
          description={`Confermi di voler eliminare ${subjectsData[userToDeleteCurrentIndex]?.name} ${subjectsData[userToDeleteCurrentIndex]?.surname} dalla lista dei soggetti?`}
          onClose={handleCloseEliminationModal}
          onConfirm={() => removeSubject(userToDeleteCurrentIndex)}
        />
      </Modal>
      <Modal isOpen={isConfirmationModalOpen} onClose={handleConfirmationModal}>
        <ConfirmationModal
          title="Invia richiesta di identificazione"
          description="Vuoi inviare le richieste di identificazione ai soggetti indicati?"
          onClose={handleConfirmationModal}
          onConfirm={handleStartIdentifySession}
        />
      </Modal>
    </Wrapper>
  );
}

export default Subjects;
