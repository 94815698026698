import React from "react";
import Select from "../../../../components/select";
import Input from "../../../../components/input";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";

export const SpidForm = (props) => {
  const [showPassword, setShowPassword] = useState("");
  React.useEffect(() => {
    setShowPassword(false);
  }, []);

  return (
    <div>
      {props.metodoSpid.length === 0 ? null : (
        <>
          <div className="drawer-title border-none margin-top">
            Impostazioni Spid
          </div>
          <div className="input-container">
            <label className="form-label" htmlFor="tenant">
              Url
            </label>
            <Input
              id="spidUrl"
              name="spidUrl"
              type="text"
              placeholder="SPID Url"
              value={props.spidUrl}
              onChange={(e) => props.setSpidUrl(e.target.value)}
            />
            {props.getError("SpidUrl") && (
              <div className="error-message">
                {props.getError("SpidUrl").message}
              </div>
            )}
          </div>
          <div className="input-container">
            <label className="form-label" htmlFor="tenant">
              Username
            </label>
            <Input
              id="spidUrsername"
              name="spidUsername"
              type="text"
              placeholder="Username"
              value={props.spidUsername}
              onChange={(e) => props.setSpidUsername(e.target.value)}
            />
            {props.getError("SpidUsername") && (
              <div className="error-message">
                {props.getError("SpidUsername").message}
              </div>
            )}
          </div>
          <div className="input-container">
            <label className="form-label" htmlFor="tenant">
              Password
            </label>
            <FontAwesomeIcon
              onClick={() => setShowPassword(!showPassword)}
              className={`eye-icon ${showPassword && "active"}`}
              icon={faEye}
            />
            <Input
              id="spidPassword"
              name="spidPassword"
              type={showPassword ? "text" : "password"}
              placeholder="Password"
              value={props.spidPassword}
              onChange={(e) => props.setSpidPassword(e.target.value)}
            />
            {props.getError("SpidPassword") && (
              <div className="error-message">
                {props.getError("SpidPassword").message}
              </div>
            )}
          </div>
          <div className="input-container">
            <label>Set di attributi richiesti abilitati </label>
            <Select
              name="attributi"
              onChange={(e) => props.setAttributi(e.target.value)}
              defaultValue={props.attributi}
            >
              <option name="Max" value="Max">
                Massimo
              </option>
              <option name="Min" value="Min">
                Minimo
              </option>
            </Select>
          </div>
        </>
      )}
    </div>
  );
};
