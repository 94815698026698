import React from "react";
import Input from "../../../../components/input";

export const FirmeForm = (props) => {
  return (
    <div>
      <div className="drawer-title border-none margin-top">
        Firme Supportate
      </div>
      <div className="checkbox-container">
        <Input
          className="checkbox"
          type="checkbox"
          name="OnlyIdentify"
          value={"OnlyIdentify"}
          checked={props.onlyIdentify}
          onChange={(e) =>
            props.onlyIdentify.length === 0
              ? props.setOnlyIdentify(e.target.value)
              : props.setOnlyIdentify("")
          }
        />
        <div>Solo processo di identificazione</div>
      </div>
      <div className="checkbox-container">
        <Input
          disabled
          className="checkbox"
          type="checkbox"
          name="Simple"
          value={"Simple"}
          checked={props.simple}
          onChange={(e) =>
            props.simple.length === 0
              ? props.setSimple(e.target.value)
              : props.setSimple("")
          }
        />
        <div>Semplice (FES)</div>
      </div>
      <div className="checkbox-container">
        <Input
          className="checkbox"
          type="checkbox"
          name="Advanced"
          value={"Advanced"}
          checked={props.advanced}
          onChange={(e) =>
            props.advanced.length === 0
              ? props.setAdvanced(e.target.value)
              : props.setAdvanced("")
          }
        />
        <div>Avanzata (FEA)</div>
      </div>
      <div className="checkbox-container">
        <Input
          disabled
          className="checkbox"
          type="checkbox"
          name="Qualified"
          value={"Qualified"}
          checked={props.qualified}
          onChange={(e) =>
            props.qualified.length === 0
              ? props.setQualified(e.target.value)
              : props.setQualified("")
          }
        />
        <div>Qualificata (FEQ)</div>
      </div>
      {props.getError("SupportedSignatures") && (
        <div className="error-message margin-top-2">
          {props.getError("SupportedSignatures").message}
        </div>
      )}
    </div>
  );
};
