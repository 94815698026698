import styled from "styled-components";

export const Wrapper = styled.div`
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
  padding-top: 48px;
  background-color: white;

  .icon {
    background-color: #09822a;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    color: white;
    padding: 8px;
  }

  .error-icon {
    background-color: rgb(216, 40, 41);
    width: 40px;
    height: 40px;
    border-radius: 50%;
    color: white;
    padding: 8px;
  }

  .text {
    color: black;
    width: 600px;
    text-align: center;
    line-height: 32px;
    font-weight: 500;
    margin: 16px 0 32px 0;
  }
`;
