import React from "react";
import Input from "../../../../components/input";
import { dateFormatter } from "../../../../utils/functions";
import Toggler from "../../../../components/toggler";

export const TenantForm = (props) => {
  return (
    <div>
      <div className="input-container">
        <label className="form-label" htmlFor="tenant">
          ID Tenant
        </label>
        <Input
          id="teamsystemTenantId"
          name="teamsystemTenantId"
          type="text"
          placeholder="Id Tenant"
          value={props.idTenant}
          onChange={(e) => props.setIdTenant(e.target.value)}
        />
        {props.getError("TeamsystemTenantId") && (
          <div className="error-message">
            {props.getError("TeamsystemTenantId").message}
          </div>
        )}
      </div>    
      <div className="input-container">
        <label className="form-label" htmlFor="tenant">
          Ragione Sociale
        </label>
        <Input
          id="businessName"
          name="businessName"
          type="text"
          placeholder="Ragione Sociale"
          value={props.ragioneSociale}
          onChange={(e) => props.setRagioneSociale(e.target.value)}
        />
        {props.getError("BusinessName") && (
          <div className="error-message">
            {props.getError("BusinessName").message}
          </div>
        )}
      </div>
      <div className="input-container">
        <label className="form-label" htmlFor="tenant">
          Partita Iva
        </label>
        <Input
          id="vatNumber"
          name="vatNumber"
          type="text"
          placeholder="Partita Iva"
          value={props.partitaIva}
          onChange={(e) => props.setPartitaIva(e.target.value)}
        />
        {props.getError("VatNumber") && (
          <div className="error-message">
            {props.getError("VatNumber").message}
          </div>
        )}
      </div>
      <div className="input-container">
        <label className="form-label" htmlFor="tenant">
          Data Attivazione
        </label>
        <Input
          disabled={true}
          id="activationDate"
          name="activationDate"
          type="text"
          value={dateFormatter(props.dataAttivazione)}
        />
      </div>
      <div className="toggler-container">
        <Toggler
          hasMargin
          checked={props.isActive}
          setIsChecked={props.setIsActive}
        />
        <div>Attivo</div>
      </div>
    </div>
  );
};
