import React, { useEffect } from "react";
import {
  ColumnContainer,
  Description,
  Image,
  ImageContainer,
  InfoContainer,
  LogoContainer,
  LogoImage,
  Title,
  TitleContainer,
  Wrapper,
} from "./style";
import SummaryImage from "../../../../assets/images/summary-image.png";
import Logo from "../../../../assets/images/tsLogoColor.png";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";

function Summary(props) {
  const { title, description } = props;
  const navigate = useNavigate();

  useEffect(() => {
    !title && !description && navigate("/");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Wrapper>
      <ImageContainer>
        <Image src={SummaryImage} />
      </ImageContainer>
      <InfoContainer>
        <LogoContainer>
          <LogoImage src={Logo} />
        </LogoContainer>
        <ColumnContainer>
          <TitleContainer>
            <FontAwesomeIcon icon={faCheckCircle} className="icon" />
            <Title>{title}</Title>
          </TitleContainer>
          <Description>{description}</Description>
        </ColumnContainer>
      </InfoContainer>
    </Wrapper>
  );
}

export default Summary;
