import styled from "styled-components";

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const TextRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 16px 0;

  .icon {
    color: #0090d1;
    width: 14px;
    height: 14px;
    margin-right: 6px;
    margin-bottom: 2px;
  }
`;

export const List = styled.li`
  font-size: 14px;
  margin-top: 16px;
  margin-left: 8px;
  list-style-position: outside;
  text-indent: -20px;
  padding-left: 16px;
`;

export const Title = styled.h5`
  font-weight: 500;
  line-height: 16px;
`;

export const Text = styled.h6`
  color: black;
  font-size: 14px;
`;
