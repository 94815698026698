import styled from "styled-components";

export const InputContainer = styled.div`
  width: calc(100% - 2px);
  height: 80px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border: 2px dashed #0090d1;
  user-select: none;
`;

export const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export const Text = styled.div`
  color: #0090d1;
  margin-right: 24px;
  margin-top: 2px;
`;
