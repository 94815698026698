import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faMinusCircle,
  faTriangleExclamation,
  faCircleInfo,
} from "@fortawesome/free-solid-svg-icons";
import { AlertContainer, AlertIcon, AlertMessage } from "./style";

const getIconForType = (type) => {
  switch (type) {
    case "success":
      return faCheckCircle;
    case "error":
      return faMinusCircle;
    case "warning":
      return faTriangleExclamation;
    case "info":
      return faCircleInfo;
    default:
      return faCheckCircle;
  }
};

const ToastAlert = ({ id, type, message }) => {
  return (
    <AlertContainer severity={type} key={id}>
      <AlertIcon severity={type}>
        <FontAwesomeIcon
          className="color"
          icon={getIconForType(type)}
          size="lg"
        />
      </AlertIcon>
      <AlertMessage>{message}</AlertMessage>
    </AlertContainer>
  );
};
export default ToastAlert;
