import styled, { css } from "styled-components";

export const AlertContainer = styled.div`
  width: 460px;
  display: flex;
  height: 64px;
  align-items: center;
  padding: 16px;
  margin: 8px 0;
  border: 1px solid transparent;
  border-bottom: 3px solid transparent;
  background-color: white;
  color: black;

  ${(props) =>
    props.severity === "error" &&
    css`
      border-color: #f44336;
    `}

  ${(props) =>
    props.severity === "warning" &&
    css`
      border-color: #ff9800;
    `}

  ${(props) =>
    props.severity === "info" &&
    css`
      border-color: #2196f3;
    `}

  ${(props) =>
    props.severity === "success" &&
    css`
      border-color: #4caf50;
    `}
`;

export const AlertIcon = styled.span`
  margin-right: 12px;
  display: flex;

  ${(props) =>
    props.severity === "error" &&
    css`
      color: #f44336;
    `}

  ${(props) =>
    props.severity === "warning" &&
    css`
      color: #ff9800;
    `}

  ${(props) =>
    props.severity === "info" &&
    css`
      color: #2196f3;
    `}

  ${(props) =>
    props.severity === "success" &&
    css`
      color: #4caf50;
    `}
`;

export const AlertMessage = styled.span`
  flex: 1;
  font-size: 1rem;
  font-weight: 600;
`;

