import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  min-height: calc(100vh - 288px);
`;

export const PageTitle = styled.h4`
  color: black;
  width: 100%;
  font-weight: 600;
  text-align: start;
`;

export const PageDescription = styled.h6`
  color: black;
  font-size: 14px;
  margin-bottom: 40px;
`;

export const ConditionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

export const ConditionTitle = styled.h4`
  width: 100%;
  text-align: center;
`;

export const ConditionDescription = styled.h6`
  width: 400px;
  text-align: center;
`;

export const LoadingContainer = styled.div`
  margin-top: 24px;
`;

export const ConditionButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 16px;
`;

export const ButtonContainer = styled.div`
  border-top: 2px solid #c9d9e8;
  padding: 0 16px;
  width: 100%;
  height: 72px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: end;
  margin-top: 32px;
`;
