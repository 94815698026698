import React, { useState, useRef } from "react";
import { DocumentContainer, Pdf, AcrofieldHighlight, FiledRow } from "./style";
import "../../../../index.css";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Document, Page, pdfjs } from "react-pdf";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

function PdfDocument({
  document,
  acrofieldsList,
  activeAcrofieldIndex,
  currentPage,
  getSignatoryByFiscalCode,
}) {
  const [, setNumPages] = useState(null);
  const pdfContainerRef = useRef(null);
  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  return (
    <DocumentContainer>
      <Pdf ref={pdfContainerRef} className="hide-scrollbar">
        <Document
          file={`data:application/pdf;base64,${document}`}
          onLoadSuccess={onDocumentLoadSuccess}
        >
          <div style={{ position: "relative" }}>
            <Page
              renderAnnotationLayer={false}
              scale={1}
              size="A4"
              renderTextLayer={false}
              pageNumber={currentPage}
            />
            {acrofieldsList
              .filter((field) => field.pageNumber === currentPage)
              .map((field) => {
                const globalIndex = acrofieldsList.findIndex(
                  (f) => f.fieldName === field.fieldName
                );
                const isFilled = field.signatoryFiscalCode !== null;
                const isActive = globalIndex === activeAcrofieldIndex;
                return (
                  <AcrofieldHighlight
                    key={field.fieldName}
                    x={field.x}
                    y={field.y}
                    h={field.height}
                    w={field.width}
                    isActive={isActive}
                    isFilled={isFilled}
                    signatoryFiscalCode={field.signatoryFiscalCode}
                  >
                    <FiledRow>
                      {getSignatoryByFiscalCode(field.signatoryFiscalCode)}
                      {isFilled && (
                        <FontAwesomeIcon
                          icon={faCheckCircle}
                          className="check-icon"
                        />
                      )}
                    </FiledRow>
                  </AcrofieldHighlight>
                );
              })}
          </div>
        </Document>
      </Pdf>
    </DocumentContainer>
  );
}

export default PdfDocument;
