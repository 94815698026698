import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  min-height: calc(100vh - 96px);
`;

export const ImageContainer = styled.div`
  width: 566px;
  height: 100%;
  background-color: #e4eaf0;
  min-height: calc(100vh - 96px);
`;

export const Image = styled.img``;

export const InfoContainer = styled.div`
  width: calc(100% - 600px);
  height: 100%;
`;

export const LogoContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: end;
  margin-top: 8px;
`;

export const LogoImage = styled.img`
  width: 200px;
  height: 46px;
`;

export const ColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 120px;
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  .icon {
    font-size: 40px;
    color: #09822a;
    margin-right: 16px;
  }
`;

export const Title = styled.h1`
  font-weight: 400;
`;

export const Description = styled.h4`
  color: black;
  margin-top: 60px;
`;
