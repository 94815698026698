import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  min-height: calc(100vh - 288px);
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 870px;
`;

export const PageTitle = styled.h4`
  color: black;
  width: 100%;
  font-weight: 600;
  text-align: start;
`;

export const PageDescription = styled.h6`
  color: black;
  font-size: 14px;
  margin-bottom: 24px;
`;

export const UploadDocumentsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 24px 0 8px 0;
`;

export const UploadDocumentTitle = styled.h6`
  color: black;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: ${(props) => (props.margin ? "8px" : "0")};
`;

export const UploadDocumentDescription = styled.h6`
  color: #68777d;
  font-size: 14px;
`;

export const UploadedDocuments = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 8px 0;
`;

export const ErrorCard = styled.div`
  width: 100%;
  border: 1px solid;
  border-bottom: 3px solid;
  border-color: #d82829;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 60px;
  background-color: white;
  margin-top: 8px;

  .error-icon {
    color: #d82829;
    font-size: 24px;
    margin: 0 16px;
  }
`;

export const DocumentCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 8px;
  height: 82px;
`;

export const DocumentCard = styled.div`
  border: 1px solid;
  width: 432px;
  display: flex;
  flex-direction: column;
  padding: 8px;
  position: relative;
  border-color: ${(props) => (props.error ? "#D82829" : "rgb(201, 217, 232)")};

  .icon-delete {
    position: absolute;
    right: 8px;
    top: 8px;
    cursor: pointer;
    color: rgb(216, 40, 41);

    &:hover {
      filter: brightness(1.1);
    }
  }
`;

export const DocumentCardText = styled.div`
  font-size: 14px;
  font-weight: ${(props) => (props.bold ? "600" : "400")};
`;

export const ToggleContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`;

export const ToggleCard = styled.div`
  width: 100%;
  box-shadow: 0px 2px 8px 0px rgba(21, 41, 53, 0.25);
  display: flex;
  flex-direction: column;
  padding: 16px;
  color: rgb(0, 70, 102);
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  margin-right: ${(props) => (props.margin ? "8px" : "0")};

  &:hover {
    border-color: #008bce;
  }
`;

export const ToggleCardRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`;

export const ToggleAbbreviation = styled.h6`
  font-size: 14px;
  line-height: 16px;
`;

export const ToggleName = styled.h5`
  font-size: 16px;
  line-height: 16px;
  margin-top: 8px;
  font-weight: 500;
`;

export const ToggleDescription = styled.span`
  font-size: 12px;
  font-weight: 400;
  color: black;

  .info-icon {
    color: rgb(0, 139, 206);
    width: 12px;
    height: 12px;
    margin-left: 2px;
  }
`;

export const ButtonContainer = styled.div`
  border-top: 2px solid #c9d9e8;
  padding: 0 16px;
  width: 100%;
  height: 72px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: end;
  margin-top: 32px;
`;

export const SignatureRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  .info-icon {
    color: rgb(0, 139, 206);
    width: 14px;
    height: 14px;
    margin-left: 4px;
    margin-bottom: 1px;
  }
`;

export const SignatureTypeName = styled.h5`
  font-weight: 500;
`;

export const SignatureTypeAbbreviation = styled.h6`
  font-size: 14px;
  font-weight: 400;
`;
