import styled from "styled-components";

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
`;

export const InformationTitle = styled.h5`
  color: #005075;
  font-weight: 500;
  margin-bottom: 8px;
`;

export const InformationText = styled.h6`
  font-size: 14px;
`;
