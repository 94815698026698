export const formSteps = [
  "session",
  "recognition",
  "subjects",
  "documents-condition",
  "documents",
  "documents-assign",
  "summary",
];

export const summaryMessage = {
  OnlyIdentify: {
    title: "Richiesta inviata!",
    description:
      "La richiesta di identificazione è stata inviata ai soggetti indicati",
  },
  IdentifyPlusSign: {
    title: "Documenti inviati!",
    description:
      "I documenti sono stati inviati ai soggetti indicati insieme alle istruzioni per firmarli",
  },
};
