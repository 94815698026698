import React, { useContext, useEffect, useState } from "react";
import { logIn} from "../../api/authenticationAPI";
import { useNavigate } from "react-router-dom";

const UserContext = React.createContext();

export const UserProvider = ({ children }) => {
  const [myUser, setMyUser] = useState(false);

  const [isAuthenticated, setIsAutenticated] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const user = localStorage.getItem("myUser");
    if (!user) {
    } else {
      setMyUser(JSON.parse(user));
    }
  }, []);

  const handleLogin = async (credentials) => {
    await logIn(credentials).then((response) => {
      if (response.status === 200) {
        setMyUser(response.data);
        setIsAutenticated(true);
        localStorage.setItem("myUser", JSON.stringify(response.data));
        navigate("/");
      } else {
        return response;
      }
    });
  };

  const handleLogout = async () => {
    // clean up everything
    setMyUser(null);
    setIsAutenticated(false);
    localStorage.removeItem("myUser");
    navigate("/login", { replace: true });
  };

  return (
    <UserContext.Provider
      value={{
        handleLogin,
        handleLogout,
        myUser,
        isAuthenticated,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
// make sure use
export const useUserContext = () => {
  return useContext(UserContext);
};
