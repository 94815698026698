import styled from "styled-components";

export const Wrapper = styled.div`
  background: linear-gradient(90deg, #0090d1, #00c3ea);
  height: 100vh;
  display: flex;

  .container {
    text-align: center;
    margin: auto;
    box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22);
    width: 28.75rem;
    min-width: 20rem;
    border-radius: 4px;
    padding: 0 2rem 2rem 2rem;
    background-color: white;
  }
  .logo {
    padding: 1.25rem;
    font-size: 1rem;
    font-weight: 700;
  }
  .login-form-button {
    width: 100%;
  }
  .link {
    text-decoration: none;
  }
  .link:hover {
    filter: brightness(1.1);
  }
  .links {
    display: flex;
    flex-direction: column;
    padding-top: 2.5rem;
  }
  .links :first-child {
    margin-bottom: 1rem;
  }

  .container {
    display: flex;
    flex-direction: column;
  }
  a {
    color: rgb(0, 144, 209);
    font-weight: 600;
  }
  form {
  }
  .form-login-input {
    height: 2.5rem;
    width: 100%;
  }
  .form-error {
    border-color: red;
  }
  .login-form-button {
    height: 2.5rem;
  }
  .form-login {
    width: 100%;
  }
  .logo {
    width: 100%;
  }
  .error-message {
    color: #f5222d;
    font-size: 0.8rem;
    margin-bottom: 0.5rem;
    font-weight: 600;
  }
  input {
    margin-bottom: 0.5rem;
  }
`;
