export const handleSessionsSorting = (
  id,
  sorting,
  setSorting,
  SortingDirection
) => {
  //
  if (id === "creationDate") {
    sorting.sortCreationDate === "" &&
      setSorting({
        sortCreationDate: SortingDirection.Descending,
        sortDescription: "",
        sortSessionsState: "",
        sortExpirationDate: "",
        sortTag: "",
      });
    sorting.sortCreationDate === SortingDirection.Descending &&
      setSorting({
        sortCreationDate: SortingDirection.Ascending,
        sortDescription: "",
        sortSessionsState: "",
        sortExpirationDate: "",
        sortTag: "",
      });
    sorting.sortCreationDate === SortingDirection.Ascending &&
      setSorting({
        sortCreationDate: "",
        sortDescription: "",
        sortSessionsState: "",
        sortExpirationDate: "",
        sortTag: "",
      });
  }
  //
  else if (id === "description") {
    sorting.sortDescription === "" &&
      setSorting({
        sortCreationDate: "",
        sortDescription: SortingDirection.Descending,
        sortSessionsState: "",
        sortExpirationDate: "",
        sortTag: "",
      });
    sorting.sortDescription === SortingDirection.Descending &&
      setSorting({
        sortCreationDate: "",
        sortDescription: SortingDirection.Ascending,
        sortSessionsState: "",
        sortExpirationDate: "",
        sortTag: "",
      });
    sorting.sortDescription === SortingDirection.Ascending &&
      setSorting({
        sortCreationDate: "",
        sortDescription: "",
        sortSessionsState: "",
        sortExpirationDate: "",
        sortTag: "",
      });
  }
  //
  else if (id === "sessionsState") {
    sorting.sortSessionsState === "" &&
      setSorting({
        sortCreationDate: "",
        sortDescription: "",
        sortSessionsState: SortingDirection.Descending,
        sortExpirationDate: "",
        sortTag: "",
      });
    sorting.sortSessionsState === SortingDirection.Descending &&
      setSorting({
        sortCreationDate: "",
        sortDescription: "",
        sortSessionsState: SortingDirection.Ascending,
        sortExpirationDate: "",
        sortTag: "",
      });
    sorting.sortSessionsState === SortingDirection.Ascending &&
      setSorting({
        sortCreationDate: "",
        sortDescription: "",
        sortSessionsState: "",
        sortExpirationDate: "",
        sortTag: "",
      });
  }
  //
  else if (id === "tag") {
    sorting.sortTag === "" &&
      setSorting({
        sortCreationDate: "",
        sortDescription: "",
        sortSessionsState: "",
        sortExpirationDate: "",
        sortTag: SortingDirection.Descending,
      });
    sorting.sortTag === SortingDirection.Descending &&
      setSorting({
        sortCreationDate: "",
        sortDescription: "",
        sortSessionsState: "",
        sortExpirationDate: "",
        sortTag: SortingDirection.Ascending,
      });
    sorting.sortTag === SortingDirection.Ascending &&
      setSorting({
        sortCreationDate: "",
        sortDescription: "",
        sortSessionsState: "",
        sortExpirationDate: "",
        sortTag: "",
      });
  }
};
