import React, { useState, useEffect } from "react";
import { useCallback } from "react";
import {
  getLogSession,
  getSignatoriesInfo,
  getSingleSession,
} from "../../../../api/backofficeAPI";
import Loading from "../../../../components/loading";
import { LogTable } from "./LogTable";
import { SingleSessionTab } from "../../../../utils/enums";
import SignatoryDrawerHeader from "./SignatoryDrawerHeader";
import { SignatoryTable } from "./SignatoryTable";
import styled from "styled-components";

const SessionDrawer = (props) => {
  const [session, setSession] = useState({});
  const [signatories, setSignatories] = useState([]);
  const [logSession, setLogSession] = useState([]);

  const [isSessionLoading, setIsSessionLoading] = useState(false);
  const [isSignatoriesLoading, setIsSignatoriesLoading] = useState(false);
  const [isLogSessionLoading, setIsLogSessionLoading] = useState(false);

  const [selectedTab, setSelectedTab] = useState("statoFirmatari");

  const getData = useCallback(() => {
    if (props.id) {
      setIsSessionLoading(true);
      setIsSignatoriesLoading(true);
      setIsLogSessionLoading(true);

      getSingleSession(props.id).then((response) => {
        if (response.status === 200) {
          setIsSessionLoading(false);
          setSession(response.data);
        } else {
          setIsSessionLoading(false);
        }
      });

      getLogSession(props.id).then((response) => {
        if (response.status === 200) {
          setIsLogSessionLoading(false);
          setLogSession(response.data);
        } else {
          setIsLogSessionLoading(false);
        }
      });

      getSignatoriesInfo(props.id).then((response) => {
        if (response.status === 200) {
          setIsSignatoriesLoading(false);
          setSignatories(response.data);
        } else {
          setIsSignatoriesLoading(false);
        }
      });
    }
  }, [props.id]);

  useEffect(() => {
    getData();
  }, [getData]);

  return (
    <Wrapper>
      <div className="drawer-title-container">
        <h4>DETTAGLIO PRATICA</h4>
      </div>
      <div className="drawer-tabs-container">
        <div
          className={`drawer-tab ${
            selectedTab === SingleSessionTab.statoFirmatari &&
            "drawer-tab-selected"
          }`}
          onClick={() => setSelectedTab(SingleSessionTab.statoFirmatari)}
        >
          Stato Firmatari
        </div>
        <div
          className={`drawer-tab ${
            selectedTab === SingleSessionTab.sessione && "drawer-tab-selected"
          }`}
          onClick={() => setSelectedTab(SingleSessionTab.sessione)}
        >
          Stato sessione
        </div>
      </div>
      {/* header */}
      {isSessionLoading ? (
        <div className="loading-container">
          <Loading small />
        </div>
      ) : (
        <SignatoryDrawerHeader session={session} onRefresh={getData} />
      )}

      {selectedTab === SingleSessionTab.statoFirmatari && (
        <>
          <h4 className="backoffice-title">Stato firmatari:</h4>
          {session && signatories && !isSignatoriesLoading ? (
            signatories?.map((signatory, index) => {
              return (
                <SignatoryTable
                  key={index}
                  signatory={signatory}
                  idSession={session?.idSession}
                />
              );
            })
          ) : (
            <div className="loading-container">
              <Loading small />
            </div>
          )}
        </>
      )}

      {selectedTab === SingleSessionTab.sessione && (
        <>
          <h4 className="backoffice-title">Stato sessione:</h4>
          {isLogSessionLoading ? (
            <Loading large />
          ) : (
            <LogTable logSession={logSession} />
          )}
        </>
      )}
    </Wrapper>
  );
};

export default SessionDrawer;

export const Wrapper = styled.div`
  position: relative;
  width: 100%;
  font-size: 1rem;
  font-weight: 200;
  margin-bottom: 10rem;

  .drawer-title-container {
    width: 100%;
    height: 4rem;
    border-bottom: solid 2px #0090d1;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .drawer-tabs-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: start;
    border-bottom: solid 2px #dee1e4;
  }

  .drawer-tab {
    height: 3.75rem;
    width: 50%;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    font-weight: 600;
    color: #5a6872;
    border-bottom: solid 4px transparent;
    cursor: pointer;

    &:hover {
      color: #0090d1;
      background-color: #f2f5f8;
    }
  }

  .drawer-tab-selected {
    color: #0090d1;
    border-bottom: solid 4px #0090d1;
    background-color: #f2f5f8;
  }

  .drawer-head {
    display: flex;
    flex-direction: column;
    margin-top: 1rem;
  }

  .li-row {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .arrow-icon {
    color: #0090d1;
    width: 20px;
    height: 20px;
    cursor: pointer;
    margin-right: 1.5rem;
  }

  .arrow-icon:hover {
    filter: brightness(1.1);
  }

  .icon-container {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #0090d1;
    font-weight: 500;
    cursor: pointer;
  }

  .icon-container:hover {
    filter: brightness(1.1);
  }

  .archive-icon {
    color: #0090d1;
    width: 20px;
    height: 20px;
    margin-right: 6px;
    margin-bottom: 2px;
  }

  .archive-icon:hover {
    filter: brightness(1.1);
  }

  .row-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .description-container {
    margin-left: 1rem;
    margin-right: 1rem;
    margin-top: 0.75rem;
  }

  .li-row {
    display: flex;
    flex-direction: row;
    margin-top: 4px;
  }

  .session-info {
    margin-left: 1rem;
    margin-right: 1rem;
    line-height: 1.5rem;
    &-item {
      color: #005075;
      font-weight: 500;
    }
  }

  .loading-container {
    margin-top: 2rem;
  }

  .table-title {
    text-align: center;
    color: #152935;
  }

  .logsession-table {
    padding-bottom: 5rem;
  }

  .backoffice-title {
    height: 4rem;
    border-bottom: 2px solid #0090d1;
    text-align: center;
    line-height: 5rem;
  }
  .first-row {
    background-color: #f2f5f8;
    border-top: none;
  }

  button {
  }
  .buttons {
    margin-right: 5rem;
  }

  .session-controls {
    display: flex;
    align-items: center;
    margin-left: 1.5rem;
    margin-top: 1.5rem;

    justify-content: space-between;
  }
  .delete-icon {
    margin-left: 0.5rem;
    height: 1.5rem;
    :hover {
      cursor: pointer;
    }

    color: #d82829;
  }
  select {
    width: 10rem;
  }
  .session-controls-select {
    margin-left: 0.5rem;
    flex: auto;
    flex-direction: row;
    display: flex;
    align-items: baseline;
  }
  .refresh-container {
    display: flex;
    flex-direction: row;
    margin-right: 11.4rem;
    color: #005075;
    font-weight: 500;
    cursor: pointer;
  }

  .refresh-icon {
    margin-left: 0.5rem;
    color: #005075;
    height: 1.5rem;
  }

  .loading {
    position: absolute;
    top: 110%;
    left: 50%;
  }

  .MuiTable-root {
    margin-bottom: 5rem;
  }
  .MuiTableBody-root {
    margin-bottom: 5rem;
  }
  .MuiTableCell-root {
    max-width: 1rem;
  }

  .loading-container {
    display: flex;
    flex-direction: column;
  }

  .loading {
    margin-top: 2rem;
    margin-left: -2rem;
  }
`;

