import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinusCircle } from "@fortawesome/free-solid-svg-icons";
import styled from "styled-components";

function InputError({ message }) {
  return (
    <Wrapper>
      {message && <FontAwesomeIcon icon={faMinusCircle} className="icon" />}
      {message}
    </Wrapper>
  );
}

export default InputError;

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 14px;
  font-weight: 400;
  color: rgb(216, 40, 41);
  margin-top: 2px;
  line-height: 18px;

  .icon {
    width: 12px;
    height: 12px;
    color: rgb(216, 40, 41);
    margin-right: 4px;
  }
`;
