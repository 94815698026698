import React, { createContext, useCallback } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./style.css";
import ToastAlert from "../../components/toastAlert";

const ToastContext = createContext();

export const useToast = () => {
  const context = React.useContext(ToastContext);
  if (!context) {
    throw new Error("useToast must be used within a ToastProvider");
  }
  return context;
};

export const ToastProvider = ({ children }) => {
  const showToast = useCallback(
    (message, { id = Math.random(), type, duration } = {}) => {
      toast(
        <ToastAlert
          key={id}
          id={id}
          type={type}
          message={message}
        ></ToastAlert>,
        { autoClose: duration }
      );
    },
    []
  );

  const hideToast = useCallback((toastId) => {
    toast.dismiss(toastId);
  }, []);

  return (
    <ToastContext.Provider value={{ showToast, hideToast }}>
      {children}
      <ToastContainer hideProgressBar icon={false} closeButton={false} />
    </ToastContext.Provider>
  );
};
