import React from "react";
import Input from "../../../../components/input";
import Toggler from "../../../../components/toggler";

export const AuthenticationMethodForm = (props) => {
  return (
    <div>
      <div className="drawer-title border-none margin-top">
        Metodi di Identificazione abilitati
      </div>
      <div className="checkbox-container">
        <Input
          className="checkbox"
          type="checkbox"
          name="metodoSpid"
          checked={props.metodoSpid}
          onChange={() =>
            props.metodoSpid.length === 0
              ? props.setMetodoSpid("SPID")
              : props.setMetodoSpid("")
          }
        />
        <div>Spid</div>
      </div>
      <div className="checkbox-container">
        <Input
          className="checkbox"
          type="checkbox"
          name="metodoCie"
          checked={props.metodoCie}
          onChange={() =>
            props.metodoCie.length === 0
              ? props.setMetodoCie("CIE")
              : props.setMetodoCie("")
          }
        />
        <div>CIE</div>
      </div>
      <div className="checkbox-container">
        <Input
          className="checkbox"
          type="checkbox"
          name="metodoVideo"
          value={"INVYDIO"}
          checked={props.metodoVideo === "INVYDIO"}
          onChange={props.invydeoHandler}
        />
        <div>Video Invydio</div>
      </div>
      <div className="checkbox-container">
        <Input
          className="checkbox"
          type="checkbox"
          name="metodoVideo"
          value={"TOCTOC"}
          checked={props.metodoVideo === "TOCTOC"}
          onChange={(e) =>
            props.metodoVideo === e.target.value
              ? props.setMetodoVideo("")
              : props.setMetodoVideo(e.target.value)
          }
        />
        <div>Toc Toc</div>
      </div>
      <div className="checkbox-container">
        <Input
          className="checkbox"
          type="checkbox"
          name="toctocSync"
          value={"TOCTOCSYN"}
          checked={props.toctocSync}
          onChange={props.toctocSyncHandler}
        />
        <div>Toc Toc Sincrono</div>
      </div>
      <div className="checkbox-container">
        <Input
          disabled
          className="checkbox"
          type="checkbox"
          name="certificato"
          value={"CERTIFICATE"}
          checked={props.certificato}
          onChange={() =>
            props.certificato.length === 0
              ? props.setCertificato("CERTIFICATE")
              : props.setCertificato("")
          }
        />
        <div>Certificato</div>
      </div>
      {props.getError("SupportedRecognitionModes") && (
        <div className="error-message margin-top-2">
          {props.getError("SupportedRecognitionModes").message}
        </div>
      )}
      {props.showTocTocSettings() && (
        <>
          <div className="drawer-title border-none margin-top">
            Impostazioni Toc Toc
          </div>

          <div className="toggler-container">
            <Toggler
              hasMargin
              checked={props.backofficeAutoGestito}
              setIsChecked={props.setBackofficeAutogestito}
            />
            <div>Usa impostazioni Personalizzate</div>
          </div>
          <>
            <div className="input-container">
              <label className="form-label" htmlFor="tenant">
                Codice Account
              </label>
              <Input
                id="accountId"
                name="accountId"
                type="text"
                placeholder="Codice account"
                value={props.accountId}
                onChange={(e) => props.setAccountId(e.target.value)}
              />
              {props.getError("AccountId") && (
                <div className="error-message">
                  {props.getError("AccountId").message}
                </div>
              )}
            </div>
            <div className="input-container">
              <label className="form-label" htmlFor="tenant">
                API KEY
              </label>
              <Input
                id="accountKey"
                name="accountKey"
                type="text"
                placeholder="Api key"
                value={props.accountKey}
                onChange={(e) => props.setAccountKey(e.target.value)}
              />
              {props.getError("AccountKey") && (
                <div className="error-message">
                  {props.getError("AccountKey").message}
                </div>
              )}
            </div>
            <div className="input-form-contianer">
              <div className="input-row margin-top-1">
                <label className="form-label label-size-small">Purpose</label>
                <label className="form-label label-size-medium">
                  Codice Workflow
                </label>
              </div>
              <div className="input-row">
                <div className="small-input-container">
                  <Input
                    value={props.fea}
                    onChange={(e) => props.setFea(e.target.value)}
                    style={{ width: "100%" }}
                    disabled
                    type={"text"}
                  />
                </div>
                <div className="medium-input-container">
                  <Input
                    value={props.feaWorkflowId}
                    onChange={(e) => props.setFeaWorkflowId(e.target.value)}
                    style={{ width: "100%" }}
                    type={"text"}
                    placeholder="Codice workflow"
                  />
                  {props.getError(
                    "TocTocData.WorkflowCombinations[0].WorkflowId"
                  ) && (
                    <div className="error-message">
                      {
                        props.getError(
                          "TocTocData.WorkflowCombinations[0].WorkflowId"
                        ).message
                      }
                    </div>
                  )}
                </div>
              </div>
              <div className="input-row margin-top-1">
                <div className="small-input-container">
                  <Input
                    value={props.feq}
                    onChange={(e) => props.setFeq(e.target.value)}
                    style={{ width: "100%" }}
                    disabled
                    type={"text"}
                  />
                </div>
                <div className="medium-input-container">
                  <Input
                    value={props.feqWorkflowId}
                    onChange={(e) => props.setFeqWorkflowId(e.target.value)}
                    style={{ width: "100%" }}
                    type={"text"}
                    placeholder="Codice workflow"
                  />
                  {props.getError(
                    "TocTocData.WorkflowCombinations[1].WorkflowId"
                  ) && (
                    <div className="error-message">
                      {
                        props.getError(
                          "TocTocData.WorkflowCombinations[1].WorkflowId"
                        ).message
                      }
                    </div>
                  )}
                </div>
              </div>
              <div className="input-row margin-top-1">
                <div className="small-input-container">
                  <Input
                    value={props.sigillo}
                    onChange={(e) => props.setSigillo(e.target.value)}
                    style={{ width: "100%" }}
                    disabled
                    type={"text"}
                  />
                </div>
                <div className="medium-input-container">
                  <Input
                    value={props.sigilloWorkflowId}
                    onChange={(e) => props.setSigilloWorkflowId(e.target.value)}
                    style={{ width: "100%" }}
                    type={"text"}
                    placeholder="Codice workflow"
                  />
                  {props.getError(
                    "TocTocData.WorkflowCombinations[2].WorkflowId"
                  ) && (
                    <div className="error-message">
                      {
                        props.getError(
                          "TocTocData.WorkflowCombinations[2].WorkflowId"
                        ).message
                      }
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="input-container">
              <label className="form-label" htmlFor="tenant">
                Frontend Url
              </label>
              <Input
                id="feRedirectUrl"
                name="feRedirectUrl"
                type="text"
                placeholder="Frontend url"
                value={props.feRedirectUrl}
                onChange={(e) => props.setFeRedirectUrl(e.target.value)}
              />
              {props.getError("FeRedirectUrl") && (
                <div className="error-message">
                  {props.getError("FeRedirectUrl").message}
                </div>
              )}
            </div>
          </>
        </>
      )}
    </div>
  );
};
