import styled from "styled-components";

export const Wrapper = styled.div`
  background: linear-gradient(90deg, #0090d1, #00c3ea);
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Card = styled.div`
  text-align: center;
  margin: auto;
  box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22);
  width: 28.75rem;
  min-width: 20rem;
  border-radius: 4px;
  padding: 0 2rem 2rem 2rem;
  background-color: white;

  .logo {
    width: 100%;
  }
  .text {
    margin-bottom: 0.75rem;
    font-family: "Roboto";
  }
  .email-input {
    margin-bottom: 0.5rem;
    font-family: "Roboto";
  }
  .email-inpute-error {
    border-color: red;
    font-family: "Roboto";
  }
  .login-form-button {
    margin-top: 1rem;
    height: 40px;
  }
  .error-message {
    color: red;
    font-size: 14px;
    font-family: "Roboto";
  }
  .success-message {
    color: green;
    font-size: 14px;
    font-family: "Roboto";
  }

  .login-link {
    margin-top: 1rem;
    color: rgb(0, 144, 209);
    cursor: pointer;
    font-family: "Roboto";

    &:hover {
      filter: brightness(1.1);
    }
  }

  .margin-top {
    margin-top: 1rem;
  }

  .resend-mail-link {
    margin-top: 1rem;
    color: rgb(0, 144, 209);
    cursor: pointer;
    font-family: "Roboto";

    &:hover {
      filter: brightness(1.1);
    }
  }

  .login-form-button {
    width: 100%;
  }
`;
