export const signatureTypesToMap = {
    Simple: {
      abbreviation: "FES",
      name: "Semplice",
      fullName: "Firma Elettronica Semplice",
      description:
        "La Firma Elettronica Semplice (FES) è la più basilare modalità di sottoscrizione.",
      info: "La Firma Elettronica Semplice (FES) è la più basilare modalità di sottoscriizone. Si utilizza per documenti a basso rischio quali consensi privacy, prerventivi, documenti di approvazione interna, ecc…",
    },
    Advanced: {
      abbreviation: "FEA",
      name: "Avanzata",
      fullName: "Firma Elettronica Avanzata",
      description:
        "La Firma Elettronica Avanzata (FEA) sostituisce la firma del classico documento cartaceo.",
      info: "La Firma Elettronica Avanzata (FEA) sostituisce la firma del classico documento cartaceo. È adatta a contratti bilaterali, dichiarativi fiscali, ordini, ecc…",
    },
    Qualified: {
      abbreviation: "FEQ",
      name: "Qualificata",
      fullName: "Firma Elettronica Qualificata",
      description:
        "La Firma Elettronica Qualificata (FEQ), giuridicamente, è la più tutelante.",
      info: "La Firma Elettronica Qualificata (FEQ), giuridicamente, è la più tutelante. È adatta per la firma di contratti multilaterali, bilanci e altri documenti con massimo valore giuridico.",
    },
  };