import styled from "styled-components";

export const Content = styled.div`
  height: fit-content;
  width: calc(100% - 104px);
  margin: 72px 24px 24px 80px;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const PageHeader = styled.div`
  height: 80px;
  width: 100%;
  border-bottom: 2px solid #0090d1;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 400;
`;

export const ErrorContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: calc(100vh - 288px);
`;

export const LoadingContainer = styled.div`
  display: flex;
  align-items: center;
  background-color: white;
  width: calc(100% - 104px);
  margin: 72px 24px 24px 80px;
  height: 100%;
  min-height: calc(100vh - 96px);
`;
