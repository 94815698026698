import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: fit-content;
  overflow-y: auto;

  .sidebar-header {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 1rem;
  }

  .sidebar-title {
  font-weight: 600;
  font-size: 1rem;
  padding: 1rem 0;
  color: #005075;
  border-bottom: solid 2px #0090d1;
  text-align: center;
  }

  .sidebar-tabs-container{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: start;
    border-bottom: solid 2px #f2f5f8;
  }

  .sidebar-tab{
    height: 3.75rem;
    width: 50%;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    font-weight: 600;
    color: #5a6872;
    border-bottom: solid 4px transparent;
    cursor: pointer;

    &:hover {
      color: #0090d1;
      background-color: #f2f5f8;
    }
  }

  .sidebar-tab-selected {
    color: #0090d1;
      border-bottom: solid 4px #0090d1;
      background-color: #f2f5f8;
  }

  .cursor-not-allowed {
    cursor: not-allowed;
  }

  .error-message {
    color: #f5222d;
    font-size: 0.8rem;
    margin-bottom: 0.5rem;
    font-weight: 600;
    max-width: 20rem;
  }

  .margin-top-2 {
    margin-top: 1.8rem;
  }

  .form {
    height: fit-content;
    margin-bottom: 6rem;
  }

  .sized-box {
    height: 2rem;
  }

  input {
    width: 20rem;
  }
  .border-none {
    border-bottom: none;
  }
  .margin-top {
    margin-top: 2rem;
  }
  .input-container {
    margin-top: 2rem;
  }
  .buttons {
    display: flex;
    justify-content: end;
    margin-top: 4rem;
  }

  .buttons2 {
    display: flex;
    justify-content: space-between;
    margin-top: 4rem;
  }

  .margin-top-1 {
    margin-top: 1rem;
  }

  .buttons-row {
    display: flex;
    flex-direction: row;
  }

  .submit {
    margin-left: 1rem;
  }
  .toggler-container {
    display: flex;
    flex-direction: row;
    height: fit-content;
    align-items: center;
    margin-top: 2rem;
  }

  .checkbox-container {
    display: flex;
    flex-direction: row;
    height: fit-content;
    align-items: center;
    margin-top: 2rem;
  }

  .checkbox {
    width: 1rem;
    margin-right: 1rem;
  }

  .not-allowed {
    cursor: not-allowed;
  }
  .warning-message {
    max-width: 320px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 1rem;
    color: black;
  }
  .warning-icon {
    color: #fdb927;
    margin-right: 0.5rem;
    margin-top: 0.3rem;
  }
  .server-error {
    display: flex;
    width: 100%;
    margin-top: 1rem;
    color: #f5222d;
    font-size: 0.8rem;
  }

  .input-form-contianer {
    display: flex;
    flex-direction: column;
    width: 320px;
  }

  .input-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
  }
  .small-input-container {
    width: 80px;
  }

  .medium-input-container {
    width: 220px;
  }

  .label-size-small {
    width: 80px;
  }

  .label-size-medium {
    width: 220px;
  }
`;
