import React, { useEffect } from "react";
// import logo from "../../../assets/images/tsLogoColor.png";
import {
  faClose,
  faBars,
  faChevronDown,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  NavbarContainer,
  ProfileContainer,
  ProfileImage,
  SidebarActionContainer,
  Title,
  VersionContainer,
} from "./style";
import ProfileDropdown from "../profileDropdown";
import { useState } from "react";
import { useUserContext } from "../../context/userContext";
import { useBackofficeContext } from "../../context/backofficeContext";
import { useNavigate } from "react-router-dom";
import { getFirstLetter } from "../../utils/functions";
import Button from "../button";
import PasswordModal from "../passwordModal";
import { UserRole } from "../../utils/enums";

const Navbar = ({
  hasSidebar = true,
  hasProfile = true,
  showVersion = true,
}) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const { handleLogout, myUser } = useUserContext();
  const [hasDropdown, setHasDropdown] = useState(false);
  const [hasButton, setHasButton] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();
  const { openSidebar, closeSidebar, isSidebarOpen } = useBackofficeContext();
  const AppVersion = process.env.REACT_APP_VERSION;

  const dropdownHandler = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  useEffect(() => {
    if (myUser && myUser.type === UserRole.TeamSystemAdministrator) {
      setHasButton(true);
    }
    if (myUser && myUser.type !== UserRole.TeamSystemAdministrator) {
      setHasDropdown(true);
    }
  }, [myUser, myUser.type]);

  const logoutHandler = () => {
    handleLogout();
  };

  const onChangePassword = () => {
    setIsDropdownOpen(false);
    setIsModalOpen(true);
  };

  const handleGoHome = () => {
    navigate("/");
  };

  const handleSidebar = () => {
    isSidebarOpen ? closeSidebar() : openSidebar();
  };

  return (
    <NavbarContainer>
      <Title>
        <span>
          {hasSidebar && (
            <SidebarActionContainer>
              <FontAwesomeIcon
                style={{ width: "16px" }}
                icon={isSidebarOpen ? faClose : faBars}
                onClick={handleSidebar}
              />
            </SidebarActionContainer>
          )}
          <span
            onClick={
              hasSidebar
                ? handleGoHome
                : () => {
                    window.location.reload();
                  }
            }
          >
            Teamsystem
          </span>{" "}
          {showVersion && <VersionContainer>V: {AppVersion}</VersionContainer>}
        </span>
      </Title>
      {hasProfile && hasDropdown && (
        <ProfileContainer>
          <ProfileImage>
            <div className="image">
              {getFirstLetter(myUser.name)}
              {getFirstLetter(myUser.surname)}
            </div>
          </ProfileImage>
          <FontAwesomeIcon
            className="arrow-down"
            icon={faChevronDown}
            onClick={dropdownHandler}
            style={{
              color: "white",
              height: "16px",
              width: "16px",
              marginLeft: "8px",
              cursor: "pointer",
              transform: isDropdownOpen ? "rotate(180deg)" : null,
            }}
          />
          <ProfileDropdown
            isOpen={isDropdownOpen}
            setIsOpen={setIsDropdownOpen}
            onChangePassword={onChangePassword}
          />
        </ProfileContainer>
      )}
      {hasButton && (
        <div className="nav-right">
          <Button onClick={logoutHandler} primary>
            Esci
          </Button>
        </div>
      )}
      <PasswordModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
      />
    </NavbarContainer>
  );
};

export default Navbar;
