import React from "react";
import { faClock } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { InfoContainer, List, TextRow, Title, Text } from "./style";

function SyncVideoInfoBox() {
  return (
    <InfoContainer>
      <Title>Riconoscimento con video sincrono</Title>
      <TextRow>
        <FontAwesomeIcon className="icon" icon={faClock} />
        <Text>
          Tempo indicativo per il completamento:&nbsp;
          <strong>10 minuti</strong>
        </Text>
      </TextRow>
      <Text>
        Proseguendo con questo metodo, per portare a termine la procedura il
        soggetto dovrà collegarsi da remoto con un operatore e avere:
      </Text>
      <List>
        <strong>Una connessione a internet di buona qualità</strong>
      </List>
      <List>
        <strong>
          Un dispositivo (PC, tablet o smartphone) dotato di webcam e microfono
        </strong>
      </List>
      <List>
        <strong>Un documento d’identità valido</strong>a scelta tra: Carta
        d’identità, Passaporto o Patente di guida
      </List>
      <List>
        <strong>Il codice fiscale.</strong>
        Se non disponibile, l’unico documento d’identità valido è il
        passsasporto
      </List>
    </InfoContainer>
  );
}

export default SyncVideoInfoBox;
