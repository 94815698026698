import React from "react";
import { Tooltip, tooltipClasses } from "@mui/material";
import { styled as muiStyled } from "@mui/material/styles";
import styled from "styled-components";

export const CustomTooltip = muiStyled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} arrow />
))(({ theme, children }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    borderRadius: "2px",
    color: "white",
    backgroundColor: "#005075",
    fontSize: "12px",
    fontWeight: "500",
    fontFamily: "Roboto",
    maxWidth: "320px",
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: "#005075",
  },
  "& .custom-child-element": {
    // styles for the custom child element
    width: "24px",
    height: "24px",
    backgroundColor: "#eee",
  },
}));

export const TooltipWrapper = React.forwardRef(function TooltipWrapper(
  props,
  ref
) {
  return (
    <CustomTooltip {...props} ref={ref} PopperProps={{ style: { zIndex: 2000 } }}>
      {props.children}
    </CustomTooltip>
  );
});

export const TooltipIcon = () => {
  return <IconWrapper>ℹ️</IconWrapper>;
};

const IconWrapper = styled.div`
  width: 0.9rem;
  height: 0.9rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #0090d1;
  cursor: pointer;
  color: white;
  font-size: 0.7rem;
`;
