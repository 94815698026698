import styled from "styled-components";

export const Wrapper = styled.div`
  width: 660px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Title = styled.div`
  color: #004666;
  width: 100%;
  border-bottom: 2px solid #008bce;
  padding: 16px 16px 16px 24px;
  font-size: 20px;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px 24px;
  width: 100%;
`;

export const InputRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin-top: ${(props) => (props.margin ? "16px" : "0")};
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 16px;
`;

export const RadioContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 200px;
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 48%;
`;

export const LargeInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const Label = styled.label`
  font-weight: 500;
  font-size: 0.875rem;
`;

export const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: end;
  height: 64px;
  background-color: #f2f6f8;
  padding-right: 12px;
`;
