import React, { useEffect, useState } from "react";
import { Content, ErrorContainer, LoadingContainer, PageHeader } from "./style";
import { getConfigTenantApp } from "../../../api/backofficeAPI";
import { useNavigate, useParams } from "react-router-dom";
import Breadcrumb from "./breadcrumb";
import Session from "./session";
import Recognition from "./recognition";
import Subjects from "./subjects";
import Documents from "./documents";

import Loading from "../../../components/loading";
import DocumentsCondition from "./documentsCondition";
import DocumentAssign from "./documentAssign";
import Summary from "./summary";
import { formSteps, summaryMessage } from "./constants";

import Navbar from "../../../components/navbar";
import Sidebar from "../../../components/sidebar";
import { useToast } from "../../../context/toastNotificationContext";
import ErrorState from "../../../components/errorState";
import { useUserContext } from "../../../context/userContext";

function CreateSession() {
  const { myUser } = useUserContext();
  const navigate = useNavigate();
  const { showToast } = useToast();
  const { step } = useParams();
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const [supportedSignatures, setSupportedSignatures] = useState([]);
  const [supportedRecognitionModes, setSupportedRecognitionModes] = useState(
    []
  );
  const [canSign, setCanSign] = useState(false);
  const [steps, setSteps] = useState([
    { name: "Sessione", path: "session" },
    { name: "Riconoscimento", path: "recognition" },
    { name: "Soggetti", path: "subjects" },
  ]);
  const [sessionData, setSessionData] = useState({
    ownerEmail: "",
    description: "",
    tag: "",
    expirationDate: "",
    signatureType: null,
    identificationMethods: [],
    signatories: [],
  });
  const savedData = JSON.parse(localStorage.getItem("sessionData"));
  const sessionType = localStorage.getItem("sessionType");
  const showBreadcrumb = step !== formSteps[5] && step !== formSteps[6];
  const showHeader = step !== formSteps[6];
  const stepToComplete = localStorage.getItem("stepToComplete");

  useEffect(() => {
    if (sessionType && stepToComplete === "summary" && step !== "summary") {
      navigate("/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionType, stepToComplete, step]);

  useEffect(() => {
    savedData && setSessionData(savedData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // check if user can be on the current step based on the condition that he has completet the pervious ones
  useEffect(() => {
    const stepToComplete =
      localStorage.getItem("stepToComplete") || formSteps[0];
    formSteps.indexOf(step) > formSteps.indexOf(stepToComplete) &&
      stepToComplete !== step &&
      navigate(`/create-session/${stepToComplete}`);
  }, [navigate, step]);

  // retrive the logged user email and add it to the data to be sent on the form submit call
  useEffect(() => {
    setSessionData((prev) => ({ ...prev, ownerEmail: myUser.account }));
  }, [myUser.account]);

  useEffect(() => {
    getConfigTenantApp()
      .then((response) => {
        if (response.status === 200) {
          setSupportedSignatures(response.data?.supportedSignatures);
          setSupportedRecognitionModes(
            response.data?.supportedRecognitionModes
          );
          const isOnlyIdentify =
            response.data?.supportedSignatures.length === 1 &&
            response.data?.supportedSignatures.includes("OnlyIdentify");

          if (!isOnlyIdentify) {
            setSteps((prevSteps) => {
              const alreadyHasDocuments = prevSteps.find((step) =>
                step.path.includes("documents")
              );
              if (!alreadyHasDocuments) {
                return [
                  ...prevSteps,
                  {
                    name: "Documenti",
                    path: [
                      "documents",
                      "documents-condition",
                      "documents-assign",
                    ],
                  },
                ];
              }
              return prevSteps;
            });
            setCanSign(true);
          }
        } else {
          showToast(response?.data?.Message || "Qualcosa e andato storto", {
            type: "error",
            duration: 5000,
          });
          navigate("/");
        }
      })
      .catch((err) => {
        setErrorMessage(err.response?.message);
      })
      .finally(() => {
        setLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleStorageData = (data) => {
    const existingSessionData =
      JSON.parse(localStorage.getItem("sessionData")) || {};
    const updatedSessionData = { ...existingSessionData, ...data };
    localStorage.setItem("sessionData", JSON.stringify(updatedSessionData));
  };

  const handleStorageStep = (step) => {
    localStorage.setItem("stepToComplete", step);
  };

  const handleToPreviousStep = () => {
    const previousStepIndex = formSteps.indexOf(step);
    navigate(`/create-session/${formSteps[previousStepIndex - 1]}`);
  };

  const commonProps = {
    handleStorageData,
    handleStorageStep,
    handleToPreviousStep,
    sessionData,
    formSteps,
  };

  const showContent = () => {
    switch (step) {
      case formSteps[0]:
        return <Session setSessionData={setSessionData} {...commonProps} />;
      case formSteps[1]:
        return (
          <Recognition
            supportedRecognitionModes={supportedRecognitionModes}
            setSessionData={setSessionData}
            {...commonProps}
          />
        );
      case formSteps[2]:
        return (
          <Subjects
            canSign={canSign}
            setSessionData={setSessionData}
            supportedSignatures={supportedSignatures}
            {...commonProps}
          />
        );
      case formSteps[3]:
        return <DocumentsCondition {...commonProps} />;
      case formSteps[4]:
        return (
          <Documents
            supportedSignatures={supportedSignatures}
            {...commonProps}
          />
        );
      case formSteps[5]:
        return (
          <DocumentAssign
            signatories={sessionData.signatories}
            {...commonProps}
          />
        );
      case formSteps[6]:
        return (
          <Summary
            title={summaryMessage[sessionType]?.title}
            description={summaryMessage[sessionType]?.description}
          />
        );
      default:
        return (
          <ErrorContainer>
            <ErrorState text={"Si è verificato un errore"} />
          </ErrorContainer>
        );
    }
  };

  return (
    <>
      <Navbar />
      <Sidebar />
      {loading && (
        <LoadingContainer>
          <Loading large />
        </LoadingContainer>
      )}
      {!loading && errorMessage && <ErrorState text={errorMessage} />}
      {!loading && !errorMessage && (
        <Content>
          {showHeader && (
            <PageHeader>
              <h4>Nuova Sessione</h4>
            </PageHeader>
          )}
          <>
            {showBreadcrumb && <Breadcrumb steps={steps} currentStep={step} />}
            {showContent()}
          </>
        </Content>
      )}
    </>
  );
}

export default CreateSession;
