import React from "react";
import { dateFormatter } from "../../../../utils/functions";
import {
  faArchive,
  faArrowRotateRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DetailedTooltip } from "../../../../components/detailedTooltip";
import TooltipData from "../backoffice/TooltipData";

function SignatoryDrawerHeader(props) {
  return (
    <div className="drawer-head">
      <div className="row-container">
        <ul className="session-info">
          <li>
            <span className="session-info-item">Id: &nbsp;</span>
            {props.session?.idSession}
          </li>
          <li>
            <span className="session-info-item">Owner sessione: &nbsp;</span>
            {props.session?.owner}
          </li>
          <li className="li-row">
            <span className="session-info-item">Stato: &nbsp;</span>
            {props.session?.status}
            <div className="tooltip-icon">
              <DetailedTooltip
                title="Informazione su i tipi di utenti"
                children={<TooltipData />}
              ></DetailedTooltip>
            </div>
          </li>
          <li>
            <span className="session-info-item">Tipo di firma: &nbsp;</span>
            {props.session?.signatureType}
          </li>
          <li>
            <span className="session-info-item">Tag: &nbsp;</span>
            {props.session?.tag}
          </li>
        </ul>
        <ul className="session-info">
          <li>
            <span className="session-info-item">Numero documenti: &nbsp;</span>
            {props.session?.documentsCount}
          </li>
          <li>
            <span className="session-info-item">Numero firmatari: &nbsp;</span>
            {props.session?.signatoriesCount}
          </li>
          <li>
            <span className="session-info-item">Data Creazione: &nbsp;</span>
            {dateFormatter(props.session?.creationDate)}
          </li>
          <li>
            <span className="session-info-item">
              Data Ultimo Aggiornamento: &nbsp;
            </span>
            {dateFormatter(props.session?.lastAccessDate)}
          </li>
          <li>
            <span className="session-info-item">
              Stato Archiviazione: &nbsp;
            </span>
            {props.session?.archivingStatus}
          </li>
          <li className="li-row">
            <FontAwesomeIcon
              icon={faArrowRotateRight}
              onClick={() => props.onRefresh()}
              className={"arrow-icon"}
            />
            <div className="icon-container">
              <FontAwesomeIcon
                icon={faArchive}
                onClick={() => {}}
                className={"archive-icon"}
              />
              <>Archivia</>
            </div>
          </li>
        </ul>
      </div>
      <div className="description-container">
        <span className="session-info-item">Descrizione: &nbsp;</span>
        {props.session?.description}
      </div>
    </div>
  );
}

export default SignatoryDrawerHeader;
