import React, { useEffect } from "react";
import { Wrapper } from "./style";
import Navbar from "../../components/navbar";
import { faCheck, faClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "../../components/button";
import { useParams } from "react-router-dom";
import { IdentificationStatus } from "../../utils/enums";
import { useState } from "react";
import { getCookie } from "../../utils/functions";
import { startSign } from "../../api/portaleAPI";
import Loading from "../../components/loading";

function Sign() {
  const { key1, key2, key3 } = useParams();
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState(null);
  const [token, setToken] = useState("");
  const hasMessage =
    key3 === IdentificationStatus.IDENTIFIED ||
    key3 === IdentificationStatus.WAITING;

  useEffect(() => {
    const token = getCookie("accesstoken");
    token
      ? setToken(token)
      : setErrorMessage("Lei non e autorizzato a vedere questa pagina");
    setLoading(false);
  }, []);

  const handleStartSign = () => {
    if (token) {
      setLoading(true);
      startSign(token, key1, key2)
        .then((response) => {
          if (response.status === 200) {
            window.open(response.data, "_self");
          } else {
            setErrorMessage("Qualcosa e andato storto");
          }
        })
        .catch(() => {
          setErrorMessage("Qualcosa e andato storto");
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const renderMessage = () => {
    if (key3 === IdentificationStatus.IDENTIFIED) {
      return "Il processo di identificazione è terminato con successo, ora puoi procedere con la firma dei documenti.";
    } else if (key3 === IdentificationStatus.WAITING) {
      return "La tua identificazione è in fase di conferma, una volta conclusa riceverai una mail con l'esito. Nel frattempo puoi procedere con la firma dei documenti.";
    } else {
      return "Qualcosa è andato storto";
    }
  };

  return (
    <Wrapper>
      <Navbar hasSidebar={false} hasProfile={false} showVersion={false} />
      {loading ? (
        <Loading large />
      ) : (
        <>
          {hasMessage && !errorMessage ? (
            <FontAwesomeIcon icon={faCheck} className="icon" />
          ) : (
            <FontAwesomeIcon icon={faClose} className="error-icon" />
          )}
          <h3
            className={`text ${
              key3 === IdentificationStatus.WAITING && "large"
            }`}
          >
            {errorMessage ? errorMessage : renderMessage()}
          </h3>
          {hasMessage && !errorMessage && (
            <Button primaryLarge onClick={handleStartSign}>
              Procedi alla firma
            </Button>
          )}
        </>
      )}
    </Wrapper>
  );
}

export default Sign;
