import React, { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

const BackofficeContext = React.createContext();

export const BackofficeProvider = ({ children }) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const location = useLocation();
  const currentPath = location?.pathname.match(/^\/[^/]*/)[0];

  const clearStorage = () => {
    const keys = [
      "sessionData",
      "stepToComplete",
      "sessionId",
      "sessionType",
      "signatureType",
      "documentIndex",
      "acrofieldsList",
    ];

    keys.forEach((key) => localStorage.removeItem(key));
  };

  useEffect(() => {
    currentPath !== "/create-session" && clearStorage();
  }, [currentPath]);

  const openSidebar = () => {
    setIsSidebarOpen(true);
  };

  const closeSidebar = () => {
    setIsSidebarOpen(false);
  };

  return (
    <BackofficeContext.Provider
      value={{ isSidebarOpen, openSidebar, closeSidebar, currentPath }}
    >
      {children}
    </BackofficeContext.Provider>
  );
};

export const useBackofficeContext = () => {
  return useContext(BackofficeContext);
};
