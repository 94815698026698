import { faEllipsisVertical } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import styled from "styled-components";
import { DetailedTooltip } from "../../../../components/detailedTooltip";
import { dateFormatter } from "../../../../utils/functions";
import TooltipData from "./TooltipData";

function UsersTable(props) {
  return (
    <Table>
      <Thead>
        <Tr className="tr-head">
          <Th className="th-cell-1 color-grey">Nome</Th>
          <Th className="th-cell-2 color-grey">Cognome</Th>
          <Th className="th-cell-3 color-grey">Email</Th>
          <Th className="th-cell-4 color-grey">
            <div>Tipo </div>
            <div className="tooltip-icon">
              <DetailedTooltip
                title="Informazione su i tipi di utenti"
                children={<TooltipData />}
              ></DetailedTooltip>
            </div>
          </Th>
          <Th className="th-cell-5 color-grey">Stato</Th>
          <Th className="th-cell-6 color-grey">Ultimo Accesso</Th>
          <Th className="th-cell-7 color-grey"></Th>
        </Tr>
      </Thead>

      {!props.isLoading && props.listaUtenti.length > 0 && (
        <Tbody>
          {props.listaUtenti?.length > 0 &&
            !props.isLoading &&
            !props.requestError &&
            props.listaUtenti?.map((user, index) => {
              return (
                <Tr className={"tr-body"} key={index}>
                  <Td>{user.name}</Td>
                  <Td>{user.surname}</Td>
                  <Td>{user.account}</Td>
                  <Td>{user.userTypeDescription}</Td>
                  <Td className="row-cell">
                    <div
                      className={` ${
                        user.isActive ? "status-label" : "status-label-large"
                      }`}
                    >
                      {user.isActive ? "Attivo" : "Non Attivo"}
                    </div>
                    <div
                      className={`status-icon ${
                        user?.isActive
                          ? "status-icon-color-green"
                          : "status-icon-color-grey"
                      }`}
                    ></div>
                  </Td>
                  <Td>{dateFormatter(user?.lastAccessDate)}</Td>
                  <Td>
                    <div
                      className="icon-container"
                      onClick={() => {
                        props.setIsNew(false);
                        props.setIsOpen(true);
                        props.setUtente(user);
                      }}
                    >
                      <FontAwesomeIcon
                        className="icon"
                        icon={faEllipsisVertical}
                      />
                    </div>
                  </Td>
                </Tr>
              );
            })}
        </Tbody>
      )}
    </Table>
  );
}

export default UsersTable;

const Table = styled.table`
  width: 100%;
  margin-top: 4rem;
  border-collapse: collapse;

  .tr-head {
    border: 1px solid transparent;
    border-bottom: solid 1px rgb(157, 166, 173);
  }

  .tr-body {
    border: 1px solid transparent;
    border-bottom: solid 1px rgb(157, 166, 173);

    &:hover {
      border: none;
      border: 1px solid #0090d1;
    }
  }
`;
const Thead = styled.thead`
  width: 100%;
`;

const Tbody = styled.tbody``;

const Tr = styled.tr`
  width: 100%;

  .th-cell-1 {
    min-width: 100px;
  }
  .th-cell-2 {
    min-width: 100px;
  }
  .th-cell-3 {
  }
  .th-cell-4 {
    display: flex;
    flex-direction: row;
    min-width: 220px;
    align-items: center;
  }
  .th-cell-5 {
    min-width: 100px;
  }
  .th-cell-6 {
    min-width: 180px;
  }
  .th-cell-7 {
    width: 50px;
  }

  .row-cell {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .color-grey {
    background-color: #f2f5f8;
  }
  .table-search {
    border: none;
    width: 98.5%;
  }

  .table-select {
    border: none;
    cursor: pointer;
  }

  .status-icon {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin-bottom: 2px;
    margin-left: 8px;
  }

  .status-label {
    width: 40px;
  }

  .status-label-large {
    width: 70px;
  }

  .status-icon-color-green {
    background-color: rgb(9, 130, 42);
  }
  .status-icon-color-grey {
    background-color: rgb(221, 225, 228);
  }

  .position-relative {
    position: relative;
  }

  .search-icon {
    position: absolute;
    right: 1rem;
    top: 0.8rem;
    color: #0090d1;
  }

  .icon-container {
    width: 30px;
    height: 30px;
    background-color: transparent;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 5px;
  }
  .icon-container:hover {
    background-color: #f2f5f8;
    cursor: pointer;
  }

  .icon {
    color: #0090d1;
  }

  .checkbox {
    margin-left: 0.5rem;
  }

  .padding-left-025 {
    padding-left: 0.25rem;
  }
`;

const Th = styled.th`
  color: #005075;
  font-weight: 500;
  height: 2.5rem;
  text-align: left;
  padding-right: 0.5rem;
  padding-left: 0.25rem;
`;

const Td = styled.td`
  color: #152935;
  font-size: 0.875rem;
  font-weight: 500;
  padding-left: 0.25rem;
  padding-right: 0.5rem;
  height: 2.5rem;
`;
