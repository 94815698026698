import React, { useState, useEffect, useCallback } from "react";
import {
  getPaginatedSessions,
  deleteSession,
  deleteMultipleSessions,
} from "../../../api/backofficeAPI";
import Button from "../../../components/button";
import EmptyState from "../../../components/errorState";
import EliminationModal from "../../../components/eliminationModal";
import { SortingDirection } from "../../../utils/enums";
import { handleSessionsSorting } from "../../components/backofficeUser/backoffice/SessionsSorting";
import Loading from "../../../components/loading";
import SingleSessionSidebar from "../../components/backofficeUser/backoffice/SingleSessionSidebar";

import ErrorState from "../../../components/errorState";
import SessionsTable from "../../components/backofficeUser/backoffice/SessionsTable";
import SessionDrawer from "../../components/backofficeUser/drawer/SessionDrawer";
import { useNavigate } from "react-router-dom";
import PageControls from "../../../components/pageControls";
import Pagination from "../../../components/pagination";
import { ButtonContainer, Wrapper } from "./style";

const UserBackoffice = () => {
  const [sessionsList, setSessionsList] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [idSession, setIdSession] = useState(true);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const [totalPageNumber, setTotalPageNumber] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);
  const [selectedSessions, setSelectedSessions] = useState([]);
  const [description, setDescription] = useState("");
  const [tag, setTag] = useState("");
  const [sessionState, setSessionState] = useState("");
  const [sorting, setSorting] = useState({
    sortCreationDate: "Desc",
    sortDescription: "",
    sortSessionsState: "",
    sortExpirationDate: "",
    sortTag: "",
  });
  const [requestError, setRequestError] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const navigate = useNavigate();

  //handle sessions sorting
  const handleSorting = (id) => {
    return handleSessionsSorting(id, sorting, setSorting, SortingDirection);
  };

  const decreasePageNumber = () => {
    if (pageNumber > 1) {
      setPageNumber(pageNumber - 1);
    }
  };

  const onRighePerPaginaChange = (e) => {
    setRowsPerPage(e.target.value);
    setPageNumber(1);
  };

  const onSessionsDelete = () => {
    if (selectedSessions.length === 1) {
      return deleteSession(selectedSessions[0]).then((response) => {
        if (response?.status === 200) {
          setIsDeleteModalOpen(false);
          setSelectedSessions([]);
          getData();
        }
      });
    }
    deleteMultipleSessions(selectedSessions).then((response) => {
      if (response?.status === 200) {
        setIsDeleteModalOpen(false);
        setSelectedSessions([]);
        getData();
      }
    });
  };

  const refreshData = () => {
    setPageNumber(1);
    setRowsPerPage(10);
    setDescription("");
    setTag("");
    setSessionState("");
    setSorting({
      sortCreationDate: SortingDirection.Descending,
      sortDescription: "",
      sortSessionsState: "",
      sortExpirationDate: "",
      sortTag: "",
    });
  };

  const getData = useCallback(() => {
    const body = {
      pageNumber: pageNumber,
      pageSize: rowsPerPage,
      filters: [
        ...(description.length > 0
          ? [
              {
                columnName: "description",
                value: description,
                operation: "Contains",
                dateFrom: null,
                dateTo: null,
              },
            ]
          : []),
        ...(tag.length > 0
          ? [
              {
                columnName: "tag",
                value: tag,
                operation: "Contains",
                dateFrom: null,
                dateTo: null,
              },
            ]
          : []),
        ...(sessionState.length > 0
          ? [
              {
                columnName: "status",
                value: sessionState,
                operation: "Equals",
                dateFrom: null,
                dateTo: null,
              },
            ]
          : []),
      ],
      sorting: [
        ...(sorting.sortCreationDate.length > 0
          ? [
              {
                columnName: "creationDate",
                direction: sorting.sortCreationDate,
              },
            ]
          : []),
        ...(sorting.sortTag.length > 0
          ? [
              {
                columnName: "tag",
                direction: sorting.sortTag,
              },
            ]
          : []),
        ...(sorting.sortDescription.length > 0
          ? [
              {
                columnName: "description",
                direction: sorting.sortDescription,
              },
            ]
          : []),
        ...(sorting.sortSessionsState.length > 0
          ? [
              {
                columnName: "status",
                direction: sorting.sortSessionsState,
              },
            ]
          : []),
        ...(sorting.sortExpirationDate.length > 0
          ? [
              {
                columnName: "expirationDate",
                direction: sorting.sortExpirationDate,
              },
            ]
          : []),
      ],
      fieldsToInclude: [],
    };
    setIsLoading(true);
    getPaginatedSessions(body)
      .then((response) => {
        if (response.status === 200) {
          setSessionsList(response.data.data);
          setTotalPageNumber(response.data.totalPages);
          setTotalRecords(response.data.totalRecords);
        } else {
          setRequestError(true);
          setSessionsList([]);
        }
      })
      .then(() => setIsLoading(false));
  }, [
    description,
    tag,
    pageNumber,
    rowsPerPage,
    sessionState,
    sorting.sortCreationDate,
    sorting.sortDescription,
    sorting.sortExpirationDate,
    sorting.sortTag,
    sorting.sortSessionsState,
  ]);

  useEffect(() => {
    (description.length > 0) | (sessionState.length > 0) && setPageNumber(1);
    const delayDebounce = setTimeout(() => {
      getData();
    }, 300);
    return () => clearTimeout(delayDebounce);
  }, [description.length, getData, sessionState.length]);

  const handleClick = (id) => {
    setIsOpen(true);
    setIdSession(id);
  };

  const onSelectAllSessions = () => {
    selectedSessions.length
      ? setSelectedSessions([])
      : setSelectedSessions(sessionsList.map((i) => i.idSession));
  };
  return (
    <Wrapper>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "end",
          paddingRight: "32px",
          marginBottom: "8px",
        }}
      >
        <Button
          primaryLarge
          onClick={() => navigate("/create-session/session")}
        >
          Nuova sessione
        </Button>
      </div>
      <div className="content">
        <PageControls
          getData={getData}
          hasRemoveFilters={true}
          refreshData={refreshData}
          totalPageNumber={totalPageNumber}
          pageNumber={pageNumber}
          setPageNumber={setPageNumber}
          decreasePageNumber={decreasePageNumber}
          rowsPerPage={rowsPerPage}
          onRighePerPaginaChange={onRighePerPaginaChange}
          totalRecords={totalRecords}
        />
        <SessionsTable
          handleSorting={handleSorting}
          sorting={sorting}
          SortingDirection={SortingDirection}
          onSelectAllSessions={onSelectAllSessions}
          description={description}
          setDescription={setDescription}
          setSessionState={setSessionState}
          tag={tag}
          setTag={setTag}
          sessionState={sessionState}
          isLoading={isLoading}
          requestError={requestError}
          sessionsList={sessionsList}
          selectedSessions={selectedSessions}
          setSelectedSessions={setSelectedSessions}
          handleClick={handleClick}
        />

        {isLoading && !requestError && (
          <div className="loading-container">
            <Loading large />
          </div>
        )}
        {!sessionsList.length && requestError && !isLoading && (
          <ErrorState
            text={
              "A causa di un errore non e stato possibile ricevere la lista delle sessioni!"
            }
          />
        )}
        {!sessionsList.length && !isLoading && !requestError && (
          <div className="empty-state-container">
            <EmptyState message="Non ci sono dati da visualizzare" />
          </div>
        )}

        {!requestError && !isLoading && (
          <ButtonContainer>
            <Button
              disabled={selectedSessions.length === 0}
              primaryLight
              onClick={() => setIsDeleteModalOpen(true)}
            >
              Elimina
            </Button>
            <Pagination
              totalPageNumber={totalPageNumber}
              pageNumber={pageNumber}
              setPageNumber={setPageNumber}
            />
          </ButtonContainer>
        )}
        <SingleSessionSidebar
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          children={<SessionDrawer id={idSession} />}
        />
      </div>
      <EliminationModal
        title={"Conferma eliminazione"}
        text={
          "Eliminando una o più sessioni elimini definitivamente tutti i dati ad essa collegati. Questa operazione non è invertibile. Sei sicuro di voler procedere?"
        }
        isModalOpen={isDeleteModalOpen}
        setIsModalOpen={setIsDeleteModalOpen}
        onAnulla={() => setIsDeleteModalOpen(false)}
        onElimina={onSessionsDelete}
      />
    </Wrapper>
  );
};

export default UserBackoffice;

