import React from "react";
import { Link } from "react-router-dom";
import { useBackofficeContext } from "../../context/backofficeContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { adminLinks, tenantLinks, userLinks } from "../../utils/constants";
import { UserRole } from "../../utils/enums";
import { useUserContext } from "../../context/userContext";
import styled from "styled-components";

const Sidebar = () => {
  const { myUser } = useUserContext();
  const { isSidebarOpen, currentPath } = useBackofficeContext();
  let links;
  if (myUser?.type === UserRole.Administrator) {
    links = adminLinks;
  } else if (myUser?.type === UserRole.TenantAdministrator) {
    links = tenantLinks;
  } else if (myUser?.type === UserRole.TenantUser) {
    links = userLinks;
  }

  return (
    <SidebarContainer isOpen={isSidebarOpen}>
      <SidebarHeader />
      <Links>
        {links?.map((el) => {
          return (
            <Link className="link" key={el.id} to={el.url}>
              <IconContainer
                isActive={currentPath === el.url?.match(/^\/[^/]*/)[0]}
              >
                <FontAwesomeIcon className={"icon"} icon={el.icon} />
                {isSidebarOpen && <span>{el.name}</span>}
              </IconContainer>
            </Link>
          );
        })}
      </Links>
    </SidebarContainer>
  );
};

export default Sidebar;

const SidebarContainer = styled.div`
  position: fixed;
  width: ${(props) => (props.isOpen ? "200px" : "56px")};
  height: calc(100% - 48px);
  background: #002333;
  z-index: 1;
  left: 0;
  top: 3rem;
  overflow-x: hidden;
  transition: width 0s ease-in-out;
`;

const SidebarHeader = styled.div``;

const Links = styled.ul`
  .link {
    text-decoration: none;
  }
`;

const IconContainer = styled.li`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 1rem;
  width: 100%;
  height: 48px;
  white-space: nowrap;
  font-family: "Roboto";
  font-style: medium;
  cursor: pointer;
  background: ${(props) =>
    props.isActive ? "linear-gradient(315deg, #00c3ea, #0090d1)" : "none"};

  span {
    color: white;
    font-size: 0.875rem;
  }
  .icon {
    color: white;
    height: 24px;
    width: 24px;
    margin-right: 8px;
  }
`;
