import React, { useCallback, useState } from "react";
import { editCompany } from "../../../../api/backofficeAPI";
import Button from "../../../../components/button";
import { Wrapper } from "../../../styles/tenantForm";
import { useEffect } from "react";
import { TenantForm } from "./TenantForm";
import { SessioniForm } from "./SessionForm";
import { UtentiForm } from "./UserForm";
import { FirmeForm } from "./SigningForm";
import { AuthenticationMethodForm } from "./AuthenticationMethodForm";
import { SpidForm } from "./SpidForm";
import { AdminManagment } from "./AdminManagment";
import { IdSignForm } from "./IdSignForm";
import { ArchiveForm } from "./ArchiveForm";
import { TsIdForm } from "./TsIdForm";

const EditTenantForm = (props) => {
  const [selectedTab, setSelectedTab] = useState("gestioneTenant");
  const [uuidTenant, setUuidTenant] = useState(
    props.tenant?.archiveData.companyUuid
    );
  //const [idTenant, setIdTenant] = useState(props.tenant?.teamsystemTenantId);
  const [idTenant] = useState(props.tenant?.teamsystemTenantId);
  const [ragioneSociale, setRagioneSociale] = useState(
    props.tenant?.businessName
  );
  const [partitaIva, setPartitaIva] = useState(props.tenant?.vatNumber);
  const [dataAttivazione] = useState(props.tenant?.activationDate);
  const [isActive, setIsActive] = useState(props.tenant?.isActive);
  const [maxSessionLength, setMaxSessionLength] = useState(
    props.tenant?.maxSessionLength
  );
  const [sessionTimeout, setSessionTimeout] = useState(
    props.tenant?.sessionTimeout
  );
  const [alertBeforeTimeout, setAlertBeforeTimeout] = useState(
    props.tenant?.alertBeforeTimeout
  );
  const [sessionsPerPage, setSessionsPerPage] = useState(
    props.tenant?.sessionsPerPage
  );
  const [maxNrOfUsersPerTenant, setMaxNrOfUsersPerTenant] = useState(
    props.tenant?.maxNrOfUsersPerTenant
  );
  const [onlyIdentify, setOnlyIdentify] = useState("");
  const [simple, setSimple] = useState("");
  const [advanced, setAdvanced] = useState("");
  const [qualified, setQualified] = useState("");
  const [clientId, setClientId] = useState(
    props.tenant?.idsignClientConfiguration?.clientId
  );
  const [clientSecret, setClientSecret] = useState(
    props.tenant?.idsignClientConfiguration?.clientSecret
  );
  const [institutionCode, setInstitutionCode] = useState(
    props.tenant?.idsignClientConfiguration?.institutionCode
  );

  const [tsClientId, setTsClientId] = useState(
    props.tenant?.teamSystemIdCredentials?.clientId
  );
  const [tSClientSecret, setTsClientSecret] = useState(
    props.tenant?.teamSystemIdCredentials?.clientSecret
  );

  const [attributi, setAttributi] = useState("Min");
  const [spidUrl, setSpidUrl] = useState("");
  const [spidUsername, setSpidUsername] = useState("");
  const [spidPassword, setSpidPassword] = useState("");
  const [portaleFirma, setPortaleFirma] = useState("DigitalSign");
  const [metodoVideo, setMetodoVideo] = useState("");
  const [metodoSpid, setMetodoSpid] = useState("");
  const [metodoCie, setMetodoCie] = useState("");

  const [responseErrors, setResponseErrors] = useState([]);
  const [toctocSync, setToctocSync] = useState("");
  const [certificato, setCertificato] = useState("");
  const [backofficeAutoGestito, setBackofficeAutogestito] = useState(
    props.tenant?.tocTocData.authenticationPortalSelfManage
  );
  const [accountId, setAccountId] = useState(props.tenant.tocTocData.accountId);
  const [accountKey, setAccountKey] = useState(
    props.tenant.tocTocData.accountKey
  );
  const [feRedirectUrl, setFeRedirectUrl] = useState(
    props.tenant.tocTocData.feRedirectUrl || ""
  );
  const [serverError, setServerError] = useState("");
  const [getProps, setGetProps] = useState(false);
  const [fea, setFea] = useState("FEA");
  const [feq, setFeq] = useState("FEQ");
  const [sigillo, setSigillo] = useState("SIGILLO");
  const [feaWorkflowId, setFeaWorkflowId] = useState(
    props.tenant.tocTocData.workflowCombinations[0]?.workflowId || ""
  );
  const [feqWorkflowId, setFeqWorkflowId] = useState(
    props.tenant.tocTocData.workflowCombinations[1]?.workflowId || ""
  );
  const [sigilloWorkflowId, setSigilloWorkflowId] = useState(
    props.tenant.tocTocData.workflowCombinations[2]?.workflowId || ""
  );
  const [archiveFlag, setArchiveFlag] = useState(
    props.tenant?.archiveData?.isActive ? "yes" : "no"
  );
  const [archiveUuid, setArchiveUuid] = useState(
    props.tenant?.archiveData?.companyUuid
  );

  useEffect(() => {
    if (props.tenant.supportedSignatures?.includes("OnlyIdentify")) {
      setOnlyIdentify("OnlyIdentify");
    }
    if (props.tenant.supportedSignatures?.includes("Simple")) {
      setSimple("Simple");
    }
    if (props.tenant.supportedSignatures?.includes("Advanced")) {
      setAdvanced("Advanced");
    }
    if (props.tenant.supportedSignatures?.includes("Qualified")) {
      setQualified("Qualified");
    }
    if (props.tenant.supportedRecognitionModes?.includes("SPID")) {
      setMetodoSpid("SPID");
    }
    if (props.tenant.supportedRecognitionModes?.includes("CIE")) {
      setMetodoCie("CIE");
    }
    if (props.tenant.supportedRecognitionModes?.includes("INVYDIO")) {
      setMetodoVideo("INVYDIO");
    }
    if (props.tenant.supportedRecognitionModes?.includes("TOCTOC")) {
      setMetodoVideo("TOCTOC");
    }
    if (props.tenant.supportedRecognitionModes?.includes("TOCTOCSYNC")) {
      setToctocSync("TOCTOCSYNC");
    }
    if (props.tenant.sessionsPerPage === 10) {
      setSessionsPerPage(10);
    }
    if (props.tenant.sessionsPerPage === 20) {
      setSessionsPerPage(20);
    }
    if (props.tenant.sessionsPerPage === 50) {
      setSessionsPerPage(50);
    }
    if (props.tenant.sessionsPerPage === 100) {
      setSessionsPerPage(100);
    }
    setSpidUrl(props.tenant.spidConfigurations?.url);
    setSpidUsername(props.tenant.spidConfigurations?.username);
    setSpidPassword(props.tenant.spidConfigurations?.password);
    setAttributi(props.tenant.spidConfigurations?.attributeSet);
    if (props.tenant.targetSignaturePortal === "DigitalSign") {
      setPortaleFirma("DigitalSign");
    }
    if (props.tenant.targetSignaturePortal === "IdSign") {
      setPortaleFirma("IdSign");
    }
    setBackofficeAutogestito(
      props.tenant.tocTocData.authenticationPortalSelfManage
    );
    setGetProps(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const body = {
    id: props.tenant.id,
    teamsystemTenantId: parseInt(idTenant),
    businessName: ragioneSociale,
    vatNumber: partitaIva,
    activationDate: dataAttivazione,
    isActive: isActive,
    adminName: props.tenant.adminName,
    adminSurname: props.tenant.adminSurname,
    adminAccountEmail: props.tenant.adminAccountEmail,
    maxSessionLength: parseInt(maxSessionLength),
    sessionTimeout: parseInt(sessionTimeout),
    alertBeforeTimeout: parseInt(alertBeforeTimeout),
    sessionsPerPage: parseInt(sessionsPerPage),
    maxNrOfUsersPerTenant: maxNrOfUsersPerTenant,
    supportedSignatures: [
      ...(onlyIdentify.length > 0 ? [onlyIdentify] : []),
      ...(advanced.length > 0 ? [advanced] : []),
      ...(simple.length > 0 ? [simple] : []),
      ...(qualified.length > 0 ? [qualified] : []),
    ],
    idsignClientConfiguration: {
      clientId: clientId,
      clientSecret: clientSecret,
      institutionCode: institutionCode,
    },
    teamSystemIdCredentials: {
      clientId: tsClientId,
      clientSecret: tSClientSecret,
    },
    supportedRecognitionModes: [
      ...(metodoSpid.length > 0 ? [metodoSpid] : []),
      ...(metodoCie.length > 0 ? [metodoCie] : []),
      ...(metodoVideo.length > 0 ? [metodoVideo] : []),
      ...(toctocSync.length > 0 ? [toctocSync] : []),
      ...(certificato.length > 0 ? [certificato] : []),
    ],
    tocTocData: {
      authenticationPortalSelfManage: backofficeAutoGestito,
      feRedirectUrl: feRedirectUrl,
      accountId: accountId,
      accountKey: accountKey,
      workflowCombinations: [
        { identificationPurpose: fea, workflowId: feaWorkflowId },
        { identificationPurpose: feq, workflowId: feqWorkflowId },
        { identificationPurpose: sigillo, workflowId: sigilloWorkflowId },
      ],
    },
    note: props.tenant.note,
    startMailTemplate: props.tenant.startMailTemplate,
    endMailTemplate: props.tenant.endMailTemplate,
    //spidLevel: attributi,
    spidConfigurations: {
      url: spidUrl,
      attributeSet: attributi,
      username: spidUsername,
      password: spidPassword,
    },
    adminId: props.tenant.adminId,
    targetSignaturePortal: portaleFirma,
    archiveData: {
      companyUuid: archiveFlag === "yes" ? archiveUuid: "",
      isActive: archiveFlag === "yes" ? true : false,
    }
  };

  const onFormSubmit = () => {
    editCompany(body).then((response) => {
      if (response.status === 400) {
        setResponseErrors(response.data);
      } else if (response.status === 200) {
        props.getData();
        props.handleDrawer(false);
      } else {
        setServerError("Errore del Server");
      }
    });
  };

  const getError = (field) => {
    return responseErrors?.find((obj) => {
      return obj.fieldName === field;
    });
  };

  const toctocSyncHandler = () => {
    if (toctocSync === "TOCTOCSYNC") {
      setToctocSync("");
    } else {
      if (metodoVideo === "INVYDIO" && toctocSync === "") {
        setMetodoVideo("");
        setToctocSync("TOCTOCSYNC");
      } else {
        setToctocSync("TOCTOCSYNC");
      }
    }
  };

  const invydeoHandler = () => {
    if (metodoVideo === "INVYDIO") {
      setMetodoVideo("");
    } else {
      if (toctocSync) {
        setToctocSync("");
        setMetodoVideo("INVYDIO");
      } else {
        setMetodoVideo("INVYDIO");
      }
    }
  };

  const showTocTocSettings = useCallback(() => {
    if (toctocSync === "TOCTOCSYNC" || metodoVideo === "TOCTOC") {
      return true;
    } else {
      return false;
    }
  }, [metodoVideo, toctocSync]);

  const showArchiveUuIdBox = () =>{
    return archiveFlag === "yes";
  }

  useEffect(() => {
    if (!backofficeAutoGestito) {
      setAccountId("");
      setAccountKey("");
      setFeRedirectUrl("");
      setFeaWorkflowId("");
      setFeqWorkflowId("");
      setSigilloWorkflowId("");
    }
  }, [backofficeAutoGestito, feaWorkflowId, feqWorkflowId, sigilloWorkflowId]);

  useEffect(() => {
    if (getProps) {
      if (toctocSync !== "TOCTOCSYNC" && metodoVideo !== "TOCTOC") {
        setBackofficeAutogestito(false);
      }
    }
  }, [getProps, metodoVideo, toctocSync]);

  return (
    <Wrapper>
      <div className="sidebar-header">
        <div className="sidebar-title">MODIFICA TENANT</div>
        <div className="sidebar-tabs-container">
          <div
            className={`sidebar-tab ${
              selectedTab === "gestioneTenant" && "sidebar-tab-selected"
            }`}
            onClick={() => setSelectedTab("gestioneTenant")}
          >
            Gestione Tenant
          </div>
          <div
            className={`sidebar-tab ${
              selectedTab === "gestioneAdmin" && "sidebar-tab-selected"
            }`}
            onClick={() => setSelectedTab("gestioneAdmin")}
          >
            Gestione Amministratori
          </div>
        </div>
      </div>
      {selectedTab === "gestioneTenant" ? (
        <div className="form">
          <TenantForm
            getError={getError}
            idTenant={idTenant}
            ragioneSociale={ragioneSociale}
            setRagioneSociale={setRagioneSociale}
            partitaIva={partitaIva}
            setPartitaIva={setPartitaIva}
            dataAttivazione={dataAttivazione}
            isActive={isActive}
            setIsActive={setIsActive}
          />

          <SessioniForm
            getError={getError}
            maxSessionLength={maxSessionLength}
            setMaxSessionLength={setMaxSessionLength}
            sessionTimeout={sessionTimeout}
            setSessionTimeout={setSessionTimeout}
            alertBeforeTimeout={alertBeforeTimeout}
            setAlealertBeforeTimeout={setAlertBeforeTimeout}
            sessionsPerPage={sessionsPerPage}
            setSessionsPerPage={setSessionsPerPage}
          />

          <UtentiForm
            getError={getError}
            maxNrOfUsersPerTenant={maxNrOfUsersPerTenant}
            setMaxNrOfUsersPerTenant={setMaxNrOfUsersPerTenant}
          />

          <FirmeForm
            getError={getError}
            onlyIdentify={onlyIdentify}
            setOnlyIdentify={setOnlyIdentify}
            simple={simple}
            setSimple={setSimple}
            advanced={advanced}
            setAdvanced={setAdvanced}
            qualified={qualified}
            setQualified={setQualified}
          />

          <TsIdForm
            getError={getError}
            tsClientId={tsClientId}
            setTsClientId={setTsClientId}
            tsClientSecret={tSClientSecret}
            setTsClientSecret={setTsClientSecret}
          />

          {advanced.length > 0 && (
            <IdSignForm
              getError={getError}
              clientId={clientId}
              setClientId={setClientId}
              clientSecret={clientSecret}
              setClientSecret={setClientSecret}
              institutionCode={institutionCode}
              setInstitutionCode={setInstitutionCode}
            />
          )}

          <AuthenticationMethodForm
            getError={getError}
            metodoSpid={metodoSpid}
            setMetodoSpid={setMetodoSpid}
            metodoCie={metodoCie}
            setMetodoCie={setMetodoCie}
            metodoVideo={metodoVideo}
            invydeoHandler={invydeoHandler}
            setMetodoVideo={setMetodoVideo}
            toctocSync={toctocSync}
            toctocSyncHandler={toctocSyncHandler}
            certificato={certificato}
            setCertificato={setCertificato}
            backofficeAutoGestito={backofficeAutoGestito}
            setBackofficeAutogestito={setBackofficeAutogestito}
            accountId={accountId}
            setAccountId={setAccountId}
            accountKey={accountKey}
            setAccountKey={setAccountKey}
            feRedirectUrl={feRedirectUrl}
            setFeRedirectUrl={setFeRedirectUrl}
            showTocTocSettings={showTocTocSettings}
            fea={fea}
            feq={feq}
            sigillo={sigillo}
            feaWorkflowId={feaWorkflowId}
            feqWorkflowId={feqWorkflowId}
            sigilloWorkflowId={sigilloWorkflowId}
            setFea={setFea}
            setFeq={setFeq}
            setSigillo={setSigillo}
            setFeaWorkflowId={setFeaWorkflowId}
            setFeqWorkflowId={setFeqWorkflowId}
            setSigilloWorkflowId={setSigilloWorkflowId}
          />

          <SpidForm
            getError={getError}
            metodoSpid={metodoSpid}
            setAttributi={setAttributi}
            attributi={attributi}
            spidUrl={spidUrl}
            setSpidUrl={setSpidUrl}
            spidUsername={spidUsername}
            setSpidUsername={setSpidUsername}
            spidPassword={spidPassword}
            setSpidPassword={setSpidPassword}
          />

        <ArchiveForm
          getError={getError}
          archiveFlag = {archiveFlag}
          setArchiveFlag = {setArchiveFlag}
          archiveUuid={archiveUuid}
          setArchiveUuid={setArchiveUuid}
          showArchiveUuIdBox={showArchiveUuIdBox}
        />

          {/* Buttons */}
          <div className="buttons">
            <Button ghostSmall onClick={() => props.handleDrawer(false)}>
              Annulla
            </Button>
            <Button
              onClick={onFormSubmit}
              type="button"
              primary
              className="submit"
            >
              Conferma
            </Button>
          </div>
          <div className="server-error">{true ? serverError : null}</div>
        </div>
      ) : (
        <AdminManagment
          tenantId={props.tenant?.teamsystemTenantId}
          handleDrawer={() => props.handleDrawer(false)}
        />
      )}
    </Wrapper>
  );
};

export default EditTenantForm;
