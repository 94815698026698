import React, { useState } from "react";
import {
  ConditionButtonContainer,
  ConditionDescription,
  ConditionTitle,
  Wrapper,
  ConditionContainer,
  ButtonContainer,
  PageTitle,
  PageDescription,
  LoadingContainer,
} from "./style";
import Button from "../../../../components/button";
import { useNavigate } from "react-router-dom";
import Modal from "../../../../components/modal";
import EliminationModal from "../eliminationModal";
import ConfirmationModal from "../confirmationModal";
import {
  createProcess,
  deleteProcess,
  getApiToken,
  startSession,
} from "../../../../api/backofficeAPI";
import { useToast } from "../../../../context/toastNotificationContext";
import Loading from "../../../../components/loading";

function DocumentsCondition(props) {
  const { formSteps, handleStorageStep, handleToPreviousStep, sessionData } =
    props;
  const [isEliminationModalOpen, setIsEliminationModalOpen] = useState(false);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const { showToast } = useToast();
  const navigate = useNavigate();
  const sessionId = localStorage.getItem("sessionId");

  const handleEliminationModal = () => {
    sessionId
      ? setIsEliminationModalOpen((prev) => !prev)
      : handleToPreviousStep();
  };

  const handleConfirmationModal = () => {
    setIsConfirmationModalOpen((prev) => !prev);
  };

  const handleGoBack = () => {
    sessionId
      ? deleteProcess(sessionId)
          .then((response) => {
            if (response.status === 200) {
              localStorage.removeItem("sessionId");
              handleToPreviousStep();
            } else {
              showToast(response?.data?.Message || "Qualcosa e andato storto", {
                type: "error",
                duration: 5000,
              });
            }
          })
          .catch((err) => {
            showToast(err?.response?.message || "Qualcosa e andato storto", {
              type: "error",
              duration: 5000,
            });
          })
          .finally(() => {
            handleEliminationModal();
          })
      : handleToPreviousStep();
  };

  const handleSelect = (type) => {
    if (type === "OnlyIdentify") {
      handleConfirmationModal();
    } else {
      setLoading(true);
      createProcess()
        .then((response) => {
          if (response.status === 201) {
            handleStorageStep(formSteps[4]);
            navigate("/create-session/documents");
            localStorage.setItem("sessionId", response.data);
          } else {
            showToast(response?.data?.Message || "Qualcosa e andato storto", {
              type: "error",
              duration: 5000,
            });
          }
        })
        .catch((error) => {
          showToast(error?.response?.Message || "Qualcosa è andato storto", {
            type: "error",
            duration: 5000,
          });
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const handleStartIdentifySession = () => {
    setLoading(true);
    handleConfirmationModal();
    localStorage.setItem("sessionType", "OnlyIdentify");

    const showErrorToast = (errorMessage) => {
      showToast(errorMessage || "Qualcosa è andato storto", {
        type: "error",
        duration: 5000,
      });
    };

    getApiToken()
      .then((response) => {
        if (response.status !== 200) {
          showErrorToast(response.data.Message);
        }

        return startSession(
          { ...sessionData, signatureType: "OnlyIdentify" },
          response.data
        );
      })
      .then((sessionResponse) => {
        if (sessionResponse.status === 200) {
          showToast("Sessione di identificazione avviata con sucesso", {
            type: "success",
            duration: 5000,
          });
          handleStorageStep(formSteps[6]);
          navigate("/create-session/summary");
        } else {
          showErrorToast(sessionResponse?.data?.Message);
        }
      })
      .catch((err) => showErrorToast(err?.data?.Message))
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Wrapper>
      <ConditionContainer>
        {loading ? (
          <LoadingContainer>
            <Loading large />
          </LoadingContainer>
        ) : (
          <>
            <PageTitle>Carica i documenti</PageTitle>
            <PageDescription>
              Qui puoi caricare i documenti da inviare ai soggetti. Se procedi
              senza documenti, la sessione sarà di sola identificazione dei
              soggetti.
            </PageDescription>
            <ConditionTitle>Vuoi aggiungere documenti?</ConditionTitle>
            <ConditionDescription>
              Se scegli di procedere senza documenti, la sessione sarà di sola
              identificazione dei soggetti che hai indicato
            </ConditionDescription>
            <ConditionButtonContainer>
              <Button ghost onClick={() => handleSelect("OnlyIdentify")}>
                No, voglio solo identificare i soggetti
              </Button>
              <Button
                style={{ marginLeft: "16px" }}
                primaryLarge
                onClick={() => handleSelect("IdentifyPlusSign")}
              >
                Sì, voglio far firmare documenti
              </Button>
            </ConditionButtonContainer>
          </>
        )}
      </ConditionContainer>
      <ButtonContainer>
        <Button ghost margin8 onClick={handleEliminationModal}>
          Indietro
        </Button>
        <Button primaryLarge disabled={true}>
          Avanti
        </Button>
      </ButtonContainer>
      <Modal isOpen={isConfirmationModalOpen} onClose={handleConfirmationModal}>
        <ConfirmationModal
          title="Invia richiesta di identificazione"
          description="Vuoi inviare le richieste di identificazione ai soggetti indicati?"
          onClose={handleConfirmationModal}
          onConfirm={handleStartIdentifySession}
        />
      </Modal>
      <Modal isOpen={isEliminationModalOpen} onClose={handleEliminationModal}>
        <EliminationModal
          title="Confermare l'eliminazione"
          description="Cliccando su elimina, tutti i dati rilevanti ai documenti verranno eliminati"
          onClose={handleEliminationModal}
          onConfirm={handleGoBack}
        />
      </Modal>
    </Wrapper>
  );
}

export default DocumentsCondition;
