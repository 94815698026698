import { Drawer } from "@mui/material";
import React from "react";
import NewTenantForm from "./NewTenantForm";
import EditTenantForm from "./EditTenantForm";

function TenantDrawer(props) {
  return (
    <Drawer
      PaperProps={{
        sx: {
          width: "500px",
          margin: "48px 0 0 0 "
        },
      }}
      anchor="right"
      open={props.isOpen}
      onClose={() => {
        props.setIsOpen(false);
      }}
      variant="temporary"
    >
      <div>
        {props.isNew ? (
          <NewTenantForm
            handleDrawer={props.handleDrawer}
            token={props.myUser.token}
            getData={props.getData}
          />
        ) : (
          <EditTenantForm
            tenant={props.tenant}
            handleDrawer={props.handleDrawer}
            token={props.myUser.token}
            getData={props.getData}
          />
        )}
      </div>
    </Drawer>
  );
}

export default TenantDrawer;
