import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  min-height: calc(100vh - 288px);
`;

export const PageTitle = styled.h4`
  color: black;
  width: 100%;
  font-weight: 600;
  text-align: start;
  padding: 0 8px;
`;

export const PageDescription = styled.h6`
  color: black;
  width: 100%;
  font-size: 14px;
  margin-bottom: 24px;
  padding: 0 8px;
`;

export const AddUserSection = styled.div`
  width: 870px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

export const AddUserText = styled.h6``;

export const AddUserContainer = styled.div`
  width: 416px;
  height: 146px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px dashed #008bce;
  cursor: pointer;
  background-color: white;
  margin: 8px;

  &:hover {
    filter: brightness(0.97);
  }
`;

export const UserCard = styled.div`
  width: 416px;
  height: 146px;
  display: flex;
  flex-direction: column;
  padding: 16px;
  margin: 8px;
  box-shadow: 0px 2px 8px 0px rgba(21, 41, 53, 0.25);
`;

export const UserCardRow = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
`;

export const UserProfileContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #152935;
  font-size: 18px;
`;

export const UserProfileImage = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #0e7186;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-weight: 400;
  font-size: 16px;
  margin-right: 16px;
`;

export const IconsContainer = styled.span`
  .icon-delete {
    cursor: pointer;
    color: rgb(216, 40, 41);

    &:hover {
      filter: brightness(1.1);
    }
  }

  .icon-edit {
    cursor: pointer;
    color: #008bce;
    margin-right: 16px;

    &:hover {
      filter: brightness(1.1);
    }
  }
`;

export const UserData = styled.h6`
  font-size: 14px;
  margin-bottom: ${(props) => (props.margin ? "8px" : "0")};
  font-weight: ${(props) => (props.bold ? "600" : "400")};
  margin-left: 56px;
  color: #9da6ad;
`;

export const LoadingContainer = styled.div`
  width: 100%;
  min-height: calc(100vh - 392px);
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ButtonContainer = styled.div`
  border-top: 2px solid #c9d9e8;
  padding: 0 16px;
  width: 100%;
  height: 72px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: end;
  margin-top: 32px;
`;
