import React, { useEffect, useState } from "react";
import {
  AccrofieldBody,
  AccrofieldHeader,
  AccrofieldNameContainer,
  AccrofiledContainer,
  AcrofieldsListContainer,
  BackSwitch,
  ButtonContainer,
  Content,
  DocumentContent,
  DocumentSwitchContainer,
  Form,
  FormTitle,
  ForwardSwitch,
  Label,
  LoadingContainer,
  SelectContainer,
  SignerNameContainer,
  SingerName,
  Wrapper,
} from "./style";
import PdfDocument from "../pdfDocument";
import Button from "../../../../components/button";
import {
  faChevronRight,
  faChevronLeft,
  faCheckCircle,
  faCaretRight,
  faCaretDown,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Select from "../../../../components/select";
import ConfirmationModal from "../confirmationModal";
import Modal from "../../../../components/modal";
import {
  getApiToken,
  getDocument,
  sendAssignedDocument,
  startSession,
} from "../../../../api/backofficeAPI";

import Loading from "../../../../components/loading";
import { useNavigate } from "react-router-dom";
import EliminationModal from "../eliminationModal";
import { useToast } from "../../../../context/toastNotificationContext";

function DocumentAssign(props) {
  const {
    signatories,
    handleToPreviousStep,
    handleStorageStep,
    sessionData,
    formSteps,
  } = props;

  const [document, setDocument] = useState(null);
  const [acrofieldsList, setAcrofieldsList] = useState([]);
  const [totalDocuments, setTotalDocuments] = useState(0);
  const [documentIndex, setDocumentIndex] = useState(
    JSON.parse(localStorage.getItem("documentIndex"))
  );
  const [selectedSignatory, setSelectedSignatory] = useState("");
  const [activeAcrofieldIndex, setActiveAcrofieldIndex] = useState(0);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [isBackModalOpen, setIsBackModalOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const { showToast } = useToast();
  const navigate = useNavigate();
  const sessionId = localStorage.getItem("sessionId");

  const allAcrofieldsAssigned =
    acrofieldsList?.length > 0 &&
    acrofieldsList?.every((field) => field.signatoryFiscalCode?.length > 0);

  useEffect(() => {
    const data = localStorage.getItem("acrofieldsList");
    data && setAcrofieldsList(JSON.parse(data));
  }, []);

  useEffect(() => {
    document && setLoading(false);
  }, [document]);

  useEffect(() => {
    setDocument(null);
    getDocument(sessionId, documentIndex)
      .then((response) => {
        if (response.status === 200) {
          setDocument(response.data?.documentBase64);
          setAcrofieldsList(response.data?.acroFields);
          setTotalDocuments(response.data?.totalDocuments);
          setCurrentPage(response.data?.acroFields[0]?.pageNumber);
        } else {
          showToast(response.data.Message || "Qualcosa e andato storto", {
            type: "error",
            duration: 5000,
          });
        }
      })
      .catch(() => {
        showToast("Qualcosa e andato storto", {
          type: "error",
          duration: 5000,
        });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documentIndex, sessionId]);

  useEffect(() => {
    allAcrofieldsAssigned && setActiveAcrofieldIndex(-1);
  }, [allAcrofieldsAssigned]);

  const handleGoBack = () => {
    localStorage.removeItem("acrofieldsList");
    handleToPreviousStep();
  };

  const handleSelectField = (i) => {
    if (i !== activeAcrofieldIndex) {
      setActiveAcrofieldIndex(i);
      setCurrentPage(acrofieldsList[i]?.pageNumber);
    } else {
      setActiveAcrofieldIndex(-1);
    }
  };

  const getSignatoryByFiscalCode = (fiscalCode) => {
    const signatory = signatories.find(
      (signatory) => signatory.fiscalCode === fiscalCode
    );

    if (signatory) {
      return `${signatory.name} ${signatory.surname}`;
    }

    return "";
  };

  const handleNameSelect = (name, callback) => {
    let updatedAcrofields = [...acrofieldsList];
    updatedAcrofields[activeAcrofieldIndex].signatoryFiscalCode = name;

    const nextIndex =
      activeAcrofieldIndex + 1 < acrofieldsList?.length
        ? activeAcrofieldIndex + 1
        : activeAcrofieldIndex;

    setAcrofieldsList(updatedAcrofields);
    setActiveAcrofieldIndex(nextIndex);

    if (nextIndex < acrofieldsList?.length) {
      setCurrentPage(acrofieldsList[nextIndex]?.pageNumber);
    }

    if (callback) callback();
  };

  const handleConfirmationModal = () => {
    setIsConfirmationModalOpen((prev) => !prev);
  };

  const handleBackModal = () => {
    setIsBackModalOpen((prev) => !prev);
  };

  const handleGoToPreviousDocument = () => {
    if (documentIndex + 1 > 1) {
      setLoading(true);
      setActiveAcrofieldIndex(0);
      localStorage.setItem("documentIndex", documentIndex - 1);
      setDocumentIndex((prev) => prev - 1);
    }
  };

  const handleGoToNextDocument = () => {
    if (documentIndex + 1 < totalDocuments) {
      setLoading(true);
      setActiveAcrofieldIndex(0);
      localStorage.setItem("documentIndex", documentIndex + 1);
      setDocumentIndex((prev) => prev + 1);
    }
  };

  const simplifyAcroFields = () => {
    return acrofieldsList.map(({ fieldName, signatoryFiscalCode }) => ({
      fieldName,
      signatoryFiscalCode,
    }));
  };

  const handleStartSession = () => {
    setLoading(true);
    handleConfirmationModal();
    localStorage.setItem("sessionType", "IdentifyPlusSign");

    const showErrorToast = (errorMessage) => {
      showToast(errorMessage || "Qualcosa è andato storto", {
        type: "error",
        duration: 5000,
      });
    };

    getApiToken()
      .then((response) => {
        if (response.status !== 200) {
          // showErrorToast(response.data.Message);
        }

        return startSession(
          {
            ...sessionData,
            signatureType: localStorage.getItem("signatureType"),
            processId: sessionId,
          },
          response.data
        );
      })
      .then((sessionResponse) => {
        if (sessionResponse.status === 200) {
          showToast("Sessione avviata con sucesso", {
            type: "success",
            duration: 5000,
          });
          handleStorageStep(formSteps[6]);
          navigate("/create-session/summary");
        } else {
          showErrorToast(sessionResponse.data.Message);
        }
      })
      .catch((err) => showErrorToast(err?.data?.Message))
      .finally(() => {
        setLoading(false);
      });
  };

  const handleSendDocument = () => {
    setLoading(true);
    sendAssignedDocument(sessionId, documentIndex, simplifyAcroFields())
      .then((response) => {
        if (response.status === 200) {
          documentIndex + 1 === totalDocuments
            ? handleConfirmationModal()
            : handleGoToNextDocument();
          localStorage.setItem(
            "acrofieldsList",
            JSON.stringify(acrofieldsList)
          );
          setAcrofieldsList([]);
        } else {
          showToast(response.data.Message || "Qualcosa e andato storto", {
            type: "error",
            duration: 5000,
          });
        }
      })
      .catch(() => {
        showToast("Qualcosa e andato storto", {
          type: "error",
          duration: 5000,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Wrapper>
      {loading ? (
        <LoadingContainer>
          <Loading large />
        </LoadingContainer>
      ) : (
        <Content>
          <DocumentSwitchContainer>
            <BackSwitch
              disabled={documentIndex + 1 === 1}
              onClick={handleGoToPreviousDocument}
            >
              <FontAwesomeIcon icon={faChevronLeft} />
            </BackSwitch>
            {`Documento ${documentIndex + 1} / ${totalDocuments}`}
            <ForwardSwitch
              onClick={handleGoToNextDocument}
              disabled={documentIndex + 1 === totalDocuments}
            >
              <FontAwesomeIcon icon={faChevronRight} />
            </ForwardSwitch>
          </DocumentSwitchContainer>
          <DocumentContent>
            <PdfDocument
              document={document}
              documentName={"test document"}
              acrofieldsList={acrofieldsList}
              activeAcrofieldIndex={activeAcrofieldIndex}
              currentPage={currentPage}
              getSignatoryByFiscalCode={getSignatoryByFiscalCode}
            />
            <Form>
              {allAcrofieldsAssigned && (
                <FontAwesomeIcon
                  icon={faCheckCircle}
                  className="title-check-icon"
                />
              )}
              <FormTitle>
                {allAcrofieldsAssigned
                  ? "Hai assegnato tutti i campi da firmare del"
                  : "Assegna un soggetto a ciascun campo da firmare del"}{" "}
                <strong>{`Documento ${
                  documentIndex + 1
                }/${totalDocuments}`}</strong>
              </FormTitle>

              <AcrofieldsListContainer>
                {acrofieldsList?.map((field, index) => {
                  const isActive = activeAcrofieldIndex === index;
                  return (
                    <AccrofiledContainer key={index}>
                      <AccrofieldHeader
                        Active={isActive}
                        onClick={() => handleSelectField(index)}
                      >
                        <AccrofieldNameContainer>
                          <FontAwesomeIcon
                            icon={isActive ? faCaretDown : faCaretRight}
                            className="arrow-icon"
                          />
                          {field.fieldName}
                        </AccrofieldNameContainer>
                        <SignerNameContainer>
                          <SingerName>
                            {getSignatoryByFiscalCode(
                              field.signatoryFiscalCode
                            )}
                          </SingerName>
                          <FontAwesomeIcon
                            icon={faCheckCircle}
                            className="check-icon"
                          />
                        </SignerNameContainer>
                      </AccrofieldHeader>
                      {isActive && (
                        <AccrofieldBody>
                          <SelectContainer>
                            <Label>Soggetto *</Label>
                            <Select
                              value={selectedSignatory}
                              onChange={(e) => {
                                handleNameSelect(e.target.value, () =>
                                  setSelectedSignatory("")
                                );
                              }}
                            >
                              <option value="">Seleziona</option>
                              {signatories?.map((signer, index) => {
                                return (
                                  <option key={index} value={signer.fiscalCode}>
                                    {`${signer.name} ${signer.surname}`}
                                  </option>
                                );
                              })}
                            </Select>
                          </SelectContainer>
                        </AccrofieldBody>
                      )}
                    </AccrofiledContainer>
                  );
                })}
              </AcrofieldsListContainer>
            </Form>
          </DocumentContent>
        </Content>
      )}

      <ButtonContainer>
        <Button ghost margin8 onClick={handleBackModal}>
          Indietro
        </Button>
        <Button
          primaryLarge
          disabled={!allAcrofieldsAssigned}
          onClick={handleSendDocument}
        >
          Avanti
        </Button>
      </ButtonContainer>
      <Modal isOpen={isConfirmationModalOpen} onClose={handleConfirmationModal}>
        <ConfirmationModal
          title="Invia documenti da firmare"
          description=" Vuoi inviare i documenti da firmare ai soggetti indicati?"
          onClose={handleConfirmationModal}
          onConfirm={handleStartSession}
        />
      </Modal>
      <Modal isOpen={isBackModalOpen} onClose={handleBackModal}>
        <EliminationModal
          title="Vuoi ricaricare i documenti"
          description="Tornando indietro i documenti caricati andranno persi"
          onClose={handleBackModal}
          onConfirm={handleGoBack}
        />
      </Modal>
    </Wrapper>
  );
}

export default DocumentAssign;
