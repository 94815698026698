import React from "react";
import Input from "../../../../components/input";

export const UtentiForm = (props) => {
  return (
    <div>
      <div className="drawer-title border-none margin-top">Gestione Utenti</div>
      <div className="input-container">
        <label className="form-label" htmlFor="tenant">
          Numero massimo di utenti attivi
        </label>
        <Input
          min="5"
          id="maxNrOfUsersPerTenant"
          name="maxNrOfUsersPerTenant"
          type="number"
          placeholder="Numero massimo di utenti attivi"
          onChange={(e) => props.setMaxNrOfUsersPerTenant(e.target.value)}
          value={props.maxNrOfUsersPerTenant}
        />
        {props.getError("MaxNrOfUsersPerTenant") && (
          <div className="error-message">
            {props.getError("MaxNrOfUsersPerTenant").message}
          </div>
        )}
      </div>
    </div>
  );
};
