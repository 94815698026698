import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import styled from "styled-components";

const Loading = (props) => {
  return (
    <LoadingWrapper>
      <FontAwesomeIcon
        className={`fas fa-circle-notch fa-spin
        ${props.white && 'white'}
        ${props.extraSmall && "size-extra-small"}
         ${props.small && "size-small"} ${props.large && "size-large"}`}
        icon={faCircleNotch}
      />
    </LoadingWrapper>
  );
};

export default Loading;

const LoadingWrapper = styled.div`
  color: #0090d1;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .white {
    color: white;
  }

  .size-extra-small {
    width: 1.5rem;
    height: 1.5rem;
  }

  .size-small {
    width: 1.5rem;
    height: 1.5rem;
  }

  .size-large {
    width: 2.5rem;
    height: 2.5rem;
  }
`;
