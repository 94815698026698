import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: center;

  .form {
    width: 320px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .input-container {
    margin-top: 2rem;
  }
  .toggler-container {
    display: flex;
    flex-direction: row;
    height: fit-content;
    align-items: center;
    margin-top: 2rem;
  }
  .checkbox {
    width: 1rem;
    margin-right: 1rem;
  }
  .select-container {
    margin-top: 2rem;
  }
  .not-allowed {
    cursor: not-allowed;
  }
  .error-message {
    color: #f5222d;
    font-size: 0.8rem;
    margin-bottom: 0.5rem;
    font-weight: 600;
    max-width: 20rem;
  }
  .warning-message {   
    max-width: 320px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 1rem;
    color: #f5222d;
  }
  .warning-icon {
    color: #fdb927;
    margin-right: 0.5rem;
    margin-top: 0.3rem;
  }
  .server-error {
    display: flex;
    width: 100%;
    margin-top: 1rem;
    color: #f5222d;
    font-size: 0.8rem;
  }

  .buttons {
    display: flex;
    justify-content: end;
    margin-top: 4rem;
  }

  .buttons2 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .submit {
    margin-left: 1rem;
  }

  .lower-section{
    margin-bottom: 2rem;
    margin-top: 2rem;
  }

  .form-label {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
`;
