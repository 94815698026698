import styled from "styled-components";

export const Wrapper = styled.div`
  width: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const TitleContaier = styled.div`
  display: flex;
  align-items: center;
  border-bottom: solid 2px #d82829;
  width: 100%;
  height: 70px;
  font-size: 1.25rem;
  font-weight: 500;
  color: #005075;
  background-color: white;
  padding-left: 16px;
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 84px;
  font-family: "Roboto";
  font-size: 0.875rem;
  font-weight: 400;
  color: #152935;
  background-color: white;
  padding: 0 0.7rem;
  text-align: center;
`;

export const ButtonContaier = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
  background-color: #f2f5f8;
  width: 100%;
  height: 64px;
`;
