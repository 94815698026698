import React, { useEffect, useState } from "react";
import {
  ButtonContainer,
  Column,
  Content,
  InputContainer,
  InputRow,
  Label,
  LargeInputContainer,
  RadioContainer,
  Title,
  Wrapper,
} from "./style";
import Input from "../../../../components/input";
import Button from "../../../../components/button";
import { hasEmptyFields } from "../../../../utils/functions";
import { fieldValidationConfig } from "./validations";
import InputError from "../../../../components/inputError";

function SubjectModal(props) {
  const {
    onClose,
    setSubjectsData,
    subjectsData,
    userToEditCurrentIndex,
    setUserToEditCurrentIndex,
    canIdentifyWithVideo,
  } = props;
  const [data, setData] = useState({
    name: "",
    surname: "",
    fiscalCode: "",
    email: "",
    mobileNumber: "",
    gender: "M",
    birthDate: "",
    cityName: "",
  });
  const [dataError, setDataError] = useState({
    name: "",
    surname: "",
    fiscalCode: "",
    email: "",
    mobileNumber: "",
    gender: "",
    birthDate: "",
    cityName: "",
  });

  const { gender, birthDate, cityName, ...otherData } = data;
  const incompleteForm =
    hasEmptyFields(canIdentifyWithVideo ? data : otherData) ||
    Object.values(dataError).some((error) => error !== "");

  useEffect(() => {
    // Check if an editing process sets the data of the user to edit
    userToEditCurrentIndex !== null &&
      setData(subjectsData[userToEditCurrentIndex]);
  }, [subjectsData, userToEditCurrentIndex]);

  const handleChange = (e) => {
    const { value, name } = e.target;
    const config = fieldValidationConfig[name];

    let error = "";
    if (!value) {
      error = config.emptyErrorMessage;
    } else if (!config.regex.test(value)) {
      error = config.invalidErrorMessage;
    }

    setData((prevData) => ({ ...prevData, [name]: value }));
    setDataError((prevError) => ({ ...prevError, [name]: error }));
  };

  const handleSubmit = () => {
    if (userToEditCurrentIndex !== null) {
      // Edit existing user
      setSubjectsData((prev) =>
        prev.map((item, index) =>
          index === userToEditCurrentIndex ? data : item
        )
      );
    } else {
      // Adding new user
      setSubjectsData((prev) => [...prev, data]);
    }
    setUserToEditCurrentIndex(null);
    onClose();
  };

  return (
    <Wrapper>
      <Title>Aggiungi soggetto</Title>
      <Content>
        <InputRow>
          <InputContainer>
            <Label>Nome *</Label>
            <Input
              type="text"
              placeholder="Inserisci il nome del soggetto"
              name="name"
              value={data.name}
              onChange={(e) => handleChange(e)}
            />
            <InputError message={dataError.name} />
          </InputContainer>
          <InputContainer>
            <Label>Cognome *</Label>
            <Input
              type="text"
              placeholder="Inserisci il cognome del soggetto"
              name="surname"
              value={data.surname}
              onChange={(e) => handleChange(e)}
            />
            <InputError message={dataError.surname} />
          </InputContainer>
        </InputRow>
        <InputRow margin>
          <InputContainer>
            <Label>Codice fiscale *</Label>
            <Input
              type="text"
              placeholder="Inserisci Codice Fiscale del soggetto"
              name="fiscalCode"
              value={data.fiscalCode}
              onChange={(e) => handleChange(e)}
            />
            <InputError message={dataError.fiscalCode} />
          </InputContainer>
          <InputContainer>
            <Label>Telefono *</Label>
            <Input
              type="text"
              placeholder="Inserisci il telefono"
              name="mobileNumber"
              value={data.mobileNumber}
              onChange={(e) => handleChange(e)}
            />
            <InputError message={dataError.mobileNumber} />
          </InputContainer>
        </InputRow>
        {canIdentifyWithVideo && (
          <InputRow margin>
            <InputContainer>
              <Label>Data di nascita *</Label>
              <Input
                type="date"
                placeholder="Inserisci la data di nascita del soggetto"
                name="birthDate"
                value={data.birthDate}
                onChange={(e) => handleChange(e)}
              />
              <InputError message={dataError.birthDate} />
            </InputContainer>
            <InputContainer>
              <Label>Città di nascita *</Label>
              <Input
                type="text"
                placeholder="Inserisci la città di nascita del soggetto"
                name="cityName"
                value={data.cityName}
                onChange={(e) => handleChange(e)}
              />
              <InputError message={dataError.cityName} />
            </InputContainer>
          </InputRow>
        )}
        {canIdentifyWithVideo && (
          <Column>
            <RadioContainer>
              <InputContainer>
                <Label>Maschio </Label>
                <Input
                  type="radio"
                  placeholder="Inserisci il sesso del soggetto"
                  name="gender"
                  value={"M"}
                  checked={data.gender === "M"}
                  onChange={(e) => handleChange(e)}
                />
              </InputContainer>
              <InputContainer>
                <Label>Femmina</Label>
                <Input
                  type="radio"
                  placeholder="Inserisci il sesso del soggetto"
                  name="gender"
                  value={"F"}
                  checked={data.gender === "F"}
                  onChange={(e) => handleChange(e)}
                />
              </InputContainer>
            </RadioContainer>
            <InputError message={dataError.gender} />
          </Column>
        )}
        <InputRow margin>
          <LargeInputContainer>
            <Label>Email *</Label>
            <Input
              large
              type="email"
              placeholder="Inserisci l’email del soggetto"
              name="email"
              value={data.email}
              onChange={(e) => handleChange(e)}
            />
            <InputError message={dataError.email} />
          </LargeInputContainer>
        </InputRow>
      </Content>

      <ButtonContainer>
        <Button ghost margin8 onClick={onClose}>
          Annulla
        </Button>
        <Button primaryLarge onClick={handleSubmit} disabled={incompleteForm}>
          Avanti
        </Button>
      </ButtonContainer>
    </Wrapper>
  );
}

export default SubjectModal;
