import styled, { css } from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  min-height: calc(100vh - 288px);
`;

export const Form = styled.div`
  height: fit-content;
  display: flex;
  flex-direction: column;
  width: 870px;
`;

export const PageTitle = styled.h4`
  color: black;
  width: 100%;
  font-weight: 600;
  text-align: start;
`;

export const PageDescription = styled.h6`
  color: black;
  font-size: 14px;
  margin-bottom: 24px;
`;

export const RowInputContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 16px;

  ${(props) =>
    props.alignBottom &&
    css`
      align-items: end;
    `}
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  ${(props) =>
    props.marginLeft &&
    css`
      margin-left: 16px;
    `}

  ${(props) =>
    props.small &&
    css`
      width: 200px;
    `}
`;

export const Label = styled.label`
  font-weight: 500;
  font-size: 0.875rem;
`;

export const InputButtonContainer = styled.div`
  margin-top: 23px;
  margin-left: 8px;
`;

export const TagContainer = styled.div`
  width: 100%;
  margin-top: 16px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  font-size: 14px;
  font-weight: 400;
`;

export const Tag = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 4px 8px;
  background-color: #dbf3ff;
  color: #004666;
  border-radius: 4px;
  border: 1px solid #66ccff;
  font-size: 12px;
  margin-right: 8px;

  .close-icon {
    width: 14px;
    height: 14px;
    cursor: pointer;
    margin-left: 4px;
    margin-bottom: 1px;
  }
`;

export const ButtonContainer = styled.div`
  border-top: 2px solid #c9d9e8;
  padding: 0 16px;
  width: 100%;
  height: 72px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: end;
  margin-top: 32px;
`;
