import React, { useCallback, useEffect, useState } from "react";
import { createNewCompany } from "../../../../api/backofficeAPI";
import Button from "../../../../components/button";
import { Wrapper } from "../../../styles/tenantForm";
import { TenantForm } from "./TenantForm";
import { SessioniForm } from "./SessionForm";
import { UtentiForm } from "./UserForm";
import { FirmeForm } from "./SigningForm";
import { AuthenticationMethodForm } from "./AuthenticationMethodForm";
import { SpidForm } from "./SpidForm";
import { ArchiveForm } from "./ArchiveForm";
import { AdminForm } from "./AdminForm";
import { IdSignForm } from "./IdSignForm";
import { TsIdForm } from "./TsIdForm";

const NewTenantForm = (props) => {
  let date = new Date();
  let now_utc = Date.UTC(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    date.getUTCHours(),
    date.getUTCMinutes(),
    date.getUTCSeconds()
  );

  const environment = process.env.REACT_APP_ENVIRONMENT;
  const [id] = useState(null);
  const [idTenant, setIdTenant] = useState("");
  const [ragioneSociale, setRagioneSociale] = useState("");
  const [partitaIva, setPartitaIva] = useState("");
  const [dataAttivazione] = useState(new Date(now_utc).toISOString());
  const [isActive, setIsActive] = useState(true);
  const [adminName, setAdminName] = useState("");
  const [adminSurname, setAdminSurname] = useState("");
  const [adminAccountEmail, setAdminAccountEmail] = useState("");
  const [maxSessionLength, setMaxSessionLength] = useState("180");
  const [sessionTimeout, setSessionTimeout] = useState("30");
  const [alertBeforeTimeout, setAlertBeforeTimeout] = useState("5");
  const [sessionsPerPage, setSessionsPerPage] = useState("10");
  const [maxNrOfUsersPerTenant, setMaxNrOfUsersPerTenant] = useState(5);
  const [onlyIdentify, setOnlyIdentify] = useState("");
  const [simple, setSimple] = useState("");
  const [advanced, setAdvanced] = useState("");
  const [qualified, setQualified] = useState("");
  const [clientId, setClientId] = useState(
    environment === "developement" ? "idsign-coll-tsonboarding" : ""
    );
  const [clientSecret, setClientSecret] = useState(
    environment === "developement" ? "idsign-coll-tsonboarding" : ""
    );
  const [institutionCode, setInstitutionCode] = useState(
    environment === "developement" ? "cliente_test" : ""
    );

  const [tsClientId, setTsClientId] = useState("");
  const [tSClientSecret, setTsClientSecret] = useState("");

  const [attributi, setAttributi] = useState("Min");

  const [spidUrl, setSpidUrl] = useState(
    environment === "developement" ? "https://spid-sp-cert.aliaslab.net/spid/gateway": ""
  );
  const [spidUsername, setSpidUsername] = useState(
    environment === "developement" ? "teamsystem" : ""
  );
  const [spidPassword, setSpidPassword] = useState(
    environment === "developement" ? "teamsystem01": ""
  );
  
  const [portaleFirma] = useState("DigitalSign");
  const [metodoVideo, setMetodoVideo] = useState("");
  const [metodoSpid, setMetodoSpid] = useState("");
  const [metodoCie, setMetodoCie] = useState("");
  const [fea, setFea] = useState("FEA");
  const [feq, setFeq] = useState("FEQ");
  const [sigillo, setSigillo] = useState("SIGILLO");
  const [feaWorkflowId, setFeaWorkflowId] = useState("");
  const [feqWorkflowId, setFeqWorkflowId] = useState("");
  const [sigilloWorkflowId, setSigilloWorkflowId] = useState("");

  const [responseErrors, setResponseErrors] = useState([]);
  const [toctocSync, setToctocSync] = useState("");
  const [certificato, setCertificato] = useState("");
  const [backofficeAutoGestito, setBackofficeAutogestito] = useState(false);
  const [accountId, setAccountId] = useState("");
  const [accountKey, setAccountKey] = useState("");
  const [feRedirectUrl, setFeRedirectUrl] = useState("");
  const [serverError, setServerError] = useState("");

  const [archiveUuid, setArchiveUuid] = useState("");
  const [archiveFlag, setArchiveFlag] = useState("no");

  const body = {
    id: id,
    teamsystemTenantId: parseInt(idTenant),
    businessName: ragioneSociale,
    vatNumber: partitaIva,
    activationDate: dataAttivazione,
    isActive: isActive,
    adminName: adminName,
    adminSurname: adminSurname,
    adminAccountEmail: adminAccountEmail,
    maxSessionLength: parseInt(maxSessionLength),
    sessionTimeout: parseInt(sessionTimeout),
    alertBeforeTimeout: parseInt(alertBeforeTimeout),
    sessionsPerPage: parseInt(sessionsPerPage),
    maxNrOfUsersPerTenant: maxNrOfUsersPerTenant,
    supportedSignatures: [
      ...(onlyIdentify.length > 0 ? [onlyIdentify] : []),
      ...(advanced.length > 0 ? [advanced] : []),
      ...(simple.length > 0 ? [simple] : []),
      ...(qualified.length > 0 ? [qualified] : []),
    ],
    idsignClientConfiguration: {
      clientId: clientId,
      clientSecret: clientSecret,
      institutionCode: institutionCode,
    },
    teamSystemIdCredentials: {
      clientId: tsClientId,
      clientSecret: tSClientSecret,
    },
    supportedRecognitionModes: [
      ...(metodoSpid.length > 0 ? [metodoSpid] : []),
      ...(metodoCie.length > 0 ? [metodoCie] : []),
      ...(metodoVideo.length > 0 ? [metodoVideo] : []),
      ...(toctocSync.length > 0 ? [toctocSync] : []),
      ...(certificato.length > 0 ? [certificato] : []),
    ],
    tocTocData: {
      authenticationPortalSelfManage: backofficeAutoGestito,
      feRedirectUrl: feRedirectUrl,
      accountId: accountId,
      accountKey: accountKey,
      workflowCombinations: [
        { identificationPurpose: fea, workflowId: feaWorkflowId },
        { identificationPurpose: feq, workflowId: feqWorkflowId },
        { identificationPurpose: sigillo, workflowId: sigilloWorkflowId },
      ],
    },
    note: "",
    startMailTemplate: "",
    endMailTemplate: "",
    //spidLevel: attributi,
    spidConfigurations: {
      url: spidUrl,
      attributeSet: attributi,
      username: spidUsername,
      password: spidPassword,
    },
    adminId: null,
    targetSignaturePortal: portaleFirma,
    archiveData: {
      companyUuid: archiveFlag === "yes" ? archiveUuid: "",
      isActive: archiveFlag === "yes" ? true : false,
    }
  };

  const onFormSubmit = () => {
    createNewCompany(body).then((response) => {
      if (response.status === 400) {
        setResponseErrors(response.data);
      } else if (response.status === 201) {
        props.getData();
        props.handleDrawer(false);
      } else {
        setServerError(response.data);
      }
    });
  };

  const showTocTocSettings = useCallback(() => {
    if (toctocSync === "TOCTOCSYNC" || metodoVideo === "TOCTOC") {
      return true;
    } else {
      return false;
    }
  }, [metodoVideo, toctocSync]);

  const getError = (field) => {
    return responseErrors?.find((obj) => {
      return obj.fieldName === field;
    });
  };
  const toctocSyncHandler = () => {
    if (toctocSync === "TOCTOCSYNC") {
      setToctocSync("");
    } else {
      if (metodoVideo === "INVYDIO" && toctocSync === "") {
        setMetodoVideo("");
        setToctocSync("TOCTOCSYNC");
      } else {
        setToctocSync("TOCTOCSYNC");
      }
    }
  };

  const invydeoHandler = () => {
    if (metodoVideo === "INVYDIO") {
      setMetodoVideo("");
    } else {
      if (toctocSync) {
        setToctocSync("");
        setMetodoVideo("INVYDIO");
      } else {
        setMetodoVideo("INVYDIO");
      }
    }
  };

  const showArchiveUuIdBox = () =>{
    return archiveFlag === "yes";
  }

  useEffect(() => {
    if (!backofficeAutoGestito) {
      setAccountId("");
      setAccountKey("");
      setFeaWorkflowId("");
      setFeqWorkflowId("");
      setSigilloWorkflowId("");
    }
  }, [backofficeAutoGestito]);

  useEffect(() => {
    if (toctocSync !== "TOCTOCSYNC" && metodoVideo !== "TOCTOC") {
      setBackofficeAutogestito(false);
      setAccountId("");
      setAccountKey("");
      setFeaWorkflowId("");
      setFeqWorkflowId("");
      setSigilloWorkflowId("");
    }
  }, [metodoVideo, toctocSync]);

  return (
    <Wrapper>
      <div className="sidebar-header">
        <div className="sidebar-title">CREA NUOVO TENANT</div>
      </div>
      <div className="form">
        <TenantForm
          getError={getError}
          idTenant={idTenant}
          setIdTenant={setIdTenant}         
          ragioneSociale={ragioneSociale}
          setRagioneSociale={setRagioneSociale}
          partitaIva={partitaIva}
          setPartitaIva={setPartitaIva}
          dataAttivazione={dataAttivazione}
          isActive={isActive}
          setIsActive={setIsActive}
        />

        <AdminForm
          title={"Dati Amministratore Tenant"}
          getError={getError}
          adminName={adminName}
          setAdminName={setAdminName}
          adminSurname={adminSurname}
          setAdminSurname={setAdminSurname}
          adminAccountEmail={adminAccountEmail}
          setAdminAccountEmail={setAdminAccountEmail}
          nameError={"AdminName"}
          surnameError={"AdminSurname"}
          emailError={"AdminAccountEmail"}
        />

        <SessioniForm
          getError={getError}
          maxSessionLength={maxSessionLength}
          setMaxSessionLength={setMaxSessionLength}
          sessionTimeout={sessionTimeout}
          setSessionTimeout={setSessionTimeout}
          alertBeforeTimeout={alertBeforeTimeout}
          setAlertBeforeTimeout={setAlertBeforeTimeout}
          sessionsPerPage={sessionsPerPage}
          setSessionsPerPage={setSessionsPerPage}
        />

        <UtentiForm
          getError={getError}
          maxNrOfUsersPerTenant={maxNrOfUsersPerTenant}
          setMaxNrOfUsersPerTenant={setMaxNrOfUsersPerTenant}
        />

        <FirmeForm
          getError={getError}
          onlyIdentify={onlyIdentify}
          setOnlyIdentify={setOnlyIdentify}
          simple={simple}
          setSimple={setSimple}
          advanced={advanced}
          setAdvanced={setAdvanced}
          qualified={qualified}
          setQualified={setQualified}
        />

        <TsIdForm
          getError={getError}
          tsClientId={tsClientId}
          setTsClientId={setTsClientId}
          tsClientSecret={tSClientSecret}
          setTsClientSecret={setTsClientSecret}
        />
        {advanced.length > 0 && (
          <IdSignForm
            getError={getError}
            clientId={clientId}
            setClientId={setClientId}
            clientSecret={clientSecret}
            setClientSecret={setClientSecret}
            institutionCode={institutionCode}
            setInstitutionCode={setInstitutionCode}
          />
        )}

        <AuthenticationMethodForm
          getError={getError}
          metodoSpid={metodoSpid}
          setMetodoSpid={setMetodoSpid}
          metodoCie={metodoCie}
          setMetodoCie={setMetodoCie}
          metodoVideo={metodoVideo}
          invydeoHandler={invydeoHandler}
          setMetodoVideo={setMetodoVideo}
          toctocSync={toctocSync}
          toctocSyncHandler={toctocSyncHandler}
          certificato={certificato}
          setCertificato={setCertificato}
          backofficeAutoGestito={backofficeAutoGestito}
          setBackofficeAutogestito={setBackofficeAutogestito}
          accountId={accountId}
          setAccountId={setAccountId}
          accountKey={accountKey}
          setAccountKey={setAccountKey}
          feRedirectUrl={feRedirectUrl}
          setFeRedirectUrl={setFeRedirectUrl}
          showTocTocSettings={showTocTocSettings}
          fea={fea}
          feq={feq}
          sigillo={sigillo}
          feaWorkflowId={feaWorkflowId}
          feqWorkflowId={feqWorkflowId}
          sigilloWorkflowId={sigilloWorkflowId}
          setFea={setFea}
          setFeq={setFeq}
          setSigillo={setSigillo}
          setFeaWorkflowId={setFeaWorkflowId}
          setFeqWorkflowId={setFeqWorkflowId}
          setSigilloWorkflowId={setSigilloWorkflowId}
        />

        <SpidForm
          getError={getError}
          metodoSpid={metodoSpid}
          setAttributi={setAttributi}
          attributi={attributi}
          spidUrl={spidUrl}
          setSpidUrl={setSpidUrl}
          spidUsername={spidUsername}
          setSpidUsername={setSpidUsername}
          spidPassword={spidPassword}
          setSpidPassword={setSpidPassword}
        />

        <ArchiveForm
          getError={getError}
          archiveFlag = {archiveFlag}
          setArchiveFlag = {setArchiveFlag}
          archiveUuid={archiveUuid}
          setArchiveUuid={setArchiveUuid}
          showArchiveUuIdBox={showArchiveUuIdBox}
        />

        <div className="buttons">
          <Button ghostSmall onClick={() => props.handleDrawer(false)}>
            Annulla
          </Button>
          <Button
            onClick={onFormSubmit}
            type="button"
            primary
            className="submit"
          >
            Conferma
          </Button>
        </div>
        <div className="server-error">{true ? serverError : null}</div>
      </div>
    </Wrapper>
  );
};

export default NewTenantForm;
