import React from "react";
import Input from "../../../../components/input";

export const IdSignForm = (props) => {
  return (
    <div>
      <div className="drawer-title border-none margin-top">
        Configurazione IdSign
      </div>

      <div className="input-container">
        <label className="form-label">Id Cliente</label>
        <Input
          id="ClientId"
          name="ClientId"
          type="text"
          placeholder="Id cliente"
          onChange={(e) => props.setClientId(e.target.value)}
          value={props.clientId}
        />
        {props.getError("ClientId") && (
          <div className="error-message">
            {props.getError("ClientId").message}
          </div>
        )}
      </div>

      <div className="input-container">
        <label className="form-label">Secret Cliente</label>
        <Input
          id="ClientSecret"
          name="ClientSecret"
          type="text"
          placeholder="Secret cliente"
          onChange={(e) => props.setClientSecret(e.target.value)}
          value={props.clientSecret}
        />
        {props.getError("ClientSecret") && (
          <div className="error-message">
            {props.getError("ClientSecret").message}
          </div>
        )}
      </div>

      <div className="input-container">
        <label className="form-label">Codice del Instituzione</label>
        <Input
          id="InstitutionCode"
          name="InstitutionCode"
          type="text"
          placeholder="Codice del instituzione"
          onChange={(e) => props.setInstitutionCode(e.target.value)}
          value={props.institutionCode}
        />
        {props.getError("InstitutionCode") && (
          <div className="error-message">
            {props.getError("InstitutionCode").message}
          </div>
        )}
      </div>
    </div>
  );
};
