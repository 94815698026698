import React, { useState, useCallback } from "react";
import { useDropzone } from "react-dropzone";
import Loading from "../loading";
import { InputContainer, RowContainer, Text } from "./style";
import Button from "../button";
import InputError from "../inputError";

const FileUploader = ({ onFilesAccepted, existingFilesSize }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [dropError, setDropError] = useState(null);

  const onDrop = useCallback(
    (acceptedFiles) => {
      setIsLoading(true);

      // Check total size including existing files
      const totalSize =
        existingFilesSize +
        acceptedFiles.reduce((acc, file) => acc + file.size, 0);
      if (totalSize > 15 * 1024 * 1024) {
        // 15MB limit exceeded
        setDropError(
          "La dimensione totale dei file, compresi i file esistenti, supera i 15 MB"
        );
        setIsLoading(false);
        return;
      }

      setDropError(null);
      onFilesAccepted(acceptedFiles);
      setIsLoading(false);
    },
    [onFilesAccepted, existingFilesSize]
  );

  const onDropRejected = useCallback((fileRejections) => {
    const isTooLarge = fileRejections.some((rejection) =>
      rejection.errors.some((error) => error.code === "file-too-large")
    );
    const isWrongType = fileRejections.some((rejection) =>
      rejection.errors.some((error) => error.code === "file-invalid-type")
    );

    if (isTooLarge) {
      setDropError("Un file supera il limite di dimensione di 15 MB");
    } else if (isWrongType) {
      setDropError("Sono ammessi solo file PDF");
    }
  }, []);

  const { getRootProps, getInputProps, open } = useDropzone({
    onDrop,
    onDropRejected,
    multiple: true,
    maxSize: 15 * 1024 * 1024,
    accept: { "application/pdf": [".pdf"] },
    noClick: true,
  });

  return (
    <>
      <InputContainer>
        <div {...getRootProps()}>
          <input {...getInputProps()} />
          {isLoading ? (
            <Loading large />
          ) : (
            <RowContainer>
              <Text>Trascina un documento in quest’area, o</Text>
              <Button ghostSmall onClick={open}>
                Carica file
              </Button>
            </RowContainer>
          )}
        </div>
      </InputContainer>
      <InputError message={dropError}></InputError>
    </>
  );
};

export default FileUploader;
