import React from "react";
import { InfoContainer, InformationText, InformationTitle } from "./style";

function InfoBox() {
  return (
    <InfoContainer>
      <InformationTitle>Scegli i metodi di riconoscimento</InformationTitle>
      <InformationText>
        Scegli uno o più metodi disponibili a lato, e prosegui con la richiesta
        del certificato.
      </InformationText>
    </InfoContainer>
  );
}

export default InfoBox;
