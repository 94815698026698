import React from "react";
import Select from "../../../../components/select";
import Input from "../../../../components/input";

export const ArchiveForm = (props) => {
  return (
    <div>
      <div className="drawer-title border-none margin-top">
        Metodi di Archivazione
      </div>
      <div className="input-container">
        <Select
          defaultValue={props.archiveFlag}
          onChange={(e) => props.setArchiveFlag(e.target.value)}
          name="setArchiveFlag" 
        >
          <option value="yes">Voglio conservare in TS archive</option>
          <option value="no">Non voglio conservare</option>
        </Select>
      </div>
      {props.showArchiveUuIdBox() && (
        <>
      <div className="input-container">
        <label className="form-label" htmlFor="tenant">
          Archive UUID Tenant
        </label>
        <Input
          id="uuidTenant"
          name="uuidTenant"
          type="text"
          placeholder="uuid Tenant"
          value={props.archiveUuid}
          onChange={(e) => props.setArchiveUuid(e.target.value)}
        />
        {props.getError("CompanyUuid") && (
          <div className="error-message">
            {props.getError("CompanyUuid").message}
          </div>
        )}
      </div>
      </>
      )}
    </div>    
  );
};