import React from "react";
import styled from "styled-components";
import Button from "../button";
import Input from "../input";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEye,
  faCircleCheck,
  faTriangleExclamation,
} from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";
import { changePassword } from "../../api/backofficeAPI";
import { useUserContext } from "../../context/userContext";
import { TooltipIcon, TooltipWrapper } from "../tooltip";

function PasswordModal(props) {
  const modalRef = React.useRef(null);
  const [password, setPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState("");
  const [showNewPassword, setShowNewPassword] = useState("");
  const [showConfirmPassword, setShowConfirmPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [newPasswordError, setNewPasswordError] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const { myUser } = useUserContext();

  React.useEffect(() => {
    if (!props.isModalOpen) {
      setPassword("");
      setNewPassword("");
      setConfirmPassword("");
      setPasswordError("");
      setNewPasswordError("");
      setConfirmPasswordError("");
      setSuccessMessage("");
      setErrorMessage("");
      setShowPassword(false);
      setShowNewPassword(false);
      setShowConfirmPassword(false);
    }
  }, [props.isModalOpen]);

  React.useEffect(() => {
    const closeModal = (e) => {
      if (
        modalRef.current &&
        props.isModalOpen &&
        !modalRef.current.contains(e.target)
      ) {
        props.setIsModalOpen(false);
      }
    };
    document.addEventListener("mousedown", closeModal);
  }, [props]);

  let validated = true;

  const onValidate = () => {
    if (password.length === 0) {
      setPasswordError("Questo campo non può essere vuoto");
      validated = false;
    } else {
      setPasswordError("");
    }

    if (newPassword.length === 0) {
      setNewPasswordError("Questo campo non può essere vuoto");
      validated = false;
    } else {
      setNewPasswordError("");
    }

    if (confirmPassword.length === 0) {
      setConfirmPasswordError("Questo campo non può essere vuoto");
      validated = false;
    } else if (confirmPassword !== newPassword) {
      setConfirmPasswordError("I due password non corrispondono");
      validated = false;
    } else {
      setConfirmPasswordError("");
    }
  };

  const handleOnSubmit = () => {
    onValidate();
    if (validated) {
      const data = {
        account: myUser?.account,
        currentPassword: password,
        newPassword: newPassword,
      };
      setErrorMessage("");
      setSuccessMessage("");
      changePassword(data).then((response) => {
        if (response.status === 200) {
          setPassword("");
          setNewPassword("");
          setConfirmPassword("");
          setSuccessMessage("Validazione completata con successo!");
        } else if (response.status === 400) {
          setErrorMessage(response.data.Message);
        } else {
          setErrorMessage("Non e stato possibile cambiare la password");
        }
      });
    }
  };

  if (!props.isModalOpen) return null;
  return (
    <ModalOverlay>
      <Card ref={modalRef}>
        <div className="upper-panel">
          <h4>Cambia password</h4>
          <TooltipWrapper
            title="La password deve essere lunga almeno 8 caratteri. Deve contenere una lettera maiuscola, una minuscola, un numero e un simbolo."
            placement="top"
          >
            <div className="info-icon">
              <TooltipIcon />
            </div>
          </TooltipWrapper>
        </div>
        <div className="midle-panel">
          <div className="input-container">
            <label className="label">Password attuale</label>
            <Input
              id="password"
              type={showPassword ? "text" : "password"}
              placeholder="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <FontAwesomeIcon
              onClick={() => setShowPassword(!showPassword)}
              className={`eye-icon ${showPassword && "active"}`}
              icon={faEye}
            />
            {passwordError && (
              <div className="input-error-message">{passwordError}</div>
            )}
          </div>

          <div className="input-container">
            <label className="label"> Nuova password</label>
            <Input
              id="newPassword"
              type={showNewPassword ? "text" : "password"}
              placeholder="nuova password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
            />
            <FontAwesomeIcon
              onClick={() => setShowNewPassword(!showNewPassword)}
              className={`eye-icon ${showNewPassword && "active"}`}
              icon={faEye}
            />
            {newPasswordError && (
              <div className="input-error-message">{newPasswordError}</div>
            )}
          </div>

          <div className="input-container">
            <label className="label">Conferma nuova password</label>
            <Input
              id="confirmNewPassword"
              type={showConfirmPassword ? "text" : "password"}
              placeholder="conferma nuova password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
            <FontAwesomeIcon
              onClick={() => setShowConfirmPassword(!showConfirmPassword)}
              className={`eye-icon ${showConfirmPassword && "active"}`}
              icon={faEye}
            />
            {confirmPasswordError && (
              <div className="input-error-message">{confirmPasswordError}</div>
            )}
          </div>
          {successMessage && (
            <div className="success-message">
              <FontAwesomeIcon className="success-icon" icon={faCircleCheck} />
              {successMessage}
            </div>
          )}
          {errorMessage && (
            <div className="error-message">
              <FontAwesomeIcon
                className="error-icon"
                icon={faTriangleExclamation}
              />
              {errorMessage}
            </div>
          )}
        </div>
        <div className="lower-panel">
          <Button
            onClick={() => props.setIsModalOpen(false)}
            secondaryBlue
            marginRight
          >
            Annulla
          </Button>
          <Button primaryLarge onClick={handleOnSubmit}>
            Salva
          </Button>
        </div>
      </Card>
    </ModalOverlay>
  );
}

export default PasswordModal;

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 6;
  background-color: rgba(0, 0, 0, 0.7);
`;

const Card = styled.div`
  width: 420px;
  height: 456px;
  background-color: white;
  position: fixed;
  z-index: 7;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  .upper-panel {
    border-bottom: 2px solid #0090d1;
    display: flex;
    align-items: center;
    width: 100%;
    height: 60px;
    padding: 0 0.5rem;
  }

  .info-icon {
    margin-left: 0.5rem;
  }

  .midle-panel {
    height: 334px;
    padding: 0.5rem;
  }

  .lower-panel {
    display: flex;
    align-items: center;
    justify-content: end;
    background-color: #f2f5f8;
    width: 100%;
    height: 60px;
    padding: 0 0.5rem;
  }

  .input-container {
    width: 320px;
    margin-top: 1rem;
    position: relative;
    margin-left: 0.5rem;
  }

  .label {
    font-size: 0.875rem;
    color: black;
  }

  .eye-icon {
    position: absolute;
    right: 0.5rem;
    color: grey;
    z-index: 5;
    top: 2.2rem;
    cursor: pointer;
  }
  .active {
    color: #0090d1;
  }

  .input-error-message {
    font-size: 0.875rem;
    color: red;
    margin: 0;
  }

  .success-message {
    font-size: 16px;
    font-weight: 500;
    color: green;
    margin-left: 8px;
    margin-top: 2rem;
  }

  .success-icon {
    color: #09822a;
    height: 1rem;
    margin-right: 6px;
  }

  .error-message {
    font-size: 14px;
    font-weight: 500;
    color: red;
    margin-left: 8px;
    margin-top: 1rem;
  }

  .error-icon {
    color: red;
    height: 1rem;
    margin-right: 6px;
  }
`;
