import styled from "styled-components";

const Select = styled.select`
  display: block;
  width: 100%;
  height: 2.5rem;
  line-height: 1.25rem;
  background: #f2f5f8;
  color: #152935;
  font-size: 0.875rem;
  font-family: "Roboto";
  letter-spacing: 0.25px;
  font-weight: 400;
  outline: 0px;
  border: 1px solid #c9d9e8;

  &pading-left {
    padding-left: -0.25rem;
  }

  &:hover {
    outline: 0px;
    border: 1px solid #005075;
    box-shadow: 0 0 7px rgba(0, 144, 209, 1);
  }

  &:active {
    border: 1px solid #0090d1;
    outline: 0px;
  }

  &:disabled {
    background: #fafbfd;
    cursor: not-allowed;
    border: 1px solid #c1c1c4 !important;
    color: #9da6ad;
  }
`;

export default Select;
