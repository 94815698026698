import React, { useEffect, useMemo, useState } from "react";
import {
  Wrapper,
  ButtonContainer,
  UploadDocumentsContainer,
  UploadedDocuments,
  DocumentCard,
  DocumentCardText,
  ToggleContainer,
  ToggleCard,
  ContentContainer,
  PageTitle,
  PageDescription,
  UploadDocumentTitle,
  UploadDocumentDescription,
  ToggleCardRow,
  ToggleAbbreviation,
  ToggleName,
  ToggleDescription,
  SignatureRow,
  SignatureTypeName,
  SignatureTypeAbbreviation,
  DocumentCardContainer,
  ErrorCard,
} from "./style";
import Button from "../../../../components/button";
import { useNavigate } from "react-router-dom";
import FileUploader from "../../../../components/fileUploader/index";
import {
  faTrashCan,
  faCircleInfo,
  faMinusCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Input from "../../../../components/input";
import { TooltipWrapper } from "../../../../components/tooltip";
import { signatureTypesToMap } from "./signatureTypesToMap";
import { uploadDocuments } from "../../../../api/backofficeAPI";
import { fileToBase64 } from "../../../../utils/functions";

import Loading from "../../../../components/loading";
import { useToast } from "../../../../context/toastNotificationContext";
import InputError from "../../../../components/inputError";

function Documents(props) {
  const {
    supportedSignatures,
    formSteps,
    handleStorageStep,
    handleToPreviousStep,
  } = props;

  const navigate = useNavigate();
  const { showToast } = useToast();
  const [files, setFiles] = useState([]);
  const [processedFiles, setProcessedFiles] = useState([]);
  const [failedDocuments, setFailedDocuments] = useState([]);
  const [loading, setLoading] = useState(false);
  const signatureTypes = useMemo(() => {
    return supportedSignatures.filter((item) => item !== "OnlyIdentify");
  }, [supportedSignatures]);
  const [selectedSignature, setSelectedSignature] = useState(signatureTypes[0]);
  const sessionId = localStorage.getItem("sessionId");
  const totalFilesSize = files.reduce((acc, file) => acc + file.size, 0);
  const hasMultipleSigninTypes = signatureTypes?.length > 1;

  useEffect(() => {
    files.length && processFiles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [files.length]);

  const validateDocuments = (documents) => {
    const failed = documents
      .filter((doc) => !doc.success)
      .map((doc) => doc.fileName);

    if (failed.length > 0) {
      setFailedDocuments(failed);
      return false;
    }
    return true;
  };

  const handleSelectSignature = (value) => {
    setSelectedSignature(value);
  };

  const handleFilesAccepted = (newFiles) => {
    setFiles((prevFiles) => [...prevFiles, ...newFiles]);
  };

  const handleDeleteFile = (index) => {
    const fileName = files[index].name;
    setFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
    setFailedDocuments((prev) => prev.filter((name) => name !== fileName));
  };

  const processFiles = async () => {
    const processed = await Promise.all(
      files.map(async (file, index) => {
        const base64 = await fileToBase64(file);
        return {
          index,
          documentBase64: base64.split(",")[1],
          fileName: file.name,
        };
      })
    );

    setProcessedFiles(processed);
  };

  const handleSubmit = () => {
    setLoading(true);
    uploadDocuments(sessionId, processedFiles)
      .then((response) => {
        if (response.status === 200) {
          if (validateDocuments(response.data)) {
            handleStorageStep(formSteps[5]);
            navigate("/create-session/documents-assign");
            localStorage.setItem("documentIndex", 0);
            localStorage.setItem("signatureType", selectedSignature);
          }
        } else {
          showToast(response?.data?.Message || "Qualcosa e andato storto", {
            type: "error",
            duration: 5000,
          });
        }
      })
      .catch((err) => {
        showToast(err?.response?.message || "Qualcosa e andato storto", {
          type: "error",
          duration: 5000,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Wrapper>
      <ContentContainer>
        <PageTitle>Carica i documenti</PageTitle>
        <PageDescription>
          Qui puoi caricare i documenti da inviare ai soggetti. Se procedi senza
          documenti, la sessione sarà di sola identificazione dei soggetti.
        </PageDescription>
        {hasMultipleSigninTypes ? (
          <>
            <UploadDocumentTitle margin>
              Tipologia di firma *
            </UploadDocumentTitle>
            <ToggleContainer>
              {signatureTypes?.map((item, index) => {
                return (
                  <ToggleCard
                    selected={item === selectedSignature}
                    margin={signatureTypes.length !== index + 1}
                    key={index}
                    onClick={() => handleSelectSignature(item)}
                  >
                    <ToggleCardRow>
                      <ToggleAbbreviation>
                        {signatureTypesToMap[item]?.abbreviation}
                      </ToggleAbbreviation>
                      <Input
                        type="radio"
                        readOnly
                        checked={item === selectedSignature}
                      />
                    </ToggleCardRow>
                    <ToggleName>{signatureTypesToMap[item]?.name}</ToggleName>

                    <ToggleDescription>
                      {signatureTypesToMap[item]?.description}
                      <TooltipWrapper
                        title={signatureTypesToMap[item]?.info}
                        placement="top"
                      >
                        <span>
                          <FontAwesomeIcon
                            icon={faCircleInfo}
                            className="info-icon"
                          />
                        </span>
                      </TooltipWrapper>
                    </ToggleDescription>
                  </ToggleCard>
                );
              })}
            </ToggleContainer>
          </>
        ) : (
          <SignatureRow>
            <SignatureTypeName>
              {signatureTypesToMap[signatureTypes]?.fullName}&nbsp;
            </SignatureTypeName>
            <SignatureTypeAbbreviation>
              {`(${signatureTypesToMap[signatureTypes]?.abbreviation})`}
            </SignatureTypeAbbreviation>
            <TooltipWrapper
              title={signatureTypesToMap[signatureTypes]?.info}
              placement="right"
            >
              <span>
                <FontAwesomeIcon icon={faCircleInfo} className="info-icon" />
              </span>
            </TooltipWrapper>
          </SignatureRow>
        )}
        <UploadDocumentsContainer>
          <UploadDocumentTitle>Carica file *</UploadDocumentTitle>
          <UploadDocumentDescription>
            La dimensione complessiva dei file non deve superare i 15Mb
          </UploadDocumentDescription>
          {failedDocuments.length > 0 && (
            <ErrorCard>
              <FontAwesomeIcon icon={faMinusCircle} className="error-icon" />
              Per procedere, elimina i documenti senza campi da firmare.
            </ErrorCard>
          )}
          <UploadedDocuments>
            {files?.map((file, index) => (
              <DocumentCardContainer key={index}>
                <DocumentCard error={failedDocuments.includes(file.name)}>
                  <DocumentCardText bold>{file.name}</DocumentCardText>
                  <DocumentCardText>
                    {(file.size / 1024 / 1024).toFixed(2)} MB
                  </DocumentCardText>
                  <FontAwesomeIcon
                    icon={faTrashCan}
                    className="icon-delete"
                    onClick={() => handleDeleteFile(index)}
                  />
                </DocumentCard>
                {failedDocuments.includes(file.name) && (
                  <InputError message="Questo documento non contiene campi da firmare" />
                )}
              </DocumentCardContainer>
            ))}
          </UploadedDocuments>
          <FileUploader
            onFilesAccepted={handleFilesAccepted}
            existingFilesSize={totalFilesSize}
          />
        </UploadDocumentsContainer>
      </ContentContainer>
      <ButtonContainer>
        <Button ghost margin8 onClick={handleToPreviousStep}>
          Indietro
        </Button>
        <Button
          primaryLarge
          onClick={handleSubmit}
          disabled={files.length === 0 || loading}
        >
          {loading ? <Loading small /> : "Avanti"}
        </Button>
      </ButtonContainer>
    </Wrapper>
  );
}

export default Documents;
