import React from "react";
import {
  BrowserRouter,
  Routes,
  Route,
  Outlet,
  Navigate,
} from "react-router-dom";
import LoginPage from "./backoffice/pages/login";
import BackOfficePage from "./backoffice/pages/backoffice";
import ForgotPassword from "./backoffice/pages/forgotPassword";
import ResetPassword from "./backoffice/pages/resetPassword";
import CreateSession from "./backoffice/pages/createSession";
import Sign from "./portal/sign";
import Summary from "./portal/summary";
import { UserProvider } from "./context/userContext";
import { BackofficeProvider } from "./context/backofficeContext";
import { ToastProvider } from "./context/toastNotificationContext";
import { NotificationProvider } from "./context/notificationContext";

const PrivateRoute = ({ children }) => {
  const userLoggedIn = localStorage.getItem("myUser");
  return userLoggedIn ? children : <Navigate to="/login" />;
};

function Routing() {
  return (
    <BrowserRouter>
      <UserProvider>
        <BackofficeProvider>
          <ToastProvider>
            <NotificationProvider>
              <Routes>
                {/* Authentication */}
                <Route path={"/login"} element={<LoginPage />}></Route>
                <Route path="/forgot-password" element={<ForgotPassword />} />
                <Route
                  path="/reset-password/:token"
                  element={<ResetPassword />}
                />
                {/* Dashboard */}
                <Route
                  path="/"
                  element={
                    <PrivateRoute>
                      <BackOfficePage />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/create-session/:step"
                  element={<CreateSession />}
                />
                {/* Portal */}
                <Route
                  path={"/sign/data1=:key1/data2=:key2/data3=:key3"}
                  element={<Sign />}
                ></Route>              
                <Route
                  path={"/summary/data1=:key1/data2=:key2/data3=:key3"}
                  element={<Summary />}
                ></Route>
                <Route path={"/summary"} element={<Summary />}></Route>
                {/* Outlet */}
                <Route path="/*" element={<Outlet />} />
              </Routes>
            </NotificationProvider>
          </ToastProvider>
        </BackofficeProvider>
      </UserProvider>
    </BrowserRouter>
  );
}

export default Routing;
