import styled from "styled-components";

export const BreadcrumbContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 32px 0 48px 0;
  user-select: none;
`;

export const StepContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  /* margin: 0 32px; */
`;

export const CircleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const Divider = styled.div`
  width: 80px;
  height: 2px;
  background-color: #b9c8d0;
`;

export const Circle = styled.div`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  border: 2px solid;
  border-color: ${(props) =>
    props.isPrevious ? "#008BCC" : props.isActive ? "#008BCC" : "#B9C8D0"};
  background: ${(props) =>
    props.isPrevious
      ? "white"
      : props.isActive
      ? "linear-gradient(90deg, #00BFE6 29.58%, #0090D1 100%)"
      : "#B9C8D0"};
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  position: relative;
  box-sizing: border-box;

  .icon {
    color: #324148;
  }
`;

export const StepText = styled.div`
  text-align: center;
  font-weight: ${(props) => (props.isActive ? "600" : "400")};
  position: absolute;
  top: 32px;
  color: #004e75;
  font-weight: 500;
  font-size: 14px;
`;
