import React, { useState } from "react";
import logo from "../../../assets/images/tsLogoColor.png";
import Input from "../../../components/input";
import Button from "../../../components/button";
import { Link, useParams } from "react-router-dom";
import { resetPassword } from "../../../api/backofficeAPI";
import Loading from "../../../components/loading";
import { TooltipIcon, TooltipWrapper } from "../../../components/tooltip";
import { Card, Wrapper } from "./style";

function ResetPassword() {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [passwordSuccess, setPasswordSuccess] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const [linkToForgotPassword, setLinkToForgotPassword] = useState(false);
  const [isLaoding, setIsLoading] = useState(false);
  const { token } = useParams();

  let Validated = false;

  const onValidate = () => {
    if (password !== confirmPassword) {
      setConfirmPasswordError("Le due password non corrispondono");
      Validated = false;
    } else {
      setConfirmPasswordError("");
      Validated = true;
    }
  };

  const handleSubmit = () => {
    const body = {
      newPassword: password,
      token: token,
    };

    setPasswordSuccess("");
    setConfirmPasswordError("");
    setPasswordError("");
    onValidate();

    if (Validated) {
      setIsLoading(true);

      resetPassword(body).then((response) => {
        if (response.status === 200) {
          setPasswordSuccess("La password e stata cambiata con sucesso");
          setIsLoading(false);
        } else if (response.status === 400) {
          setPasswordError(response.data.Message);
          setIsLoading(false);
        } else if (response.status === 404) {
          setPasswordError(
            "La sessione del cambio password e scadutta, richiedi una nuova mail per aprire un altra sessione."
          );
          setLinkToForgotPassword(true);
          setIsLoading(false);
        } else {
          setPasswordError("Non e stato possibile reimpostare la password");
          setIsLoading(false);
        }
      });
    }
  };

  return (
    <Wrapper>
      <Card>
        <div>
          <img className={"logo"} src={logo} alt={"ts-logo"} />
        </div>

        {!passwordSuccess && !isLaoding && (
          <>
            <div className="title-container">
              <div className="text">Reimposta la tua password</div>

              <TooltipWrapper
                title="La password non rispetta i criteri di sicurezza, deve contenere almeno 8 caratteri, una lettera in maiuscolo, una in minuscolo, un numero e un carattere speciale tra %#$_@!"
                placement="top"
              >
                <div className="info-icon">
                  <TooltipIcon />
                </div>
              </TooltipWrapper>
            </div>

            <Input
              className={`password-input `}
              id="password"
              name="password"
              type="password"
              placeholder="Nuovo password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            {passwordError && (
              <div className="error-message">{passwordError}</div>
            )}
            <Input
              className={`password-input margin-top`}
              id="password"
              name="password"
              type="password"
              placeholder="Conferma password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
            {<div className="succes-message"></div>}
            {confirmPasswordError && (
              <div className="error-message">{confirmPasswordError}</div>
            )}
            <Button
              primary
              onClick={handleSubmit}
              className="login-form-button"
            >
              Invia nuova password
            </Button>
          </>
        )}

        {isLaoding && (
          <div className="loading-container">
            <Loading small />
          </div>
        )}

        {passwordSuccess && !isLaoding && (
          <div className="success-container">
            <p>{passwordSuccess}</p>
            <Link className="login-link" to={"/login"}>
              Login
            </Link>
          </div>
        )}

        {linkToForgotPassword && !isLaoding && (
          <Link className="login-link margin-top" to={"/forgot-password"}>
            Richiedi nuova mail
          </Link>
        )}
      </Card>
    </Wrapper>
  );
}

export default ResetPassword;

