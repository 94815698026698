import styled from "styled-components";

export const NavbarContainer = styled.div`
  background-color: #002333;
  height: 48px;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px;
  user-select: none;
  z-index: 2;
`;

export const Title = styled.h5`
  color: white;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
`;

export const ProfileContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const ProfileImage = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 2px solid #0090d1;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  color: #005075;
  font-weight: 400;
  background-color: white;
`;

export const VersionContainer = styled.span`
  color: white;
  font-size: 14px;
  font-weight: 400;
  margin-left: 16px;
`;

export const SidebarActionContainer = styled.span`
  margin-right: 16px;
  cursor: pointer;
`;
