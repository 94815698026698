import React from "react";
import Input from "../../../../components/input";
import Select from "../../../../components/select";

export const SessioniForm = (props) => {
  return (
    <div>
      <div className="drawer-title border-none margin-top">
        Configurazione Sessioni
      </div>
      <div className="input-container">
        <label className="form-label" htmlFor="tenant">
          Cancellazione automatica dopo n giorni
        </label>
        <Input
          max="180"
          min="1"
          id="maxSessionLength"
          name="maxSessionLength"
          type="number"
          placeholder="Cancellazione"
          onChange={(e) => props.setMaxSessionLength(e.target.value)}
          value={props.maxSessionLength}
        />
        {props.getError("MaxSessionLength") && (
          <div className="error-message">
            {props.getError("MaxSessionLength").message}
          </div>
        )}
      </div>
      <div className="input-container">
        <label className="form-label" htmlFor="tenant">
          Timeout sessione dopo n giorni
        </label>
        <Input
          max={`${props.maxSessionLength - 1}`}
          min="1"
          id="sessionTimeout"
          name="sessionTimeout"
          type="number"
          placeholder="sessionTimeout"
          onChange={(e) => props.setSessionTimeout(e.target.value)}
          value={props.sessionTimeout}
        />
        {props.getError("SessionTimeout") && (
          <div className="error-message">
            {props.getError("SessionTimeout").message}
          </div>
        )}
      </div>
      <div className="input-container">
        <label className="form-label" htmlFor="tenant">
          Soglia Timeout n giorni prima
        </label>
        <Input
          max={`${props.sessionTimeout - 1}`}
          min="5"
          id="alertBeforeTimeout"
          name="alertBeforeTimeout"
          type="number"
          placeholder="alertBeforeTimeout"
          onChange={(e) => props.setAlertBeforeTimeout(e.target.value)}
          value={props.alertBeforeTimeout}
        />
        {props.getError("AlertBeforeTimeout") && (
          <div className="error-message">
            {props.getError("AlertBeforeTimeout").message}
          </div>
        )}
      </div>
      <div className="input-container">
        <label className="form-label" htmlFor="tenant">
          Sessioni per pagina
        </label>
        <Select
          defaultValue={props.sessionsPerPage}
          onChange={(e) => props.setSessionsPerPage(e.target.value)}
          name="sessioni"
        >
          <option value="10">10</option>
          <option value="20">20</option>
          <option value="50">50</option>
          <option value="100">100</option>
        </Select>
      </div>
    </div>
  );
};
