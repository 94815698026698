import axios from "axios";

const BASEURL = process.env.REACT_APP_API_KEY;

//start the signature procedure on IDSign
export const startSign = async (token, key1, key2) => {
  const headers = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  const body = { sessionId: key1, signatoryId: key2 };
  try {
    const response = await axios.post(
      `${BASEURL}Sign/startsign`,
      body,
      headers
    );
    return response;
  } catch (err) {
    return err.response;
  }
};

export const getSummaryInfo = async (token, key1, key2, key3) => {
  const headers = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  const body = { sessionId: key1, signatoryId: key2, isReturn: key3 };
  try {
    const response = await axios.post(
      `${BASEURL}SessionManager/summaryinfo`,
      body,
      headers
    );
    return response;
  } catch (err) {
    return err.response;
  }
};
