import styled, { css } from "styled-components";

export const DocumentContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 32px;
  user-select: none;
`;

const hideScrollbar = css`
  scrollbar-width: thin;
  scrollbar-color: transparent transparent;

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const Pdf = styled.div`
  width: 644px;
  height: 826px;
  background-color: #f2f5f8;
  padding: 0 1rem;
  display: flex;
  justify-content: center;
  border: 1px solid #c9d9e8;
  overflow: auto;
  position: relative;
  scrollbar-width: thin;
  scrollbar-color: transparent transparent;

  ${hideScrollbar}
`;

export const AcrofieldHighlight = styled.div`
  position: absolute;
  left: ${({ x }) => x}px;
  bottom: ${({ y }) => y}px;
  width: ${({ w }) => w}px;
  height: ${({ h }) => h}px;
  border: 2px solid
    ${({ isActive, isFilled }) => (isActive ? "#008bce" : "transparent")};
  box-shadow: 0px 0px 8px
    ${({ isActive, isFilled }) => (isActive ? "#008bce" : "transparent")};
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
  color: black;
  font-size: 14px;
`;

export const FiledRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 8px;

  .check-icon {
    color: #09822a;
    width: 14px;
    height: 14px;
    margin-left: 8px;
  }
`;

export const PdfFooter = styled.div`
  width: 100%;
  height: 2rem;
  background-color: white;
  border: 1px solid #c9d9e8;
  border-top: none;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Icon = {
  marginLeft: "0.5rem",
  color: "#0090d1",
  cursor: "pointer",
};

export const DocumentIcon = {
  color: "#0090d1",
  cursor: "pointer",
  margin: "0 0.5rem",
};

export const ZoomIndicator = styled.div`
  background-color: #f2f5f8;
  height: 100%;
  padding: 0 0.5rem;
  display: flex;
  align-items: center;
`;
