import React from 'react'
import styled, { css } from 'styled-components';

function Toggler(props) {
  const handleToggle = () => {
    if (!props.disabled) {
      props.setIsChecked(!props.checked);
    }
  };

  return (
    <SwitchContainer 
      margin={props.hasMargin} 
      isOn={props.checked} 
      onClick={handleToggle} 
      disabled={props.disabled}
    >
      <SwitchToggle isOn={props.checked} />
    </SwitchContainer>
  )
}

export default Toggler

const SwitchContainer = styled.div`
  display: inline-block;
  width: 40px;
  height: 20px;
  background-color: ${(props) => (props.isOn ? "#0090D1" : "#cccccc")};
  border-radius: 34px;
  position: relative;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  ${(props) => props.disabled && css`
    opacity: 0.5;
  `}
  margin-right: ${(props) => (props.margin ? "0.5rem" : "0")};
`;

const SwitchToggle = styled.div`
  width: 16px;
  height: 16px;
  background-color: #fff;
  border-radius: 30px;
  position: absolute;
  top: 2px;
  left: ${(props) => (props.isOn ? "22px" : "2px")};
  transition: all 0.2s ease-in-out;
`;