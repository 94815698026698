import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  min-height: calc(100vh - 288px);
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
`;

export const DocumentSwitchContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  font-weight: 500;
  font-size: 14px;
  color: #008fd6;
  margin: 16px 0;
  user-select: none;
`;

export const BackSwitch = styled.span`
  margin-right: 24px;
  cursor: ${(props) => (props.disabled ? "normal" : "pointer")};
  color: ${(props) => (props.disabled ? "#98AAB3" : "#008fd6")};
`;

export const ForwardSwitch = styled.span`
  margin-left: 24px;
  cursor: ${(props) => (props.disabled ? "normal" : "pointer")};
  color: ${(props) => (props.disabled ? "#98AAB3" : "#008fd6")};
`;

export const DocumentContent = styled.div`
  display: flex;
  flex-direction: row;
`;

export const Form = styled.div`
  width: 410px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .title-check-icon {
    color: #09822a;
    width: 32px;
    height: 32px;
    margin-bottom: 16px;
  }
`;

export const FormTitle = styled.div`
  margin-bottom: 8px;
  width: 100%;
  color: black;
  font-weight: 400;
  user-select: none;
`;

export const AcrofieldsListContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 24px;
  width: 100%;
  user-select: none;
`;

export const AccrofiledContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const AccrofieldHeader = styled.div`
  height: 32px;
  width: 100%;
  background-color: ${(props) => (props.Active ? "#DBF3FF" : "transparent")};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 8px;
  cursor: pointer;
  margin-bottom: 8px;
`;

export const AccrofieldNameContainer = styled.div`
  font-size: 14px;
  font-weight: 400;
  color: black;
  width: 180px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 16px;

  .arrow-icon {
    margin-right: 8px;
    margin-top: 1px;
  }
`;

export const SignerNameContainer = styled.div`
  font-size: 14px;
  font-weight: 400;
  color: #566b76;
  display: flex;
  flex-direction: row;
  line-height: 16px;

  .check-icon {
    color: #09822a;
    width: 14px;
    height: 14px;
    margin-left: 8px;
  }
`;

export const SingerName = styled.div`
  width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: end;
`;

export const SelectContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
`;

export const Label = styled.label`
  font-size: 14px;
  margin-bottom: 4px;
`;

export const AccrofieldBody = styled.div`
  width: 300px;
`;

export const AcrofieldsListItemTextSpan = styled.span;

export const LoadingContainer = styled.div`
  width: 100%;
  min-height: calc(100vh - 288px);
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ErrorContainer = styled.div`
  width: 100%;
  min-height: calc(100vh - 288px);
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ButtonContainer = styled.div`
  border-top: 2px solid #c9d9e8;
  padding: 0 16px;
  width: 100%;
  height: 72px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: end;
  margin-top: 32px;
`;
