import {
  faEllipsisVertical,
  faMagnifyingGlass,
  faSort,
  faSortDown,
  faSortUp,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import styled from "styled-components";
import { DetailedTooltip } from "../../../../components/detailedTooltip";
import Input from "../../../../components/input";
import Select from "../../../../components/select";
import { dateFormatter } from "../../../../utils/functions";
import TooltipData from "./TooltipData";

function SessionsTable(props) {
  return (
    <Table>
      <Thead>
        <Tr className="tr-head">
          <Th className="th-cell th-cell-1"></Th>
          <Th
            className="th-cell th-cell-2"
            onClick={(e) => props.handleSorting("creationDate")}
          >
            <span>Data creazione&nbsp;</span>
            {props.sorting.sortCreationDate ===
              props.SortingDirection.Descending && (
              <FontAwesomeIcon icon={faSortUp} />
            )}
            {props.sorting.sortCreationDate ===
              props.SortingDirection.Ascending && (
              <FontAwesomeIcon icon={faSortDown} />
            )}
            {props.sorting.sortCreationDate === "" && (
              <FontAwesomeIcon icon={faSort} />
            )}
          </Th>
          <Th className="th-cell th-cell-3">Tipo di firma</Th>
          {window.innerWidth > 1600 && (
            <Th className="th-cell th-cell-4">Nr.doc</Th>
          )}
          {window.innerWidth > 1600 && (
            <Th className="th-cell th-cell-5">Nr.firm</Th>
          )}
          <Th
            className="th-cell th-cell-7"
            onClick={(e) => props.handleSorting("tag")}
          >
            <span>Tag&nbsp;</span>
            {props.sorting.sortTag === props.SortingDirection.Descending && (
              <FontAwesomeIcon icon={faSortUp} />
            )}
            {props.sorting.sortTag === props.SortingDirection.Ascending && (
              <FontAwesomeIcon icon={faSortDown} />
            )}
            {props.sorting.sortTag === "" && <FontAwesomeIcon icon={faSort} />}
          </Th>
          <Th
            className="th-cell th-cell-7"
            onClick={(e) => props.handleSorting("description")}
          >
            <span>Descrizione&nbsp;</span>
            {props.sorting.sortDescription ===
              props.SortingDirection.Descending && (
              <FontAwesomeIcon icon={faSortUp} />
            )}
            {props.sorting.sortDescription ===
              props.SortingDirection.Ascending && (
              <FontAwesomeIcon icon={faSortDown} />
            )}
            {props.sorting.sortDescription === "" && (
              <FontAwesomeIcon icon={faSort} />
            )}
          </Th>
          <Th className="th-cell th-cell-8">
            <span>Stato&nbsp;</span>
            <div onClick={(e) => props.handleSorting("sessionsState")}>
              {props.sorting.sortSessionsState ===
                props.SortingDirection.Descending && (
                <FontAwesomeIcon icon={faSortUp} />
              )}
              {props.sorting.sortSessionsState ===
                props.SortingDirection.Ascending && (
                <FontAwesomeIcon icon={faSortDown} />
              )}
              {props.sorting.sortSessionsState === "" && (
                <FontAwesomeIcon icon={faSort} />
              )}
            </div>

            <DetailedTooltip
              left
              title="Informazione su gli stati della sessione"
              children={<TooltipData />}
            ></DetailedTooltip>
          </Th>

          <Th
            className="th-cell th-cell-9"
            onClick={(e) => props.handleSorting("expirationDate")}
          >
            <span>Data di scadenza&nbsp;</span>
            {props.sorting.sortExpirationDate ===
              props.SortingDirection.Descending && (
              <FontAwesomeIcon icon={faSortUp} />
            )}
            {props.sorting.sortExpirationDate ===
              props.SortingDirection.Ascending && (
              <FontAwesomeIcon icon={faSortDown} />
            )}
            {props.sorting.sortExpirationDate === "" && (
              <FontAwesomeIcon icon={faSort} />
            )}
          </Th>
          <Th className="th-cell th-cell-10"></Th>
        </Tr>
        <Tr className="tr-head">
          <Th className="th-cell color-grey padding-left-025">
            <Input
              type="checkbox"
              className="checkbox"
              onChange={props.onSelectAllSessions}
            />
          </Th>
          <Th className="th-cell color-grey"></Th>
          <Th className="th-cell color-grey"></Th>
          {window.innerWidth > 1600 && <Th className="th-cell color-grey"></Th>}
          {window.innerWidth > 1600 && <Th className="th-cell color-grey"></Th>}
          <Th className="th-cell color-grey position-relative">
            <Input
              type="text"
              id="tag"
              className="table-search"
              value={props.tag}
              placeholder="Cerca"
              onChange={(e) => props.setTag(e.target.value)}
            />
            <FontAwesomeIcon className="search-icon" icon={faMagnifyingGlass} />
          </Th>
          <Th className="th-cell color-grey position-relative">
            <Input
              type="text"
              id="Description"
              value={props.description}
              placeholder="Cerca"
              className="table-search"
              onChange={(e) => props.setDescription(e.target.value)}
            />
            <FontAwesomeIcon className="search-icon" icon={faMagnifyingGlass} />
          </Th>
          <Th className="th-cell color-grey">
            <Select
              className="table-select"
              name="status"
              onChange={(e) => props.setSessionState(e.target.value)}
              value={props.sessionState}
            >
              <option value="">Default</option>
              <option value="Closed">Closed</option>
              <option value="Failed">Failed</option>
              <option value="Open">Open</option>
              <option value="Ready">Ready</option>
            </Select>
          </Th>
          <Th className="th-cell color-grey"></Th>
          <Th className="th-cell color-grey"></Th>
        </Tr>
      </Thead>

      <Tbody>
        {!props.isLoading &&
          !props.requestError &&
          props.sessionsList.length > 0 &&
          props.sessionsList.map((session, index) => {
            return (
              <Tr key={index} className={"tr-body"}>
                <Td>
                  <Input
                    className="checkbox"
                    type="checkbox"
                    value={session.idSession}
                    checked={props.selectedSessions.includes(session.idSession)}
                    onChange={(e) => {
                      if (e.target.checked) {
                        props.setSelectedSessions([
                          ...props.selectedSessions,
                          session.idSession,
                        ]);
                      } else {
                        props.setSelectedSessions(
                          props.selectedSessions.filter(
                            (id) => id !== session.idSession
                          )
                        );
                      }
                    }}
                  />
                </Td>
                <Td>{dateFormatter(session.creationDate)}</Td>
                <Td>{session.signatureType}</Td>
                {window.innerWidth > 1600 && <Td>{session.documentsCount}</Td>}
                {window.innerWidth > 1600 && (
                  <Td>{session.signatoriesCount}</Td>
                )}
                <Td>{session.tag}</Td>
                <Td>{session.description}</Td>
                <Td className="row-cell">
                  <div className="status-label">{session.status}</div>

                  {session.status === "Ready" && (
                    <div className="status-icon status-icon-color-green" />
                  )}
                  {session.status === "Closed" && (
                    <div className="status-icon status-icon-color-grey" />
                  )}
                  {session.status === "Failed" && (
                    <div className="status-icon status-icon-color-red" />
                  )}
                  {session.status === "Open" && (
                    <div className="status-icon status-icon-color-yellow" />
                  )}
                </Td>
                <Td>{dateFormatter(session.expirationDate)}</Td>
                <Td>
                  <div
                    className="icon-container"
                    onClick={() => props.handleClick(session.idSession)}
                  >
                    <FontAwesomeIcon
                      className="icon"
                      icon={faEllipsisVertical}
                    />
                  </div>
                </Td>
              </Tr>
            );
          })}
      </Tbody>
    </Table>
  );
}

export default SessionsTable;

const Table = styled.table`
  width: 100%;
  margin-top: 2rem;
  border-collapse: collapse;

  .tr-body {
    border: 1px solid transparent;
    border-bottom: solid 1px rgb(157, 166, 173);

    &:hover {
      border: none;
      border: 1px solid #0090d1;
    }
  }
`;
const Thead = styled.thead`
  width: 100%;
`;

const Tbody = styled.tbody``;

const Tr = styled.tr`
  width: 100%;

  .th-cell-1 {
    width: 50px;
  }
  .th-cell-2 {
  }
  .th-cell-3 {
  }
  .th-cell-4 {
    width: 70px;
  }
  .th-cell-5 {
    width: 70px;
  }
  .th-cell-6 {
    min-width: 150px;
    max-width: 250px;
  }
  .th-cell-7 {
    min-width: 130px;
  }
  .th-cell-8 {
    min-width: 100px;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .th-cell-9 {
  }
  .th-cell-10 {
    width: 50px;
  }

  .status-label {
    width: 48px;
  }

  .row-cell {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .color-grey {
    background-color: #f2f5f8;
  }
  .table-search {
    border: none;
    width: 98.5%;
  }

  .table-select {
    border: none;
    cursor: pointer;
  }

  .status-icon {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin-bottom: 2px;
    margin-left: 8px;
  }

  .status-icon-color-green {
    background-color: rgb(9, 130, 42);
  }
  .status-icon-color-red {
    background-color: rgb(216, 40, 41);
  }

  .position-relative {
    position: relative;
  }

  .search-icon {
    position: absolute;
    right: 1rem;
    top: 0.8rem;
    color: #0090d1;
  }

  .status-icon-color-grey {
    background-color: rgb(221, 225, 228);
  }
  .status-icon-color-yellow {
    background-color: rgb(253, 185, 39);
  }

  .icon-container {
    width: 30px;
    height: 30px;
    background-color: transparent;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .icon-container:hover {
    background-color: #f2f5f8;
    cursor: pointer;
  }

  .icon {
    color: #0090d1;
  }

  .checkbox {
    margin-left: 0.5rem;
  }

  .padding-left-025 {
    padding-left: 0.25rem;
  }
`;

const Th = styled.th`
  color: #005075;
  font-weight: 500;
  height: 2.5rem;
  cursor: pointer;
  text-align: left;
  padding-right: 0.5rem;
  padding-left: 0.25rem;
`;

const Td = styled.td`
  color: #152935;
  font-size: 0.875rem;
  font-weight: 500;
  padding-left: 0.25rem;
  padding-right: 0.5rem;
  height: 2.5rem;
`;
