import React from "react";
import { dateFormatter } from "../../../../utils/functions";
import styled from "styled-components";

export const LogTable = (props) => {
  return (
    <Table>
      <Thead>
        <Tr>
          <Th className="th-1"></Th>
          <Th className="th-2">Data</Th>
          <Th className="th-3">Stato</Th>
          <Th className="th-4">Descrizione</Th>
        </Tr>
      </Thead>
      <Tbody>
        {props.logSession?.map((item, index) => {
          return (
            <Tr key={index}>
              <Td className="td-1"></Td>
              <Td className="td-2">{dateFormatter(item.timeStamp)}</Td>
              <Td className="td-3">{item.description}</Td>
              <Td className="td-4">{item.extendedDescription }</Td>
            </Tr>
          );
        })}
      </Tbody>
    </Table>
  );
};



export const Table = styled.table`
  border-collapse: collapse;
  width: fit-content;
  table-layout: fixed;
  margin-left: 1rem;
  margin-right: 1rem;
  margin-top: 2rem;
`;

export const Thead = styled.thead``;
export const Tbody = styled.tbody``;

export const Tr = styled.tr`
  text-align: left;
  border-bottom: solid 1px rgb(157, 166, 173);

  .th-1 {
    width: 40px;
  }
  .th-2 {
    width: 250px;
  }
  .th-3 {
    width: 250px;
  }
  .th-4 {
    width: 310px;
  }

  .td-1 {
    width: 40px;
  }
  .td-2 {
    width: 250px;
  }
  .td-3 {
    width: 250px;
  }
  .td-4 {
    width: 310px;
  }

  .chevron {
    width: 30px;
    color: #152935;
    cursor: pointer;
  }
`;

export const Th = styled.th`
  background-color: #f2f5f8;
  font-style: Medium;
  color: #005075;
  line-height: 1rem;
  font-weight: 500;
  height: 40px;
`;

export const Td = styled.td`
  height: 40px;
  font-size: 0.875rem;
  color: #152935;
  font-weight: 400;
`;