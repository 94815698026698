import React from "react";
import styled from "styled-components";
import Button from "../button";

function EliminationModal(props) {
  const modalRef = React.useRef(null);

  React.useEffect(() => {
    const closeModal = (e) => {
      if (
        modalRef.current &&
        props.isModalOpen &&
        !modalRef.current.contains(e.target)
      ) {
        props.setIsModalOpen(false);
      }
    };
    document.addEventListener("mousedown", closeModal);
  }, [props]);
  if (!props.isModalOpen) return null;

  return (
    <ModalOverlay>
      <Card ref={modalRef}>
        <div className="upper-panel">{props.title}</div>
        <div className="midle-panel">{props.text}</div>
        <div className="lower-panel">
          <Button onClick={props.onAnulla} secondaryBlue>
            Annulla
          </Button>
          <Button onClick={props.onElimina} primaryLight margin8>
            Elimina
          </Button>
        </div>
      </Card>
    </ModalOverlay>
  );
}

export default EliminationModal;

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 6;
  background-color: rgba(0, 0, 0, 0.7);
`;

const Card = styled.div`
  width: 400px;
  height: 220px;
  background-color: white;
  position: fixed;
  z-index: 7;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  .upper-panel {
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: solid 2px #d82829;
    width: 100%;
    height: 70px;
    font-family: "Cairo";
    font-size: 1.5rem;
    font-weight: 600;
    color: #005075;
    background-color: white;
  }

  .midle-panel {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 84px;
    font-family: "Roboto";
    font-size: 0.875rem;
    font-weight: 400;
    color: #152935;
    background-color: white;
    padding: 0 0.7rem;
    text-align: center;
  }

  .lower-panel {
    display: flex;
    align-items: center;
    justify-content: end;
    background-color: #f2f5f8;
    width: 100%;
    height: 64px;
  }
`;
