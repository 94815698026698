import React from "react";
import { Wrapper, TitleContaier, Content, ButtonContaier } from "./style";
import Button from "../../../../components/button";

function EliminationModal(props) {
  const { onClose, onConfirm, title, description } = props;

  return (
    <Wrapper>
      <TitleContaier>{title}</TitleContaier>
      <Content>{description}</Content>
      <ButtonContaier>
        <Button secondaryBlue onClick={onClose}>
          Annulla
        </Button>
        <Button onClick={onConfirm} primaryLight margin8>
          Elimina
        </Button>
      </ButtonContaier>
    </Wrapper>
  );
}

export default EliminationModal;
