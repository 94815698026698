import { faFileLines } from "@fortawesome/free-solid-svg-icons";

export const adminLinks = [
  {
    id: 0,
    icon: faFileLines,
    name: "Gestione Tenant",
    url: "/",
  },
];
export const tenantLinks = [
  {
    id: 0,
    icon: faFileLines,
    name: "Gestione Utenti",
    url: "/",
  },
];
export const userLinks = [
  {
    id: 0,
    icon: faFileLines,
    name: "Lista Pratiche",
    url: "/",
  },
];
