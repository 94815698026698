import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import Button from "../../../../components/button";
import Input from "../../../../components/input";
import Select from "../../../../components/select";
import { faTriangleExclamation } from "@fortawesome/free-solid-svg-icons";
import { faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  resendDocuments,
  resendEmailToSignatory,
  updateSignatoryData,
} from "../../../../api/backofficeAPI";
import { formatedDate } from "../../../../utils/functions";
import Loading from "../../../../components/loading";
import { TooltipWrapper } from "../../../../components/tooltip";
import styled from "styled-components";

function SignatoryModal(props) {
  const modalRef = React.useRef(null);
  const [id, setId] = useState("");
  const [name, setName] = useState("");
  const [surname, setSurname] = useState("");
  const [fiscalCode, setFiscalCode] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [gender, setGender] = useState("");
  const [birthDate, setBirthDate] = useState("2001-09-30T00:00:00Z");
  const [birthPlace, setBirthPlace] = useState("");
  const [cityCode, setCityCode] = useState("");
  const [countryName, setCountryName] = useState("");
  const [countryCode, setCountryCode] = useState("");
  const [city, setCity] = useState("");
  const [address, setAddress] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [isEmailLoading, setEmailLoading] = useState();
  const [isDocumentsLoading, setIsDocumentsLoading] = useState();
  const [isUpdateLoading, setUpdateLoading] = useState();
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [canResendMail, setCanResendMail] = useState(false);
  const [canUpdateData, setCanUpdateData] = useState(false);
  const [canUpdateMail, setCanUpdateMail] = useState(false);
  const [canDownloadSignedDocument, setCanDownloadSignedDocument] =
    useState(false);

  useEffect(() => {
    setId(props.user?.signatoryId);
    setName(props.user?.name);
    setSurname(props.user?.surname);
    setFiscalCode(props.user?.fiscalCode);
    setEmail(props.user?.email);
    setPhone(props.user?.phoneNumber);
    setGender(props.user?.gender);
    setBirthDate(formatedDate(props.user?.birthData?.birthDate));
    setBirthPlace(props.user?.birthData?.cityName);
    setCityCode(props.user?.birthData?.cityCode);
    setCountryName(props.user?.residenceData?.countryName);
    setCountryCode(props.user?.residenceData?.countryCode);
    setCity(props.user?.residenceData?.city);
    setAddress(props.user?.residenceData?.address);
    setPostalCode(props.user?.residenceData?.postalCode);
    setCanResendMail(props.user?.canResendMail);
    setCanUpdateData(props.user?.canUpdateData);
    setCanUpdateMail(props.user?.canUpdateEmail);
    setCanDownloadSignedDocument(props.user?.canDownloadSignedDocument);
  }, [props.user]);

  useEffect(() => {
    const closeModal = (e) => {
      if (
        modalRef.current &&
        props.isModalOpen &&
        !modalRef.current.contains(e.target)
      ) {
        props.setIsModalOpen(false);
      }
    };
    document.addEventListener("mousedown", closeModal);
  }, [props]);

  useEffect(() => {
    if (!props.isModalOpen) {
      setSuccessMessage("");
      setErrorMessage("");
    }
  }, [props.isModalOpen]);

  const onResendEmail = () => {
    const body = {
      sessionId: props.idSession,
      signatoryId: props.user?.signatoryId,
    };
    setEmailLoading(true);
    resendEmailToSignatory(body).then((response) => {
      if (response.status === 200) {
        setSuccessMessage("La mail e stata mandata con successo");
        setEmailLoading(false);
      } else {
        setErrorMessage("Non e stato possibile mandare la mail");
        setEmailLoading(false);
      }
    });
  };

  const onResendDocuments = () => {
    const body = {
      sessionId: props.idSession,
      signatoryId: props.user?.signatoryId,
    };
    setIsDocumentsLoading(true);
    resendDocuments(body).then((response) => {
      if (response.status === 200) {
        setSuccessMessage(
          "I documenti firmati sono stati nuovamente inviati all’utente"
        );
        setIsDocumentsLoading(false);
      } else {
        setErrorMessage("Non e stato possibile inviare i documenti");
        setIsDocumentsLoading(false);
      }
    });
  };

  const onUpdateData = () => {
    const data = {
      signatoryId: id,
      name: name,
      surname: surname,
      fiscalCode: fiscalCode,
      email: email,
      mobileNumber: phone,
      gender: gender,
      birthData:
        birthDate === ""
          ? null
          : {
              birthDate: birthDate,
              cityName: birthPlace,
              cityCode: cityCode,
            },
      residenceData: {
        countryName: countryName,
        countryCode: countryCode,
        city: city,
        address: address,
        postalCode: postalCode,
      },
    };
    setUpdateLoading(true);
    updateSignatoryData(props.idSession, data).then((response) => {
      if (response.status === 200) {
        setSuccessMessage(
          "I dati del firmatario sono stati aggiornati con successo"
        );
        setUpdateLoading(false);
      } else {
        setErrorMessage(
          "Non e stato possibile aggiornare i dati del firmatario"
        );
      }
    });
  };

  if (!props.isModalOpen) return null;
  return (
    <ModalOverlay>
      <Card ref={modalRef}>
        <div className="upper-panel">
          <h4>
            {name} {surname}
          </h4>
        </div>

        <div className="midle-panel">
          <>
            <div className="row-container">
              <InputContainer>
                <label className="label">Nome</label>
                <Input
                  id="nome"
                  type="text"
                  placeholder="nome"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </InputContainer>
              <InputContainer>
                <label className="label">Cognome</label>
                <Input
                  id="cognome"
                  type="text"
                  placeholder="cognome"
                  value={surname}
                  onChange={(e) => setSurname(e.target.value)}
                />
              </InputContainer>
              <InputContainer>
                <label className="label">Genere</label>
                <Select
                  name="gender"
                  defaultValue={gender}
                  onChange={(e) => setGender(e.target.value)}
                >
                  <option value="M">Maschio</option>
                  <option value="F">Femmina</option>
                </Select>
              </InputContainer>
            </div>
            <div className="row-container">
              <InputContainer>
                <label className="label">Codice Fiscale</label>
                <Input
                  id="codiceFiscale"
                  type="text"
                  placeholder="codice fiscale"
                  value={fiscalCode}
                  onChange={(e) => setFiscalCode(e.target.value)}
                />
              </InputContainer>
              <InputContainer>
                <label className="label">Email</label>
                <Input
                  disabled={!canUpdateMail}
                  id="email"
                  type="email"
                  placeholder="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </InputContainer>
              <InputContainer>
                <label className="label">Telefono</label>
                <Input
                  id="telefono"
                  type="text"
                  placeholder="telefono"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                />
              </InputContainer>
            </div>
            <div className="row-container">
              <InputContainer>
                <label className="label">Data di nascita</label>
                <Input
                  type="date"
                  name="birthDate"
                  value={birthDate}
                  onChange={(e) => setBirthDate(e.target.value)}
                />
              </InputContainer>
              <InputContainer>
                <label className="label">Citta di nascita</label>
                <Input
                  id="cittaDiNascita"
                  type="text"
                  placeholder="citta di nascita"
                  value={birthPlace}
                  onChange={(e) => setBirthPlace(e.target.value)}
                />
              </InputContainer>
              <InputContainer>
                <label className="label">Codice della citta</label>
                <Input
                  id="codiceCitta"
                  type="text"
                  placeholder="Codice della citta"
                  value={cityCode}
                  onChange={(e) => setCityCode(e.target.value)}
                />
              </InputContainer>
            </div>
            <div className="row-container">
              <InputContainer>
                <label className="label">Stato</label>
                <Input
                  id="stato"
                  type="text"
                  placeholder="stato"
                  value={countryName}
                  onChange={(e) => setCountryName(e.target.value)}
                />
              </InputContainer>
              <InputContainer>
                <label className="label">Codice dello stato</label>
                <Input
                  id="codiceStato"
                  type="text"
                  placeholder="Codice dello stato"
                  value={countryCode}
                  onChange={(e) => setCountryCode(e.target.value)}
                />
              </InputContainer>
              <InputContainer>
                <label className="label">Citta</label>
                <Input
                  id="citta"
                  type="text"
                  placeholder="citta"
                  value={city}
                  onChange={(e) => setCity(e.target.value)}
                />
              </InputContainer>
            </div>
            <div className="row-container">
              <InputContainer>
                <label className="label">indirizzo</label>
                <Input
                  id="indirizzo"
                  type="text"
                  placeholder="indirizzo"
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                />
              </InputContainer>
              <InputContainer>
                <label className="label">Codice postale</label>
                <Input
                  id="codicePostale"
                  type="text"
                  placeholder="codice postale"
                  value={postalCode}
                  onChange={(e) => setPostalCode(e.target.value)}
                />
              </InputContainer>
            </div>
          </>
          {errorMessage && (
            <div className="error-message">
              <FontAwesomeIcon
                className="error-icon"
                icon={faTriangleExclamation}
              />
              {errorMessage}
            </div>
          )}
          {successMessage && (
            <div className="success-message">
              <FontAwesomeIcon className="success-icon" icon={faCircleCheck} />
              {successMessage}
            </div>
          )}
        </div>
        <div className="lower-panel">
          <Button primaryLight onClick={() => props.setIsModalOpen(false)}>
            Annulla
          </Button>
          <div className="button-row">
            <TooltipWrapper
              title="Invia i documenti firmati all’utente firmatario"
              placement="top"
            >
              <Button
                disabled={!canDownloadSignedDocument}
                secondaryBlue
                onClick={onResendDocuments}
                className={"email-button"}
              >
                {isDocumentsLoading ? (
                  <Loading extraSmall />
                ) : (
                  "Invia documenti"
                )}
              </Button>
            </TooltipWrapper>

            <TooltipWrapper title="Tooltip content " placement="top">
              <Button
                disabled={!canResendMail}
                secondaryBlue
                margin1rem
                onClick={onResendEmail}
                className={"email-button"}
              >
                {isEmailLoading ? <Loading extraSmall /> : "Invia email"}
              </Button>
            </TooltipWrapper>

            <TooltipWrapper title="Tooltip content " placement="top">
              <Button
                disabled={!canUpdateData}
                primaryLarge
                onClick={onUpdateData}
                className={"save-button"}
              >
                {isUpdateLoading ? <Loading extraSmall white /> : "Salva"}
              </Button>
            </TooltipWrapper>
          </div>
        </div>
      </Card>
    </ModalOverlay>
  );
}

export default SignatoryModal;

export const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 6;
  background-color: rgba(0, 0, 0, 0.7);
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 1rem;
  width: 240px;
`;

export const Card = styled.div`
  width: 840px;
  height: 566px;
  background-color: white;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  .upper-panel {
    width: 100%;
    height: 60px;
    border-bottom: solid 2px #0090d1;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0 1rem;
    align-items: center;
    margin-bottom: 1rem;
  }

  .midle-panel {
    width: 100%;
    height: 430px;
  }

  .lower-panel {
    width: 100%;
    height: 60px;
    background-color: #f2f5f8;
    display: flex;
    flex-direction: row;
    padding: 0 1rem;
    justify-content: space-between;
    align-items: center;
  }

  .button-row {
    display: flex;
    flex-direction: row;
  }

  .row-container {
    display: flex;
    flex-direction: row;
    padding: 0 1rem 1rem 1rem;
    justify-content: flex-start;
  }

  .label {
    font-size: 14px;
    font-weight: 500;
    margin-left: 4px;
  }

  .error-message {
    font-size: 16px;
    font-weight: 500;
    color: red;
    margin-left: 16px;
    margin-right: 16px;
  }

  .success-message {
    font-size: 16px;
    font-weight: 500;
    color: green;
    margin-left: 16px;
    margin-right: 16px;
  }

  .success-icon {
    color: #09822a;
    height: 1rem;
    margin-right: 6px;
  }
  .error-icon {
    color: red;
    height: 1rem;
    margin-right: 6px;
  }

  .save-button {
    min-width: 100px;
  }

  .email-button {
    min-width: 120px;
  }
`;
