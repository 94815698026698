import React from "react";
import Input from "../../../../components/input";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";

export const TsIdForm = (props) => {
  const [showPassword, setShowPassword] = useState("");
  React.useEffect(() => {
    setShowPassword(false);
  }, []);

  return (
    <div>
      <div className="drawer-title border-none margin-top">
        Configurazione Teamsystem Id
      </div>

      <div className="input-container">
        <label className="form-label">Id Cliente TS</label>
        <Input
          id="TsClientId"
          name="ClientId"
          type="text"
          placeholder="Id cliente"
          onChange={(e) => props.setTsClientId(e.target.value)}
          value={props.tsClientId}
        />
        {props.getError("TeamSystemIdCredentials.ClientId") && (
          <div className="error-message">
            {props.getError("TeamSystemIdCredentials.ClientId").message}
          </div>
        )}
      </div>

      <div className="input-container">
        <label className="form-label">Secret Cliente TS</label>
        <FontAwesomeIcon
          onClick={() => setShowPassword(!showPassword)}
          className={`eye-icon ${showPassword && "active"}`}
          icon={faEye}
        />
        <Input
          id="TsClientSecret"
          name="ClientSecret"
          type={showPassword ? "text" : "password"}
          placeholder="Secret cliente"
          onChange={(e) => props.setTsClientSecret(e.target.value)}
          value={props.tsClientSecret}
        />
        {props.getError("TeamSystemIdCredentials.ClientSecret") && (
          <div className="error-message">
            {props.getError("TeamSystemIdCredentials.ClientSecret").message}
          </div>
        )}
      </div>
    </div>
  );
};
