import styled from "styled-components";

export const PageWrapper = styled.div`
  height: fit-content;
  width: calc(100% - 104px);
  margin: 72px 24px 24px 80px;
  background-color: white;
  min-height: calc(100vh - 96px);
  min-width: 1000px;
`;

export const PageTitleContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 5rem;
  margin-bottom: 1rem;
  color: #005075;
  border-bottom: solid 2px #0090d1;
`;
export const PageTitle = styled.h3``;
export const PageSubtitle = styled.h5``;
