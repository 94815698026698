import React from "react";
import styled from "styled-components";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function ErrorState(props) {
  return (
    <Wrapper>
      <IconWrapper>
        <FontAwesomeIcon
          style={{
            color: "white",
            height: "2.5rem",
          }}
          icon={faClose}
        />
      </IconWrapper>
      <h3 className="text">{props.text}</h3>
    </Wrapper>
  );
}

export default ErrorState;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: fit-content;
  margin: 2rem;

  .text {
    margin-top: 1rem;
  }
`;

const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 3rem;
  width: 3rem;
  border-radius: 50%;
  background-color: rgb(216, 40, 41);
`;
