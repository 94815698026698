import React from "react";
import {
  BreadcrumbContainer,
  StepContainer,
  Circle,
  StepText,
  CircleContainer,
  Divider,
} from "./style";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Breadcrumb = ({ steps, currentStep }) => {
  const currentStepIndex = steps.findIndex((step) =>
    step.path.includes(currentStep)
  );

  return (
    <BreadcrumbContainer>
      {steps.map((step, index) => {
        const isActive = step.path.includes(currentStep);
        const isPrevious = index < currentStepIndex;

        return (
          <React.Fragment key={index}>
            <StepContainer>
              <CircleContainer>
                <Circle isActive={isActive} isPrevious={isPrevious}>
                  {isPrevious ? (
                    <FontAwesomeIcon icon={faCheck} className="icon" />
                  ) : (
                    index + 1
                  )}
                  <StepText isActive={isActive}>{steps[index]?.name}</StepText>
                </Circle>
                {steps.length - 1 !== index && <Divider />}
              </CircleContainer>
            </StepContainer>
          </React.Fragment>
        );
      })}
    </BreadcrumbContainer>
  );
};

export default Breadcrumb;
