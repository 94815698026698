import React from "react";

import { useUserContext } from "../../../context/userContext";
import { UserRole } from "../../../utils/enums";
import TeamSystemBackoffice from "../teamsystemBackoffice";
import UserBackoffice from "../userBackoffice";
import {
  PageWrapper,
  PageTitleContainer,
  PageSubtitle,
  PageTitle,
} from "../../styles/shared";
import { useState } from "react";
import { useEffect } from "react";
import TenantBackoffice from "../tenantBackoffice";
import Navbar from "../../../components/navbar";
import Sidebar from "../../../components/sidebar";

const BackOfficePage = () => {
  const { myUser, handleLogout } = useUserContext();
  const [hasSubtitle, setHasSubtitle] = useState(false);
  const [title, setTitle] = useState("");

  useEffect(() => {
    if (myUser.type === UserRole.TeamSystemAdministrator) {
      setTitle("GESTIONE TENANT");
      setHasSubtitle(false);
    }
    if (myUser.type === UserRole.TenantAdministrator) {
      setTitle("GESTIONE UTENTI");
      setHasSubtitle(true);
    }
    if (myUser.type === UserRole.TenantSupervisor) {
      setTitle("GESTIONE PRATICHE");
      setHasSubtitle(true);
    }
    if (myUser.type === UserRole.TenantUser) {
      setTitle("GESTIONE PRATICHE");
      setHasSubtitle(true);
    }
  }, [myUser.type]);

  return (
    <>
      <Navbar />
      <Sidebar />
      <PageWrapper>
        <PageTitleContainer>
          <PageTitle className="backoffice-title">{title}</PageTitle>
          {hasSubtitle && (
            <PageSubtitle className="backoffice-subtitle">
              {myUser?.companyName} - P.IVA {myUser?.vatNumber}
            </PageSubtitle>
          )}
        </PageTitleContainer>

        {myUser.type === UserRole.TeamSystemAdministrator ? (
          <TeamSystemBackoffice />
        ) : null}
        {myUser.type === UserRole.TenantAdministrator ? (
          <TenantBackoffice />
        ) : null}
        {myUser.type === UserRole.TenantSupervisor ? <UserBackoffice /> : null}
        {myUser.type === UserRole.TenantUser ? <UserBackoffice /> : null}
        {myUser.type === UserRole.ExternalAppUser && handleLogout()}
        {myUser.type === UserRole.TenantApiUser && handleLogout()}
      </PageWrapper>
    </>
  );
};

export default BackOfficePage;
